@import '../abstracts/variables';

.userstats-parent {
  display: flex;
  // align-items: flex-end;
  width: 100%;
  gap: 10px;
}
.leaderboard-skeleton-wrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  .left{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    padding: 20px;
    background-color: #e1e1e1;
    gap: 20px;
  }
  .right{
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    padding: 20px;
    background-color: #e1e1e1;
    gap: 20px;
  }
}
.userstats-container {
  width: 75%;
  height: auto;
  min-height: 600px;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  &--left {
    width: 25%;
    min-height: 600px;
    height: auto;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 15px 20px 20px 20px;
    .leaderboard__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 600px;
      .loader__and__table-leaderboard {
        height: 570px;
        border-radius: 8px;
        padding-top: 5px;
        .loader__contianer {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .rdg-light {
          height: 100%;
          border-radius: 8px;
        }
      }
    }
    .statistics__information__text-first {
      font-size: 18px;
      color: $primary;
      font-weight: 600;
      font-family: 'Montserrat';
    }
  }
  &--top {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    border-bottom: 1px solid #d9d9d9;
    .userstats-left-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      text-align: center;
      border-right: 1px solid #d9d9d9;
      &:last-child {
        border-right: none;
      }
      &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #212121;
      }
      &-number {
        font-weight: 600;
        font-size: 30px;
        line-height: 37px;
        color: #212121;
      }
      &-orders {
        display: flex;
        align-items: center;
        height: 30px;
        width: 200px;
        margin-bottom: 5px;
        &:first-of-type {
          margin-top: 10px;
          opacity: 0.7;
        }
        &-icon {
          display: flex;
          align-items: center;
          border: 1px solid $primary;
          border-right: none;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          height: 100%;
          &.cancelled {
            background-color: #da6d6db2;
            border: 1px solid #da6d6d;
            & .orders__icon {
              border-right: 1px solid #da6d6d;
            }
          }
          .orders__icon {
            padding: 0 8px;
            border-right: 1px solid $primary;
            display: flex;
            height: 100%;
            align-items: center;
          }
          .orders__title {
            color: #fff;
            font-family: 'Montserrat';
            padding: 0 10px 0 8px;
            width: 120px;
            text-align: left;
            font-weight: 600;
          }
        }
        .orders__count {
          padding: 0 5px;
          font-family: 'Montserrat';
          color: #fff;
          background-color: $primary;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          min-width: 60px;
          &.cancelled {
            background-color: #da6d6d;
          }
        }
      }
    }
  }
  &--middle--top {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    font-family: 'Montserrat';

    .userstats-left-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      &-clientName {
        font-size: 20px;
        padding-bottom: 10px;
        font-weight: 600;
      }
      &-total {
        display: flex;
        align-items: end;
        gap: 20px;
        width: 100%;
      }
      &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #212121;
      }
      &-number {
        font-weight: 600;
        font-size: 16px;
        line-height: 37px;
        color: #212121;
      }
      &-orders {
        display: flex;
        align-items: center;
        height: 30px;
        margin-bottom: 5px;
        width: 50%;
        &:first-of-type {
          margin-top: 10px;
          opacity: 0.7;
        }
        &-icon {
          display: flex;
          align-items: center;
          border: 1px solid $primary;
          border-right: none;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          height: 100%;
          width: 100%;
          &.cancelled {
            background-color: #da6d6db2;
            border: 1px solid #da6d6d;
            & .orders__icon {
              border-right: 1px solid #da6d6d;
            }
          }
          .orders__icon {
            padding: 0 8px;
            border-right: 1px solid $primary;
            display: flex;
            height: 100%;
            align-items: center;
          }
          .orders__title {
            color: #fff;
            font-family: 'Montserrat';
            padding: 0 10px 0 8px;
            font-size: 12px;
            text-align: left;
            font-weight: 600;
          }
        }
        .orders__count {
          padding: 0 5px;
          font-family: 'Montserrat';
          color: #fff;
          background-color: $primary;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: 600;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          min-width: 45px;
          &.cancelled {
            background-color: #da6d6d;
          }
        }
      }
    }
  }
  &--middle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    flex-direction: row;
    width: 50%;
    align-items: flex-end;
    padding: 10px;
    gap: 10px;
    font-family: 'Montserrat';
    align-self: flex-start;

    .userstats-left-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      &-title {
        font-weight: 600;
        font-size: 11px;
        // line-height: 20px;
        color: #212121;
      }
      &-number {
        font-weight: 600;
        font-size: 14px;
        // line-height: 37px;
        color: #212121;
      }
      &-orders {
        display: flex;
        align-items: center;
        height: 30px;
        margin-bottom: 5px;
        &:first-of-type {
          margin-top: 10px;
          opacity: 0.7;
        }
        &-icon {
          display: flex;
          align-items: center;
          border: 1px solid $primary;
          border-right: none;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          height: 100%;
          &.cancelled {
            background-color: #da6d6db2;
            border: 1px solid #da6d6d;
            & .orders__icon {
              border-right: 1px solid #da6d6d;
            }
          }
          .orders__icon {
            padding: 0 8px;
            border-right: 1px solid $primary;
            display: flex;
            height: 100%;
            align-items: center;
          }
          .orders__title {
            color: #fff;
            font-family: 'Montserrat';
            padding: 0;
            padding-left: 2px;
            width: 40px;
            font-size: 7px;
            text-align: left;
            font-weight: 600;
          }
        }
        .orders__count {
          padding: 0 5px;
          font-family: 'Montserrat';
          color: #fff;
          background-color: $primary;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: 600;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          min-width: 44px;
          &.cancelled {
            background-color: #da6d6d;
          }
        }
      }
    }
  }
  &--bottom {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;
    .userstats-left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 25%;
      background-color: #fefefe;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      &-seperator {
        display: flex;
        align-items: center;
        justify-content: center;
        &-border {
          border: 1px solid rgba(0, 0, 0, 0.2);
          height: 75%;
        }
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        text-align: center;
        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #212121;
        }
        &-number {
          font-weight: 600;
          font-size: 30px;
          line-height: 37px;
          color: #212121;
        }
      }
    }
    .userstats-middle {
      // width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      background-color: #fefefe;
      border-radius: 8px;
      canvas {
        max-height: 450px;
        max-width: 450px;
        border-right: 1px solid #f5f5f5;
      }
      .noDataMsg {
        color: #939393;
      }
      &-stats {
        width: 20%;
        width: 24%;
        height: 265px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      &-colors {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 260px;
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        &__legend-color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-bottom: 30px;
        }
        &-title {
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
        &-number {
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
      }
    }
    .userstats-right {
      width: 60%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-around;
      background-color: #fefefe;
      border-radius: 8px;
      canvas {
        max-height: 480px;
      }
      .noDataMsg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #939393;
      }
      .statistics__information__text {
        width: 100%;
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
        &-first {
          font-size: 16px;
          color: $primary;
          font-weight: 600;
          margin-left: 10px;
        }
        &__selectedDate {
          display: flex;
          background-color: #f0f0f0;
          align-items: center;
          justify-content: flex-end;
          color: #c7c7c7;
          height: 40px;
          border-radius: 8px;
          font-size: 14px;
          font-family: 'Montserrat';
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 600;
          min-width: 210px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .userstats-parent {
    flex-direction: column;
  }
  .userstats-container {
    width: 100%;
    &--left {
      width: 100%;
    }
  }
  .userstats-container--bottom {
    height: auto;
    flex-direction: column;
    padding: 8px 0 8px 0;
    gap: 10px;
    .userstats-left {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
    .userstats-middle {
      justify-content: space-around;
      gap: 0px;
      width: 100%;
      margin-left: 0;
      border-bottom: 1px solid #f5f5f5;
      border-radius: 0;
      canvas {
        max-height: 450px;
        max-width: 450px;
        border-right: none;
      }
    }
    .userstats-right {
      margin-right: 0px;
      margin-left: 0px;
      flex-direction: column;
      padding: 8px;
      width: 100%;
      .statistics__information__text-second {
        display: none;
      }
    }
    .userstats-left-seperator {
      display: none;
    }
  }
}
