.parent {
  // z-index: 1;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.parent-container {
  width: 100%;
}
p{
  margin-bottom: 0px !important;
}

.language__dropdown{
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.pl30 {
  padding-left: 30px;
}
.pr30 {
  padding-right: 30px;
}
.pr20 {
  padding-right: 20px;
}
.pl20 {
  padding-left: 20px;
}