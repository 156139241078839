@import "../../abstracts/variables";

.pinInput__container{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    flex-direction: column;

    &-text{
        color: #484848;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 62px; /* 155% */
        letter-spacing: 0.2px;
        margin-bottom: 110px;

        span{
            color: #1C4CCF;
        }
        @media (max-width: 600px) {
          font-size: 32px;
        }
        @media (max-width: 400px) {
          font-size: 30px;
      }
       
    }

    &-pin{
        span{
            color: #484848;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.8px;
            display: flex;
            justify-content: center;
            margin-bottom: 42px;
        }

        &--errorPin{
          display: block;
          margin-top: 1px;
          margin-left: 5px;
          color: #D00A12;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: italic;
          font-weight: 600;
          line-height: normal
          margin-top
          
        }
    }
}

.pin-input {
    display: flex;
    gap: 10px;
    input {
      width: 110px;
      height: 131px;
      // text-align: center;
      font-size: 16px;
      border-radius: 10px;
      border: 2px solid $primary;
      // background: rgba(108, 30, 176, 0.20);
      background: #F0E8F7;;
      @media (max-width: 600px) {
        width: 90px;
        height: 100px;
        font-size: 30px;
        
      }
      @media (max-width: 400px) {
        width: 80px;
        height: 90px;
        font-size: 30px;
    }
      @media (max-width: 360px) {
        width: 80px;
        height: 90px;
        font-size: 30px;
    }
  }
  }
  

  .pin-input input[type="password"] {
    font-size: 20px; 
    -webkit-text-security: square;
  }

  .password-asterisks {
    color: #484848;
    font-family: "Roboto";
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  //Generate Token

  .generateToken__container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    

    &--titleText{
      display: flex;
      flex-direction: column;
      gap: 5px;

      &-sorry{
        text-align: center;
        text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
        font-size: 109px;
        font-style: normal;
        font-weight: 700;
        line-height: 100px; /* 91.743% */
        letter-spacing: -8px;
        text-transform: uppercase;
        background: conic-gradient(from 180deg at 50% 50%, rgba(108, 30, 176, 0.75) 0deg, rgba(136, 22, 134, 0.75) 91.87500357627869deg, rgba(91, 9, 99, 0.75) 187.49999284744263deg, rgba(28, 76, 207, 0.75) 266.2499928474426deg, rgba(14, 136, 242, 0.75) 346.8750071525574deg);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &-expired{
        color: #484848;
        text-align: center;
        font-family: "Montserrat";
        font-size: 25px;
        font-style: normal;
        font-weight: 596;
        line-height: 62px;

        span{
          color: #1C4CCF;
          font-size: 32px;
          font-style: normal;
          font-weight: 596;
          line-height: 62px;
        }
      }

      &-img{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    
  }

  //Generate Token Button
  .generateToken__button1{
    display: flex;
    width: 295px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: linear-gradient(95deg, #9956A9 0%, #2A3CAE 100%);
    backdrop-filter: blur(10px);
    cursor: pointer;
    &-text{
      color: #FFF;
      text-align: right;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .generateToken__button2{
    display: flex;
    width: 295px;
    height: 49px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: linear-gradient(95deg, #9956A9 0%, #2A3CAE 100%);
    backdrop-filter: blur(10px);
    cursor: pointer;
    &-text2{
      color: #FFF;
      text-align: right;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .generateToken__button3{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      border: 2px solid rgba(108, 30, 176, 0.5);
      padding: 10px;
      cursor: pointer;
    &-text3{
        text-align: right;
        -webkit-text-stroke-width: 2;
        -webkit-text-stroke-color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: linear-gradient(95deg, rgb(153 86 169 / 70%) 0%, rgb(42 60 174 / 70%) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        backdrop-filter: blur(10px);
    }
  }
 
