.custom-collapse {
    border-radius: 8px !important;
    background-color: #f9f9f9;
    border: none;
    min-width: 1100px;
    &.opened {
      background-color: transparent;
      .ant-collapse-header {
        box-shadow: none;
        background-color: transparent;
        height: 30px;
        line-height: 30px;
        margin-top: 16px;
      }
    }
    &-skeleton_wrapper{
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      .box{
        display: flex;
        min-height: 80px;
        background-color: #e1e1e1;
        border-radius: 8px;
        .cell{
          flex: 1;
          display: flex;
          padding: 10px;
          border-right: 1px solid #d6d6d6;
          &:last-child{
            border-right: none;
          }
        }
      }
    }
  }

  .custom-collapse .ant-collapse-header {
    background-color: #f9f9f9;
    border-radius: 8px 8px 8px 8px !important;
    height: 80px;
    line-height: 80px;
    display: flex;
    align-items: center;
    padding: 0px !important;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    .ant-collapse-header-text {
      height: 100%;
      width: 100%;
    }
    .header-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      line-height: normal !important;
      &.opened {
        .header-wrapper-nameSection {
          border-right: none;
          width: 100%;
        }
      }
      &-nameSection {
        width: 350px;
        border-right: 1px solid #e4e4e4;
        display: flex;
        gap: 14px;
        margin-left: 14px;
        align-items: center;
        &-opened {
          border: none;
        }
        &-container {
          display: flex;
          height: 50px;
          width: 100%;
          gap: 10px;
        }
        &-name {
          font-family: 'Montserrat';
          font-weight: 600;
          color: #c1c1c1;
          font-size: 14px;
        }
        &-subTitle {
          font-family: 'Montserrat';
          font-size: 13px;
          font-weight: 500;
          margin-left: 10px;
          color: #c1c1c1;
        }
      }
      &-nameSectionLeft {
        width: 22px;
        height: 22px;
      }
      &-nameSectionRight {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
      }
      &-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &-item {
          display: flex;
          flex: 1 1;
          align-items: center;
          box-sizing: border-box;
          height: 100%;
          border-right: 1px solid #e4e4e4;
          flex-direction: column;
          justify-content: center;
          gap: 15px;
          &:last-child {
            border-right: none;
          }
          &-title {
            font-family: 'Montserrat';
            font-weight: 600;
            color: #252525;
            font-size: 14px;
          }
          &-content {
            font-family: 'Montserrat';
            font-weight: 600;
            color: #c1c1c1;
            font-size: 14px;
            min-height: 30px;
          }
        }
      }
      .color {
        height: 100%;
        width: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        &.hide {
            display: none;
          }
      }
    }
  }
  .ant-collapse-item {
    border-radius: 8px !important;
    border: none;
  }
  .custom-collapse .ant-collapse-content {
    border-radius: 0 0 8px 8px !important;
    background-color: #ffffff;
    border-top: none !important;
  }

  .custom-collapse .ant-collapse-content-box {
    padding: 16px;
  }

  .custom-collapse .ant-collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 5s ease-out;
  }

  .custom-collapse .ant-collapse-content-active {
    max-height: 500px;
    transition: max-height 5s ease-in;
    border-radius: 8px !important;
    background-color: #f9f9f9;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    overflow: scroll;
    .ant-collapse-content-box {
      padding: 0px;
      .panel-wrapper {
        display: flex;
        .table__wrapper {
          width: 100%;
          .rdg-light {
            border-radius: 0px;
            border: none;
            .rdg-header-row {
              background-color: #f9f9f9 !important;
              font-size: 14px;
              font-weight: 600;
            }
            .client-cell {
              padding: 0;
            }
            .status-cell {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .columnheader {
              text-align: center;
            }
            .rdg-header-row {
              text-align: center;
            }
            .gray-row{
              background-color: #f9f9f9 !important;
              font-size: 14px;
              font-weight: 600;
              color: #c1c1c1;
            }
            .priority-box-wrapper{
              display: flex;
              border-radius: 4px;
              width: fit-content;
              padding: 0 5px;
              align-items: center;
              justify-content: center;
              gap: 5px;
              height: 30px;
              margin-top: 7px;
              background-color: #ffffff;
              &_box{
                width: 14px;
                height: 14px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                .dot{
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                }
              }
              &_name {
                font-size: 10px;
              }
            }
          }
          .custom-pagination-wrapper{
            background-color: #f9f9f9;
          }
        }
        .color {
          width: 8px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          &.hide {
            display: none;
          }
        }
      }
    }
  }