.marketplace__container {
    padding: 20px 0;
    &-apps {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        .app__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 300px;
            width: 300px;
            padding: 50px 20px 20px 20px;
            cursor: pointer;
            border-radius: 10px;
            box-shadow: 3px 4px 4px 0px #00000026;
            background-color: #fff;
            border: 1px solid;
            &.available {
                color: $primary;
                border-color: #E4E4E4;
            }
            &.unavailable {
                color: #979797;
                border-color: #E0DFE0;
                & .icon {
                    filter: contrast(5%)
                }
            }
            &__top {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                & .name {
                    font-family: 'Montserrat';
                    font-size: 20px;
                    font-weight: 700;
                }
                & .icon {
                    width: 50px;
                    height: 50px;
                }
            }
            &__bottom {
                &-status {
                    font-family: "Montserrat";
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}
.apiKey__modal {
    height: 100vh;
    padding: 20px 30px;
    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: calc(100vh - 40px);
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                margin: 0 auto;
                font-family: "Montserrat";
                font-size: 20px;
                font-weight: 700;
                color: $primary;
            }
            .icon {
                cursor: pointer;
                svg {
                    width: 16px;
                    height: 16px;
                    path {
                        fill: $primary;
                        fill-opacity: 1;
                    }
                }
            }
        }
        &-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-actions {
              .primaryButton {
                width: 100%;
                height: 45px;
                background-color: $primary;
                border-radius: 8px;
                color: #fff;
                text-align: center;
                font-family: "Montserrat";

              }
            }
        }
    }
}
.no_apiKey--wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-inner {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        .main__title {
            font-family: "Montserrat";
            font-size: 20px;
            font-weight: 500;
            color: #979797;
        }
        .information {
            color: #979797;
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 500;
            width: 60%;
            text-align: center;
        }
        &-btn {
            background-color: $primary;
            border-radius: 10px;
            padding: 12px 100px;
            color: #fff;
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}