.wrapper{
    display: none;
  }
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      padding: 12px;
      text-align: left;
      border: 1px solid #ddd;
    }
  
    .parent-row {
      background-color: #e9ecef;
      font-weight: bold;
  
      td {
        padding-left: 10px;
      }
    }
  
    .detailed-row {
      background-color: #f8f9fa;
      td {
        padding-left: 30px;
      }
    }
  
    .detailed-header th {
      background-color: #6c757d;
      color: #fff;
    }
  }
  .print-footer{

  }
  @media print {
    .wrapper{
      padding: 20px;
      display: block;
      font-family: "Montserrat";
    }
    .custom-table {
      width: 100%;
      border: 1px solid black;
      table-layout: fixed; 
      font-family: "Montserrat";
    }
  
    th, td {
      border: 1px solid black;
      padding: 8px;
      font-family: "Montserrat";
    }
  
    /* Hide elements like buttons during printing */
    button {
      display: none;
    }
  
    .parent-row {
      background-color: #e9ecef !important;
      font-family: "Montserrat";
    }
  
    .detailed-row {
      background-color: #f8f9fa !important;
      padding-left: 40px;
      font-family: "Montserrat";
    }
  
    .detailed-header {
      background-color: #6c757d;
      color: white;
      font-family: "Montserrat";

    }
    .print-footer {
        float: right;
        font-size: 16px;
        font-weight: 500;
      font-family: "Montserrat";
    }
    .print-footer-info{
       display: flex;
       gap: 20px;
       font-weight: 700;
        font-family: "Montserrat";

    }
  }