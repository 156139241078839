@import '../abstracts/variables';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}
button {
  border: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
}
.rdg-light {
  font-weight: 500 !important;
  font-size: 12px !important;
}

.goal__container {
  &.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: 0px !important;
  }
}

.MuiAutocomplete-option {
  font-family: 'Montserrat' !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
  color: #979797;
}
.MuiInputBase-input {
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
}
.dropdown-input {
  height: 75px !important;
  width: 40% !important;
  .AutocompleteCl {
    width: 100% !important;
    margin-bottom: 0;
    padding: 2px 0;
    & .dropdown-option {
      margin-top: 0 !important;
      width: 100%;
    }
  }
  .MuiInputBase-root fieldset {
    border: solid 1px #d9d9d9 !important;
  }
}

.dropdown-input {
  height: auto !important;
  width: 45% !important;
  padding: 5px 0;
  margin-bottom: 0 !important;
}

.dropdown-input .MuiInputBase-root {
  margin: 5.5px 0 0 !important;
}
.autocomplete{
  .MuiInputBase-root{
    .MuiAutocomplete-endAdornment{
      margin-right:20px;
    }
  }
}

.AutocompleteCl {
  label {
    font-size: 13px;
    font-family: 'Montserrat';
  }

  input {
    font-size: 13px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: solid 2px #f5f5f5 !important;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    // top: 3px;
    top: -5px;
    left: -11px;
  }
}

.MuiAutocomplete-root {
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 7.5px 4px;
  }

  label + .MuiInput-formControl {
    margin-top: 10px;
  }

  input {
    font-size: 13px;
  }
}
.rangepicker-buttons {
  display: flex;
  align-items: center;
  .dashboardInput {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
    border-radius: 8px 0px 0px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    padding-left: 8px;
    padding-right: 8px;
    gap: 10px;
    font-weight: 600;
    font-size: 14px;
  }
  .rangepickers-buttons__open {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rangepickers-buttons__reload {
    width: 36px;
    height: 36px;
    background: $primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form_input {
    width: 219px;
    .dashboardInput {
      margin-top: 0px;
      height: 44px;
      color: #252525;
      outline: none;
      background: #ffffff;
      font-weight: 500;
      // box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
      border-radius: 8px;
      box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
    }
    .form_input__label {
      display: none;
    }
  }
}
.orders__daterange__picker {
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
    top: -2px;
    position: absolute;
    padding-right: 5px;
    background-color: #f5f5f5;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    top: -2px;
    position: absolute;
    padding-right: 5px;
    background-color: #f5f5f5;
  }

  label + .MuiInput-formControl {
    margin-top: 5px;
  }

  @include respond(phone) {
    margin-bottom: 5px;
  }
}

.infinitscroll_loader {
  min-height: 20px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  position: relative;
  bottom: 40px;
  &.center {
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    border: none;
    overflow: hidden;
    background-color: #e1e1e1 !important;
    border-radius: 5px;
  }
}
.ant-checkbox-wrapper{
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 13px;
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: $primary;
    border-color: $primary;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary; 
    box-shadow: none;
  }
  &:hover .ant-checkbox-inner {
    border-color: $primary;
  }
  .ant-checkbox-checked:after{
    border-color: $primary;
  }
}
.table_skeleton{
  max-height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 8px;
}
.full__width__loader {
  height: 100%;
  min-height: 55vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  color: $primary;
  font-size: 4px;
  margin: 25px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.ant-picker {
  width: 266px;
}
.loader__and__table {
  position: relative;
  width: 100%;
  height: calc(100vh - 150px);
  &-orderspage {
    position: relative;
    width: 100%;
    height: calc(100vh - 140px);
    &-collapseContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      .Mui-expanded {
        margin: 0px !important;
      }
      .full__loader {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        gap: 5px;
        .row{
          height: 50px;
          background-color: #e1e1e1;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          align-items: center;
          .ant-skeleton-element{
            width: auto;
          }
          &:last-child{
            height: 300px;
            align-items: flex-start;
            padding: 10px 20px;
          }
        }
      }
      .MuiPaper-root {
        width: 100%;
        box-shadow: 2px 3px 4px 0px #00000026;
        &::before {
          display: none;
        }
        .custom__header {
          &-title {
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 700;
            color: #252525;
          }
        }
        .table__wrapper {
          padding: 0 0 20px 0;
          height: 650px;
        }
        .rdg-light {
          height: 590px !important;
          min-height: 590px;
          margin: 0;
          overflow: auto;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        .custom-pagination-wrapper {
          border-top: none;
        }
      }
    }
    .three-dots-icon {
      cursor: pointer;
    }
    .rdg-light {
      min-height: calc(100vh - 200px);
      font-family: 'Montserrat';

      .price {
        background-color: #f5f5f5;
        font-weight: 600;
        color: #4a4a4a;
      }
      .headerStyles {
        font-weight: 600;
        color: #4a4a4a;
      }
      .empty-rows-view {
        color: #8c8c8c;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & .full__width__loader {
      background-color: white;
      position: absolute;
      top: 0%;
      left: 0;
      .loader {
        display: flex;
        align-self: flex-end;
      }
    }
    .MuiTableCell-root {
      font-family: 'Montserrat' !important;
      font-size: 13px !important;
      font-weight: 500;
      padding: 5px !important;
    }
    .MuiTableCell-paddingNone {
      padding: 0 !important;
    }
  }
  &-userstable {
    position: relative;
    width: 100%;
    height: 100%;
    & .full__width__loader {
      background-color: white;
      position: absolute;
      top: 0%;
      left: 0;
    }
    .MuiTableCell-root {
      font-family: 'Montserrat' !important;
      font-size: 13px !important;
      font-weight: 500;
      padding: 5px !important;
    }
    .MuiTableCell-paddingNone {
      padding: 0 !important;
    }
    .row-container{
      .rdg-light{
        margin: 20px 12px;
        border-right: none !important;
        border-bottom: none !important;
      }
    }
    .rdg-light {
      font-family: 'Montserrat';
      height: 100%;
      margin: 0px !important; 
      .rdg-header-row {
        background-color: #f0f0f0;
        & .rdg-cell {
          color: #000;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
        }
      }
      .custom-cell-class {
        background-color: transparent;
        color: $secondary;
        font-weight: 600;
      }
      .empty-rows-view {
        color: #8c8c8c;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .user__table__photo__and__name {
        .user__table__initials {
          height: 90%;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: center;
          & span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  &-leaderboard {
    border-radius: 10px;
    overflow: hidden;
    .MuiTableCell-root {
      font-family: 'Montserrat' !important;
      font-size: 13px !important;
      font-weight: 600;
      padding: 10px 10px 10px 10px !important;
    }
    .MuiTableCell-paddingNone {
      padding: 0 !important;
    }
  }
  &-dashboardpage {
    border-radius: 10px;
    overflow: hidden;
    .rdg-light {
      min-height: 100vh;
      font-family: 'Montserrat';
      .headerStyles {
        font-weight: 600;
        color: #4a4a4a;
      }
      .empty-rows-view {
        color: #8c8c8c;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .MuiTableCell-root {
      font-family: 'Montserrat' !important;
      font-size: 13px !important;
      font-weight: 500;
      padding: 10px 10px 10px 10px !important;
    }
    .MuiTableCell-paddingNone {
      padding: 0 !important;
    }
  }
  &.traings {
    top: 0;
    left: 0;
    position: sticky;
    display: none;
  }
  & .full__width__loader {
    background-color: white;
    // z-index: 999999;
    position: absolute;
    top: 0%;
    left: 0;
  }
}
.MuiCheckbox-root {
  color: #d5d5d5 !important;
  padding: 3px !important;
  svg {
    width: 20px;
  }
}
.Mui-checked {
  color: $primary !important;
}
.Mui-checked + .MuiSwitch-track {
  background-color: $primary !important;
  opacity: 0.5 !important;
  box-shadow: inset 0px 0px 9px 2px rgba(0, 0, 0, 0.4) !important;
}

.css-1yjjitx-MuiSwitch-track {
  opacity: 0.15 !important;
}

.MuiSwitch-track {
  background-color: #ccc !important; /* Change the background color */
  opacity: 0.5 !important;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2) !important; /* Add your box-shadow for off state */
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-height: 48px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
}

.css-1km1ehz {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-height: 48px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
  // margin-right: 25px;
  height: 4px;
}
.custom-pagination-wrapper {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.custom-pagination {
  width: 20%;
  height: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  .three-dots-icon {
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
  &-information {
    font-family: 'Montserrat';
    font-size: 12px;
    color: #8c8c8c;
  }
}

.table-actions-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 20px;
  justify-content: center;
  width: 50px;
  & span {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  & img {
    cursor: pointer;
  }
  .preview__row {
    position: relative;
    &.hide {
      &::before {
        position: absolute;
        content: '';
        height: 25px;
        width: 2px;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 50%;
        left: 50%;
        background-color: #c1c1c1;
      }
    }
  }
}
.project-categories_custom-cell {
  border-right: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-categories_custom-header-cell {
  border-right: none;
}
.category-custom__cell {
  background-color: transparent;
  color: $secondary;
}
.custom__cell-expander {
  border-right: none;
  overflow-y: hidden;
  div {
    span {
      span {
        svg {
          & > * {
            fill: $primary;
          }
        }
      }
    }
  }
  &:hover {
    background-color: #fff;
  }
}
.headerStyles {
  font-weight: 600;
  color: #4a4a4a;
}

.rdg-cell {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  box-shadow: none !important;
}

.custom__option {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center !important;
}

.autoCompleteStyle {
  div {
    .MuiPaper-rounded {
      border-radius: none !important;
      border-top-right-radius: inherit !important;
      border-bottom-right-radius: inherit !important;
      ul {
        .MuiAutocomplete-option[data-focus='true'] {
          background: #f5f5f5 !important;
        }
        .MuiAutocomplete-option[aria-selected='true'] {
          background: #f5f5f5 !important;
        }
      }
    }
  }
}

.popperComponent {
  display: flex;
  .popperComponentStyle {
    background-color: #f5f5f5;
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    max-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      width: 200px;
    }
  }
}
.popperComponentStyle-second {
  background: $primary !important;
  svg {
    & path:last-of-type {
      fill: $secondary;
    }
  }
}
.seeMore__btn {
  color: $primary;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.custom__cell__selector,
.custom__cell__checkbox {
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    padding-left: 25px;
  }
}
.custom__autocomplete {
  .MuiInput-underline:before {
    border-bottom: none;
    content: '';
    display: none;
  }
  .MuiInputBase-root {
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 0 10px;
    input {
      height: unset !important;
    }
    .MuiAutocomplete-endAdornment {
      margin: 0;
    }
  }
  .MuiFormControl-root {
    .Mui-disabled {
      background-color: #f5f5f5;
    }
  }
  fieldset {
    border: none
  }
}

.dataGrid_view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $primary;
  font-weight: 600;
}
.table__tabs {
  background-color: #fff;
  padding: 7px 10px;
  max-width: fit-content;
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  font-family: 'Montserrat';
  &.loading{
    background-color: #e1e1e1;
    &::after {
      background: #e1e1e1;
    }
    &::before {
      background: #e1e1e1;
    }
  }
  .selected__count{
    position: absolute;
    right: -8px;
    top: 12px;
    background-color: #D00A12;
    font-size: 8px;
    border-radius: 50%;
    width: 19px;
    text-align: center;
    height: 19px;
    padding-top: 3px;
    font-family: 'Montserrat';
    font-weight: 600;
  }
  .tab {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #C1C1C1;
    border-radius: 5px;
    padding: 3px 10px;
    font-size: 12px;
    cursor: pointer;
    .name {
      color: inherit;
    }
    .icon {
      height: 100%;
      display: flex;
      align-items: center;
      svg {
        path {
          stroke: #C1C1C1;
        }
      }
    }
    &.active {
      position: relative;
      background-color: $primary;
      color: #fff;
      .icon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
  &::after {
    content: '';
    background: #fff;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 0;
    left: -3px;
    bottom: -12px;
    right: auto;
    border-radius: 50% 0 50% 0;
    transform: rotate(-315deg);
  }

  &::before {
    content: '';
    background: #fff;
    height: 20px;
    width: 20px;
    position: absolute;
    top: auto;
    left: auto;
    right: -3px;
    bottom: -12px;
    border-radius: 0 50% 0 50%;
    transform: rotate(-225deg);
  }
}
.table__wrapper {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  .rdg-light {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    &::-webkit-scrollbar:horizontal {
      display: none !important;
    }
  }
  &__uploads{
    padding: 0px;
  }
}
.custom__autocomplete--wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: 0px;
  }
}
.settle__orders__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .textarea__input {
    textarea {
      border: 1px solid #d9d9d9 !important;
      border-radius: 8px !important;
    }
  }
}
.publish__btn {
  position: relative;
   &--count {
    position: absolute;
    top: -7px;
    bottom: 15px;
    background-color: #D00A12;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    right: -3px;
    color: #fff;
    font-size: 10px;
   }
}
.calculationsTable__wrapper {
  padding: 20px;
  min-height: 100vh;
  overflow-y: auto;
  margin-bottom: 20px;
}

.async-dropdown-autocomplete{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .asyncDropDown-label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    margin-left: 1px;
    color: #252525;
  }