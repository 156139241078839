@import '../../abstracts/variables';

.header-path {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left:5px;
  padding-right: 5px;
  font-family: 'Montserrat';
  color: $secondary;
  font-weight: 500;
  font-size: 14px;
}
.back{
  display: flex;
  cursor: pointer;
}
.custom-popover-filters{
  & ul {
    .ant-dropdown-menu-item {
      padding: 0;
      cursor: default;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .filters__popover {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-header {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .filters-container {
      max-height: 260px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 6px;
      }
      &-wrapper {
        padding: 8px 10px;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
        &-name {
          color: #252525;
          font-family: 'Montserrat';
          font-size: 14px;
        }
        &-icons {
          width: 18px;
          height: 18px;
          border: 1px solid #6C1EB0;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              rect {
                fill: $primary;
                fill-opacity: 1;
              }
            }
          }
        }
        &:hover {
          background: $secondaryhover;
          border-radius: 8px;
          .filters-container-wrapper-icons {
            visibility: visible;
            cursor: pointer;
          }
        }
      }
      &-noResults {
        color: $primary;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 25px 0;
      }
    }
  }
}
.importsPage {
  &-layout {
    padding: 20px;
    width: 100%;
    height: calc(100vh - 60px);
    font-family: 'Montserrat';
    gap: 20px;
    display: flex;
    flex-direction: column;
    &_header {
    }
    .rdg-light {
      .purple-row {
        background-color: #5673c226;
      }
      .first-cell {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .row {
      width: 100%;
      height: 70px;
      border-radius: 8px;
      background-color: #ffffff;
      padding: 20px;
      cursor: pointer;
      box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 15%);
      transition: 0.1s linear all;
      display: flex;
      align-items: center;
      scale: 0.996;
      &:hover {
        transition: 0.1s linear all;
        background-color: #e4e4e4;
      scale: 0.999;
      }
      &-left {
        display: flex;
        flex-direction: column;
        &_title {
          font-weight: 700;
          font-size: 14px;
          font-family: "Montserrat";        
        }
        &_description {
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
  }
  &-tables {
    padding: 20px;
    width: 100%;
    height: calc(100vh - 60px);
    font-family: 'Montserrat';
    display: flex;
    &_expand-container {
      height: 100%;
      display: flex;
      align-items: center;
      .left {
        background-color: #fff;
        width: 18px;
        height: 24px;
        border: 1px solid $primary;
        position: relative;
        right: 9px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          & rect {
            fill: $primary;
          }
        }
      }
      .right {
        background-color: #fff;
        width: 18px;
        height: 24px;
        border: 1px solid $primary;
        position: relative;
        left: 9px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          & rect {
            fill: $primary;
          }
        }
      }
    }
    &_left {
      width: 50%;
      height: 100%;
      padding: 20px;
      border-radius: 10px;
      background-color: #ffffff;
      transition: width 0.8s ease;
      &-title {
        color: #252525;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        margin-bottom: 20px;
      }
      &-filters{
        display: flex;
        gap: 15px;
      }
      .rdg-light {
        height: 90%;
        .table__wrapper{
          padding: 20px 12px 20px 12px;
        }
        .custom__cell-expander {
          &:hover {
            background-color: #d2d8ee;
          }
        }
        .purple-row {
          background-color: #5673c226;
        }
        .failed-row {
          background-color: #c2566f26;
        }
        .ant-select {
          min-width: 270px;
          display: inline-block;
          input{
            height: 30px !important;
          }
        }
        .first-cell {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .no-header {
          display: none !important;
        }
        .tooltip-wrapper{
          display: flex;
            flex-direction: column;
            gap: 10;
          }
          &-row{
            display: flex;
            flex-direction: row;
            gap: 5;
            align-items: center;
          }
      }
    }
  }
}
