@import "../../abstracts/variables";

.tab-wrapper{
    display: flex;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    height: 49px;
    .tab-wrapper-box{
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        border-right: 1px solid #EFEFEF;
        &:first-child{
            border-left: 1px solid #EFEFEF;
        }
        &.wrapped{
            border-right: none;
        }
    }
    .ant-dropdown-trigger{
        cursor: pointer ;
      }
    .tab{
        background-color: #FFFFFF;
        color: $secondary;    
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 100px;
        height: 100%;
    }
    .bar{
        height: 5px;
        width: 50%;
        border-radius: 10px 10px 0px 0px;
        &.active{
            background: $primary;
            height: 5px;
            width: 50%;
            border-radius: 10px 10px 0px 0px;
        }
    }
}