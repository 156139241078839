.fullButton {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    background-color: darkturquoise;
    border: none;
    color: #fff;
}

.buttonDisabled{
    background-color: gray !important;
}