.big__search__bar__container {
  flex-direction: row-reverse;
  display: flex;
  align-items: center;
  gap:10px;
  max-height: 60px;
  height: 30px;
  background: #fff;
  .show__search__input{
    background-color: #F0F0F0;
    border: 1px solid #E0DFE0;
    border-radius: 8px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      width: 16px;
    }
  }
  .popover-search__input{
    padding:0 20px;
  }
  .search__input {
    background-color: #F0F0F0;
    border-radius: 8px;
    border: 1px solid #E0DFE0;
    // width : 80%;
    height:100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
    position: relative;
    // padding: 5px;
    .clear_input_icon {
      cursor:pointer;
    }
    .strict__icon {
      visibility: hidden;
      &.visible {
       visibility: visible;
       position: absolute;
       right: 7px;
       cursor: pointer;
       background-color: #E0DFE0;
       padding: 2px;
       border-radius: 4px;
       transition: all 0.3s ease-in-out;
       opacity: 0.5;
      }
      &.active {
        opacity: 1;
      }
    }
  }
 
  .searchbar-filters{
    // background-color: white;
    background-color: $primary;
    display: flex;
    align-items: center;
    height:30px;
    width: 30px;
    justify-content: center;
    padding:5px;
    border-radius: 50%;
    cursor: pointer;
    & svg {
      & > * {
        stroke: white;
      }
    }
  }
  @include respond(tab-port) {
    width: 100%;
  }

  & input {
    outline: none;
    border: none;
    background: transparent;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    width: 85%;
    color: #252525;
    &::placeholder {
      color: #C1C1C1;
      font-weight: 500;
    }
  }
}
.popover__search__input .ant-popover-inner{
  border-radius: 8px;
}
.popover__search__input .ant-popover-inner-content {
  padding: 10px; 
}