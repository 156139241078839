.collapsable__table {
  width: 85%;
  border-radius: 10px !important;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 0 20px;
  .rdg-light {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin: 20px 0;
    font-family: "Montserrat";
    .empty-rows-view {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  &-header {
    padding: 0 !important;
    border-bottom: 0px !important;
    .custom__header {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      &-title {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        color: #252525;
      }
      &-icons {
        display: flex;
        align-items: center;
        gap: 20px;
        span {
          height: 100%;
          display: flex;
          align-items: center;
        }
        .year {
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 500;
          color: #252525;
        }
      }
    }
  }
  &-loader {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}