.structures__container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  .MuiAccordion-root:before{
    opacity: 0 !important;  
  }
  .MuiPaper-root {
    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        &Inner {
          .MuiAccordion-region {
            position: relative;
            padding-bottom: 35px;
            .rdg-light {
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
              overflow: hidden;
              min-height: fit-content;
            }
          }
        }
      }
    }
  }
  &-loader {
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.bottom {
      width: 100%;
      position: absolute;
      inset: auto 0px 40px 0px;
      height: 50px;
      max-height: 50px;
      z-index: 999;
      background-color: #f5f5f5;
    }
  }
}
.create__structure__modal {
  padding: 20px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 600;
      color: $primary;
    }
    span:last-of-type {
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
        & > * {
          fill: $primary;
        }
      }
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    & > * {
      padding: 0;
      .form_input__label {
        font-family: 'Montserrat';
        font-size: 12px;
        color: #252525;
        font-weight: 600;
        margin-bottom: 5px !important;
      }
      input {
        margin: 0;
        height: 55px;
      }
    }
    .primaryButton {
      background-color: $primary;
      color: #fff;
      border-radius: 8px;
      height: 45px;
    }
    &-autocomplete {
      .MuiAutocomplete-root {
        margin-bottom: 0;
        & input {
          height: auto;
        }
        .MuiFormControl-root {
          .MuiInputBase-root {
            fieldset {
              border-radius: 5px;
              border: 1px solid #d9d9d9 !important;
            }
          }
        }
      }
    }
  }
}
.custom_stufe__loader {
  height: 300px;
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add__new__row {
  position: absolute;
  bottom: 0;
  top: auto;
  height: 35px;
  left: 2px;
  right: 2px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &-content {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    span:first-of-type {
      height: 100%;
      display: flex;
      align-items: center;
    }
    span:last-of-type {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      color: #c1c1c1;
    }
  }
}
