.invalid__csv__errors__container{
    max-height: 500px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    & div{
        border: 1px solid #bdbdbd;
        display: flex;
        height: 50px;
        margin: 10px 0;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        width: 100%;
        color: #cc0000;
        font-family: "Montserrat";
        font-weight: 500;
      
    }
    &::-webkit-scrollbar {
        width: 6px !important;
      height: 7px !important;
    
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #cc0000;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #db1313;
      }
   
 
}
