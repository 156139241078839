.color-picker-with-popover {
  &__input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-condition {
      display: flex;
      justify-content: center;
      align-items: center;

      &-value {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        margin-left: 20px;
      }
    }

    span {
      padding-left: 15px;
      color: #252525;
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.color-picker-space{
  justify-content: space-between;
}
.color-picke-arrow{
  padding-right: 20px;
}
.color-picker-popover {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 260px;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .ant-picker {
    // display: none;
    top: 40px;
    position: absolute;
    visibility: hidden;
  }
}
.ant-popover {
  z-index: 1301;
}
.ant-picker-dropdown {
  z-index: 1302;
}
