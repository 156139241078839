@import '../../abstracts/variables';

.userBoxContanier {
  height: 30px;
  border-radius: 3px;
  border: 1px solid #979797;
  display: flex;
  &.current {
    background-color: #fff;
    border: 1px solid $primary;
  }
  .iconBox {
    width: 32px;
    height: 100%;
    border-right: 1px solid #979797;
    display: flex;
    align-items: center;
    justify-content: center;
    &.current {
      border-right: 1px solid $primary;
    }
  }
  .nameBox {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    max-width: 150px;
    .user {
      color: #979797;
      font-size: 13px;
      font-weight: 600;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 5px;
    }
  }
}
