@import '../../abstracts/variables';

.trainings {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat';

  .empty{
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat"; 
    flex-direction: column;
  }
  &__cards {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 20px 20px;

    @include respond(tab-port) {
      justify-content: center;
    }
  }
}

.trainingsTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;

  @include respond(tab-land) {
    flex-direction: column;
    // justify-items: flex-start;
    align-items: flex-start;
  }

  &__filters {
    display: flex;
    align-items: center;
    margin: 15px 0;
    .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
      height: 44px;
    }
    .MuiFormControl-fullWidth {
      height: 100%;
    }
    .MuiAutocomplete-inputRoot {
      height: 100%;
    }
    @include respond(tab-port) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
    }

    & > * {
      // width: 50%;
      // margin-right: 10px;

      @media screen and (max-width: 750px) {
        width: 100%;
        margin-top: 10px;
        margin-right: 0;
      }
    }

    .MuiInputBase-root {
      input {
        margin-right: 0 !important;
      }
    }
  }

  &__fltrs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @include respond(tab-port) {
      width: 100%;
      margin-top: 15px;
    }

    @include respond(phone) {
      flex-direction: column;
    }

    & > * {
      width: 50%;
      margin-right: 10px;

      @include respond(phone) {
        margin-right: 0;
        margin-top: 10px;
      }
    }
    &-rangePicker {
      div {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        gap: 20px
      }
    }
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    top: 0;
    background-color: #f5f5f5;
    padding-right: 5px;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    top: -4px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-bottom: 4px !important;
  }

  .css-17vdz66 {
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      top: -3px;
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
      background-color: #f5f5f5;
      padding-right: 5px;
    }
  }

  label + .MuiInput-formControl {
    margin-top: 10px;
  }

  .css-17vdz66 {
    & > * {
      @include respond(tab-port) {
        margin-top: 5px;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: $primary;
    display: flex;
    gap: 35px;

    span {
      font-weight: normal;
    }
  }

  &__rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    @include respond(tab-land) {
      width: 100%;
    }

    @include respond(phone) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @media screen and (max-width: 780px) {
      flex-direction: column;
    }

    & > * {
      @include respond(tab-port) {
        width: 100% !important;
      }
    }

    // search input
    & :nth-of-type(3) {
      width: 80% !important;

      @include respond(tab-port) {
        width: 100% !important;
        margin: 10px 0 !important;
      }
    }
  }

  &__dropdown {
    margin-bottom: 0 !important;

    fieldset {
      border: 2px solid #c1c1c1;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 6px;

    @media screen and (max-width: 780px) {
      margin-top: 15px;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    grid-gap: 4px;
    gap: 4px;
    // background: #1e8659;
    background: $primary;
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    font-family: 'Montserrat';
    width: 200px;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.trainingCard {
  margin-right: 30px;
  margin-bottom: 30px;
  width: 355px;
  height: 100%;
  box-shadow: 3px 4px 4px 0px #00000026;
  position: relative;
  z-index: 1;
  border-radius: 8px;

  @include respond(tab-port) {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }

  @media screen and (max-width: 660px) {
    width: 100%;
    margin-right: 0;
  }

  &:hover {
    .trainingCard__bottom {
      transform: translateY(0);
      visibility: visible;
      transition: 0.2s ease-in-out;
      z-index: 999;

      @include respond(tab-port) {
        bottom: 8px;
        transform: translateY(100%);
      }
    }
  }

  & .no__img__trainingCard__category {
    background-color: white;
    padding: 6px 6px 6px 20px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius: 8px;

    & .trainingCard__category {
      font-weight: 500;
      padding-top: 5px;
      color: #252525;
    }
    & .trainingCard__title {
      margin-top: -7px;
      color: #252525;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 150px;
    background-color: #f3f5f7;
    padding: 20px 15px;
    cursor: pointer;
    position: relative;

    @include respond(tab-port) {
      width: 100%;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius:8px;
      border-top-right-radius:8px;
    }
  }

  &__category {
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    margin-bottom: 10px;
    z-index: 1;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: $primary;
    z-index: 1;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: #979797;
    padding: 15px;
    background-color: #f0f0f0;
    height:64px;
    width: 100%;
    position: absolute;
    bottom:0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    z-index: -1;

    @include respond(tab-port) {
      bottom: 0;
      transform: translateY(0);
    }

    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & svg {
        & > * {
          stroke: #979797;
        }
      }

      span {
        margin-left: 5px;
      }
    }

    &-icon {
      width: 15px;
      height: 15px;
      fill: #3b505d;
    }
  }
}
.trainingCard__top{
  border-radius: 8px;
}

.toggle__templates {
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 35px;
  color: $primary;
  font-size: 14px;
  font-weight: 600;

  @include respond(tab-port) {
    width: 50%;
  }
}
