.iban-input__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat';
    font-size: 12px;
    margin-left: 1px;
    color: #252525;
    font-weight: 600;
  }
  &-input {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    gap: 5px;
    .inputWrapper {
      width: 49%;
      position: relative;
      input {
        width: 100%;
        height: 40px;
        color: rgb(37, 37, 37);
        border-radius: 5px;
        padding: 16px 16px 16px 12px;
        border: solid 1px #d9d9d9;
        background-color: #fff !important;
        font-size: 13px;
      }
      &-icons {
        position: absolute;
        top: 0;
        right: 15px;
        left: auto;
        bottom: 0;
        display: flex;
        align-items: center;
      }
    }
    .iban-validator-wrapper {
      .primaryButton {
        width: 100px;
        height: 40px;
        margin-top: 0;
        background-color: $primary;
        border-radius: 8px;
        color: #fff;
        font-family: "Montserrat";
        .loader {
          font-size: 2.5px;
        }
      }
    }
  }
}
