@import '../../abstracts/variables';

//Analytics Container
.analytics__container {
  width: 100%;
  max-height: calc(100vh - 80px);
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: 1fr 1fr;
  overflow: auto;
  align-items: center;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}
.analytics__container-grouped {
  width: 100%;
  height: auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0px 30px 30px 30px;
  font-family: 'Montserrat' !important;
  max-width: calc(100vw - 100px);
  overflow-x: scroll;
  .panel-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
  .color-percentage-wrapper-single {
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 15px;
    background-color: #e4e4e4;
    align-items: center;
    .title {
      font-size: 10px;
      position: absolute;
      width: 100px;
      margin: 8px 20px;
      color: #fff;
    }
    &_item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      width: 100%;
    }
  }
  .color-percentage-wrapper {
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 3px;
    cursor: pointer;
    :first-child {
      border-radius: 3px 0px 0px 3px;
    }
    :last-child {
      border-radius: 0px 3px 3px 0px;
    }
    :only-child {
      border-radius: 3px;
    }
    &_item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(1);
      transition: transform 0.1s ease;
      &:hover {
        transform: scale(1.05);
        z-index: 100;
      }
    }
  }
}

.analytics__container--viewDate {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 30px 15px 30px;

  &-view {
  }
  &-date {
  }
}

.isAnalytics__text {
  color: #979797 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

//Dropdown Styles
.analyticDropDown-container {
  max-height: 260px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 6px;
  }
  &-wrapper {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    &-name {
      color: #252525;
      font-family: 'Montserrat';
      font-size: 13px;
    }
    &-icons {
      visibility: hidden;
      display: flex;
      gap: 5px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &:hover {
      background-color: $secondaryhover;
      .leadStatus-container-wrapper-icons {
        visibility: visible;
        cursor: pointer;
      }
    }
  }
  &-noResults {
    color: $primary;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 25px 0;
  }
}

.analytic-popover-leadstatuses {
  & ul {
    width: fit-content;
    padding: 4px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .ant-dropdown-menu-item {
      cursor: default;
      border-radius: 5px;
      padding: 50;
      &:hover {
        background-color: $secondaryhover;
        cursor: pointer;
      }
    }
  }
}

.activity__textarea {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  font-family: 'Montserrat' !important;
  height: 160px !important;
  textarea {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    outline: none;
    margin-top: 5px;
    resize: none;
    height: 200px;
    padding: 20px;
    color: #252525;
  }
  .textarea_max_length {
    color: #ddd;
    font-size: 14px;
    align-self: flex-end;
    margin: 5px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.activityContent__button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 132px);
  overflow: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  .MuiInputBase-root {
    height: 50px;
  }
  .MuiAutocomplete-inputRoot {
    border-radius: 8px !important;
    border: 1px solid #d9d9d9;
    padding-left: 11px !important;
    fieldset {
      border: none;
    }
  }
}

//Radio Buttons
.radio__inputs {
  margin-left: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    color: #252525 !important;
    font-weight: 600 !important;
    font-family: 'Montserrat';
  }
}

.radio__inputs > * {
  flex: 0 0 40%;
  box-sizing: border-box;
  padding: 5px;
}

//Modal Header
.analyticHeader__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  color: $primary;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

//Modal Container
.firstStep__container {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  .Mui-disabled {
    .MuiAutocomplete-endAdornment {
      top: 16px;
    }
  }

  &--third_container {
    display: flex;
    flex-direction: column;
    // height: 100%;
    justify-content: space-between;
  }
  &--items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    &-radioButtons {
      &-users {
        display: flex;
        gap: 10px;
      }
      &-clients {
        display: flex;
        gap: 10px;
      }
    }

    &-clientList {
      width: 100%;
      height: calc(100vh - 370px);
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      margin-top: 10px;
      font-family: 'Montserrat';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &-dropdown {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      &-checkBoxes {
        display: flex;
        gap: 10px;
      }
      &-channelFilters {
        overflow: auto;
        &::-webkit-scrollbar-thumb {
          // background: #0E88F2;
          background: $primary;
          border-radius: 15px;
        }
      }
    }
  }
}

.activity__modal__dropdown__menu--items-each {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  // gap: 10px;

  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: 0px;
  }
  label {
    margin-bottom: 10px;
  }
  .MuiAutocomplete-endAdornment {
    margin-right: 20px;
  }
  .MuiAutocomplete-tag {
    margin: unset;
  }
}

.activity__modal__dropdown__select-status {
  .MuiInputBase-root {
    min-height: 50px;
    height: auto !important;
  }
}
.option-wrapper {
  font-family: 'Montserrat';
  font-weight: 600;
  margin-bottom: 5px;
  color: #979797;
  width: 100%;
  display: flex;
  padding: 4px 8px;
  font-size: 13px;
  max-height: 30px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer !important;
  &:hover{
    background: $secondaryhover;
  }
  .role{
    max-height: 35px;
    min-width: 130px;
  }
}
.firstStep__dropdown {
  font-family: 'Montserrat';
  .MuiFormControl-root {
    .MuiIconButton-label {
      padding-right: 10px !important;
    }
    .MuiOutlinedInput-inputAdornedEnd {
      padding-left: 16px !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none;
    }
    fieldset {
      border: none;
      legend {
        display: none !important;
      }
    }
    label {
      .Mui-focused {
        display: none !important;
      }
      padding-left: 12px;
      font-weight: 500;
      font-family: 'Montserrat';
      font-size: 14px !important;
      top: -2px !important;
    }
    .MuiOutlinedInput-notchedOutline {
      display: none;
      border: none !important;
    }
    .PrivateNotchedOutline-legendLabelled-14 {
      display: none !important;
    }
  }
  .MuiInputLabel-formControl {
    font-family: 'Montserrat';
  }
  .MuiAutocomplete-inputRoot {
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 8px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 15px;
    padding-right: 10px;
  }
}

.flex__radioButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
}

//Channel filters scrollbar
.firstStep__container--items-clientList-channelFilters {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}

//Modal header
.activityReports__container {
  &--navigation {
    margin-bottom: 25px;
  }
}

.costumizeAlert__button {
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  margin-top: 12px;
}

//Dropdown class
.activity__modal__dropdown__menu {
  color: #252525;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &--items {
    display: flex;
    flex-direction: column;

    &-switch {
      width: 100%;
      display: flex;
      margin-bottom: 20px;

      &-items {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        &-repeat {
          display: flex;
          gap: 5px;
        }
      }
    }

    &-previewCard {
      width: 100%;
      border: 1px solid gray;
      border-style: dashed;
      border-radius: 8px;
      height: 200px;
      background-color: #dadada;
      .card__wrapper {
        max-height: 100%;
        .alertsCards__container {
          transform: scale(0.95);
          overflow: hidden;
        }
      }
    }

    & .MuiInputBase-root.Mui-disabled {
      background-color: #f3f3f3 !important;
      border-radius: 8px !important;
    }

    & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: #f3f3f3 !important ;
      border-radius: 8px !important;
    }
  }
}

.secondStep__input input {
  height: 50px !important;
  margin-top: 0px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9;
}

.activity__modal--accordion-title {
  color: #000000 !important;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 700;
}

.activity__modal-color_picker {
  background-color: white;
  width: 0px;
  height: 280px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  flex-wrap: wrap;
  gap: 10px;

  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

//Modal Stepper
.ant-steps-item-title:after {
  left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  cursor: pointer !important;
  padding-left: 0px !important;
}
.activityReports__container--stepper .ant-steps-item-icon {
  margin: 0 !important;
  background-color: #979797 !important;
  border-color: #979797 !important;
  width: 30px !important;
  height: 30px !important;
}
.activityReports__container--stepper .ant-steps-item-icon {
  margin: 0 !important;
  background-color: #979797 !important;
  border-color: #979797 !important;
  width: 30px !important;
  height: 30px !important;
}
.ant-steps-item-container .ant-steps-icon {
  left: 0 !important;
  top: -1px !important;
}

.activityReports__container--stepper
  .ant-steps-item-finish
  .ant-steps-item-icon,
.activityReports__container--stepper
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: $primary !important;
  border-color: $primary !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #979797 !important;
  height: 2px !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: $primary !important;
  height: 2px !important;
}
.ant-steps-item-title:after {
  top: 14px !important;
}

.disabled__labels {
  color: #252525;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.active__labels {
  color: #252525;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.activity__modal--info {
  z-index: 9999999 !important;
  font-family: 'Montserrat' !important;
  & ul {
    margin-top: 5px !important;
    border-radius: 8px !important;
  }
}

.preview__card {
  color: #252525;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 5px;
}

.inputFocus {
  input:focus-visible {
    outline-offset: -1px;
    outline-color: $primary;
    outline-style: outset;
    outline-width: 1px;
  }
}
.goals__skeleton{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  width: 33.3%;
}
.goals__loading {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  padding: 10px 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: transparent;
  &-group{
    position: unset;
    align-items: center;
  }
}
.empty-list-view{
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 600;
  height: calc(100dvh - 200px);
  align-items: center;
  justify-content: center;
}
.status__info-container {
  min-width: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  &--title {
    display: flex;
    align-items: center;
    gap: 10px;
    & .icon {
      height: 100%;
      display: flex;
      align-items: center;
    }
    & .text {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      color: #252525;
    }
  }
  &--status {
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 3px;
    height: 27px;
    &-iconWrapper {
      padding: 5px 10px;
      border-right: 1px solid;
      span {
        height: 100%;
        display: flex;
        align-items: center;
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
    &-statusWrapper {
      padding: 5px 10px;
      span {
        height: 100%;
        display: flex;
        align-items: center;
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  &--message {
    span {
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.status__info-popover {
  ul {
    padding: 0;
    li {
      padding: 0;
    }
  }
}
