.date-picker-with-types {
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 6px;
    .date-picker-with-types__input-icons{
      display: flex;
      align-items: center;
      gap: 12px;
    }
    span {
      padding: 4px;
      color: #979797;
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.date-picker-types {
  display: flex;
  flex-direction: column;
  width: 280px;
  // height: 340px;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .ant-picker {
    // display: none;
    top: 50px;
    position: absolute;
    visibility: hidden;
  }
}
.ant-popover {
  z-index: 1301;
}
.ant-picker-dropdown {
  z-index: 1302;
}