.gruped__select-wrapper {
  padding: 10px;
  &-item {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      padding: 0 10px;
      font-size: 14px;
      .item__name {
        font-family: 'Montserrat';
        font-size: 14px;
        color: #979797;
        font-weight: 600;
      }
      .item__icon {
        &.expanded {
          transform: rotate(-180deg);
        }
      }
    }
    .children_wrapper {
      max-height: 200px;
      overflow: auto;
      padding: 15px 0px;
      &-item {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        padding: 0px 10px;
        .item__name {
        color: #979797;
          font-family: 'Montserrat';
          font-family: 12px;
          margin: 2px 0;
          font-weight: 500;
        }
        &:hover {
          background-color: #f5f5f5;
          transition: 0.2s all ease-in;
        }
      }
    }
  }
}
.grouped__select {
  width: 100% !important;
  .ant-select {
    width: calc(50% + 10px);
  }
  &.salesOrg__select {
    .ant-select {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .options-wrapper {
    max-height: 150px;
    overflow: auto;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-top: 20px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px;
    &-no_data {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      span {
        color: #d9d9d9;
        font-weight: 600;
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
    &-item {
      background-color: #e9e9e9;
      padding: 7px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      gap: 10px;
      &-text {
        font-size: 14px;
        font-family: 'Montserrat';
        color: #252525;
        font-weight: 600;
      }
      &-clearIcon {
        border-radius: 50%;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a8a8a8;
        cursor: pointer;
      }
    }
  }
}
