@import '../../../../../../common/styles/abstracts/variables';
.statistic-and-documnets {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  &--bottom-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &--icons {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px;
      .add-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      svg {
        cursor: pointer;
      }
    }
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {
    display: none !important;
  }
}

.statistics__upload__documents {
  background-color: #fff;
  height: 100vh;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .loadingContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-text {
      font-weight: 700;
      font-size: 20px;
      color: $primary;
    }
  }
  &-body {
    margin-top: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 22px;
    .modal__dropdown__menu {
      display: flex;
      flex-direction: column;
      label {
        color: #252525;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .MuiInputBase-root {
        height: 50px;
        border-radius: 8px;
      }
      .MuiAutocomplete-endAdornment {
        padding-right: 15px;
      }
    }
    .form_input__label {
      color: #252525 !important;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
    &-dropdown {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &-files {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      flex-direction: column;
      &-upload {
        width: 100%;
        &-file {
          font-family: 'Montserrat';
          font-weight: 600;
        }
      }
      &__file {
        height: 55px;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0px 5px 0px 5px;
        color: #979797;
        font-size: 14px;
        font-weight: 600;
      }
      &__xlsxBox {
        height: 44px;
        width: 44px;
        min-width: 44px;
        background-color: #31b0731a;
        color: #31b073;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &-span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &__pdfbox {
        height: 44px;
        width: 44px;
        background-color: #d00a1226;
        color: #d00a12;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
    }
  }
  &-footer {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    .primaryButton {
      width: 100%;
      height: 100%;
      background: #6c1eb0;
      border-radius: 8px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.client-list-button{
  margin-top: 80px;
}
.upload-box-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  &-label {
    color: #252525;
    font-size: 14px;
    font-weight: 600;
  }
  &-box {
    height: 180px;
    border-radius: 8px;
    border: 2px dashed #e8e8e8;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 8px;
    gap: 6px;
    cursor: pointer;
    &__label {
      color: #252525;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.custom--header-title {
  width: 100%;
}

.statistics__upload__documents-body-dropdown {
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: unset;
  }
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 5px;
  }
  .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root
    .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding: unset;
    height: 50px;
  }
  .css-1qqsdnr-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    font-weight: 600;
    padding: 16.5px 20px 16.6px !important;
  }

  .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
    margin-right: 11px;
  }
}
