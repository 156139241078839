@import "../abstracts/variables";
.table_part {
}
.toggle-container {
  cursor: pointer;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ECECEC;
  color: $primary;
 font-weight: 600;
font-size: 16px;
  img {
    width: 19px;
    height: 19px;
    border-radius: 50%;
  }
}
.parent_container {
  .MuiTableCell-paddingNone {
    padding: 0 !important;
  }
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone {
    div:first-child {
      min-height: 34px;
      max-height: 34px;
      button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
        padding: 5px;
      }
    }
  }
  thead.MuiTableHead-root tr th {
    padding: 5px 16px;
  }
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    padding-left: 16px;
  }
}
.actions-custom {
  display: flex;
  justify-content: center !important;
  width: 100%;
}
.MuiTable-root {
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
}
.MuiTableCell-root {
  font-family: "Montserrat" !important;
  font-size: 13px !important;
  font-weight: 500;
}
.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  > div
  ::-webkit-scrollbar-thumb {
  // background-color: #d6d6d6 !important;
  background: $primary;
  width: 5px !important;
  border-radius: 25px;
  height: 7px !important;
}
.Component-horizontalScrollContainer-12 ::-webkit-scrollbar {
  width: 10px !important;
  height: 7px !important;
}
.MuiPaper-root ::-webkit-scrollbar {
  width: 1px !important;
  height: 7px !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.material__table__no__data {
  display: flex;
  flex: row;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.material__table__no__data__img {
  width: 16px;
  height: 20px;
  fill: #707070;
}

.material__table__no__data__text {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 4.29;
  color: #7d8c96;
  margin-left: 5px;
}

// .Component-horizontalScrollContainer-5{
// 	min-height: 450px !important;
// }

.dashboard__right__side .Component-horizontalScrollContainer-5 {
  min-height: 100% !important;
}
