.main__wrapper {
    padding: 10px;
    display: flex;
    gap: 15px;
    overflow: hidden;
}
.left__wrapper {
    max-width: 370px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 2px 4px 12px 0px #00000014;
    border-radius: 8px;
}
.right__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    max-width: calc(100% - 385px);
    width: calc(100% - 385px);
    padding: 10px;
    box-shadow: 2px 4px 12px 0px #00000014;
    border-radius: 8px;
    &-items {
        display: flex;
        gap: 15px;
        max-width: 100%;
        overflow-x: auto;
    }
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  overflow: hidden;
  appearance: none;
  outline: none;
  min-width: 350px;
  width: 350px;
  max-width: 350px;
  margin: 10px;
  min-height: 75vh;
  max-height: 75vh;
  transition: background-color 350ms ease;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  &-search {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    .big__search__bar__container {
        flex: 1;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    &-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .grab__icon {
        cursor: grab;
      }
      &-actions {
        display: flex;
        align-items: center;
        gap: 10px;
        & > * {
          cursor: pointer;
        }
      }
    }
    &-name {
      margin: 10px 0;
      border: 1px solid #979797;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      padding: 10px;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        color: #252525;
      }
    }
  }
  &__content {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.hover {
    background-color: rgb(235, 235, 235, 1);
  }

  &.horizontal {
    width: 100%;

    ul {
      grid-auto-flow: column;
    }
  }

  &.shadow {
    box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }
}

.Header {
  display: flex;
  padding: 5px 20px;
  padding-right: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &:hover {
    .Actions > * {
      opacity: 1 !important;
    }
  }
}

.Actions {
  display: flex;

  > *:first-child:not(:last-child) {
    opacity: 0;

    &:focus-visible {
      opacity: 1;
    }
  }
}

$font-weight: 400;
$background-color: #fff;
$border-color: #efefef;
$text-color: #333;
$handle-color: rgba(0, 0, 0, 0.25);
$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Wrapper {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    --scale: 1.05;
    --box-shadow: $box-shadow;
    --box-shadow-picked-up: $box-shadow-border,
      -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
    z-index: 999;
  }
}

.Item {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  background-color: $background-color;
  box-shadow: $box-shadow;
  outline: none;
  border-radius: 8px;
  list-style: none;
  border: 1px solid #c1c1c1;
  -webkit-tap-highlight-color: transparent;
  color: $text-color;
  font-weight: $font-weight;
  font-size: 1rem;
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: 0 0px 4px 1px $focused-outline-color, $box-shadow;
  }

  &:not(.withHandle) {
    touch-action: manipulation;
    cursor: grab;
  }

  &.dragging:not(.dragOverlay) {
    opacity: var(--dragging-opacity, 0.5);
    z-index: 0;

    &:focus {
      box-shadow: $box-shadow;
    }
  }

  &.dragOverlay {
    cursor: inherit;
    /* box-shadow: 0 0px 6px 2px $focused-outline-color; */
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
    opacity: 1;
  }

  &.color:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 3px;
    display: block;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--color);
  }

  &:hover {
    .Remove {
      visibility: visible;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    .grab__icon {
        height: 100%;
        display: flex;
        align-items: center;
    }
    &-name {
      background-color: #f0f0f0;
      border-radius: 4px;
      padding: 5px 15px;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 10px;
      span {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #979797;
      }
    }
    &-actions {
      display: flex;
      align-items: center;
      gap: 10px;
      & > * {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  &__options {
    margin: 10px 0 0 25px;
    max-width: 70%;
    &-single {
      margin: 4px 0;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      &-label {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #979797;
        white-space: nowrap;
        overflow: hidden;           
        text-overflow: ellipsis;
      }
    }
  }
}

.Remove {
  visibility: hidden;
}

.Actions {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}
.drag__and__drop-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0000001A;
    padding: 10px;
    &-actions {
        display: flex;
        align-items: center;
        gap: 15px;
        & > * {
            cursor: pointer;
        }
    }
    &-title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        color: #252525;
    }
}