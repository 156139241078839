@import '../../abstracts/variables';

.import__order__container {
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & .back__icon__import {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  & .confirm__uload__csv {
    position: absolute;
    top: 20px;
    right: 3%;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 15px;
    color: #fff;
    background-color: $primary;
    height: 50px;
    border-radius: 8px;
    padding: 0px 20px 0px 20px;
    cursor: not-allowed;
    &.uploaded {
      color: #fff;
      cursor: pointer;
    }
  }

  & .uploaded__csv__container {
    margin-top: 50px;
    width: 94%;
    position: relative;

    & .show__errors {
      cursor: pointer;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 15px;
      color: #cc0000;
      width: 100%;
      background-color: transparent;
    }
    & .rdg-light {
      width: 100%;
      margin-top: 10px;
      min-height: 87vh;
      overflow: auto;
      font-family: 'Montserrat';
      &.blur {
        filter: blur(1.4px);
      }
    }
    & .loader__contianer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.row__header__renderer {
  display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px 7px 15px 7px;
  &-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-checkbox {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 2px;
      border: 1px solid #979797;
      cursor: pointer;
    }
  }
  &-selected {
    display: flex;
    height: 35px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: #979797 !important;
    &-name {
      color: #979797 !important;
      min-width: 90%;
      max-width: 90%;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    &-clear {
      display: flex;
      justify-content: flex-end;
      padding-top: 5px;
      height: 100%;
      width: 10%;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
  & span {
    line-height: normal;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    
  }
  & .MuiAutocomplete-root {
    height: 35px;
    margin-bottom: 0px;
    background-color: #fff;
    border-radius: 10px;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
    legend {
      display: none;
    }
    fieldset {
      border: none !important;
    }
  }
}
