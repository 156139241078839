.radio-label-checked {
	height: 30px;
	color: #3b86ff;
	font-weight: 500;
	padding-right: 16px;

	.MuiTypography-body1 {
		color: #3b86ff;
		font-size: 14px !important;
	}
}

.radio-label {
	padding-right: 16px;

	.MuiTypography-body1 {
		font-weight: 400;
		font-size: 14px;
	}
}

.radio-label-checked-text{
	color: #252525;
	font-family: 'Montserrat';
	font-weight: 600;
	padding-left: 5px;
}