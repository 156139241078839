.privacy__policy__container{
    width: 100%;

    & .privacy__policy__header{
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(218, 218, 218);

        & .privacy__policy__header__items{
            width: 70%;
            padding: 10px 0;
            display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            // flex-direction: column;
      
            & img{
                width: 30px;
                cursor: pointer;
            }
            & .privacy__policy__oneri__logo{
                margin : 0 auto;
                display: flex;
                align-self: center;
                width: 100px;
                cursor: default;
            }
           
        }
    }

    & .privacy__policy__footer{
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgb(218, 218, 218);
        padding:20px;

        & .privacy__policy__footer__items{
            padding: 10px;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
      
           
            & .privacy__policy__oneri__logo{
                width: 100px;
                cursor: default;
            }
            & p{
                color:#3c515e;
                font-family: "Montserrat";
                font-weight: 600;
            }
           
        }
    }

    .privacy__policy__text__container{
        padding: 40px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .privacy__policy__rule__container{
            width: 70%;
            margin-bottom: 20px;
            & .privacy__policy__rule__header{
                font-family: "Montserrat",Sans-serif;
                font-size: 18px;
                color: #7a7a7a;
                margin-bottom: 10px;
                font-weight: 600;

            }

            & .privacy__policy__rule__bigger__text{
                font-family: "Montserrat",Sans-serif;
                 font-size: 22px;
                 color: #8f8f8f;
                 margin-bottom: 10px;
                 font-weight: 600;
            }
            & .privacy__policy__rule__text{
                font-family: "Montserrat",Sans-serif;
                font-size: 22px;
                color: #a3a3a3;
                margin-bottom: 10px;
                font-weight: 400;
                &.list{
                    margin-left: 70px;
                    font-weight: 300;
                    color: #bbbbbb;

                }
            }
        }
    }

}