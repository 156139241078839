.order__details__container{
    background-color:#fdfdfd;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        margin-left: 0;
      }

    & .order__details__top{


        & p{
            font-family: "Montserrat";
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 4.62;
            letter-spacing: normal;
            color: #3b505d;

            & span{
                color: #008955;
            }
        }

        & h1{
            font-family: "Montserrat";
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -1.45px;
            color: #26353e;
        }
        
    }

    & .order__details__bottom{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
       & div{
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #ececec;
       }
       
    }
}

.contact__details__container{
    width: 938px;
    height: 800px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    padding: 30px 47px 42px 60px;
    border: solid 1px #ececec;
    background-color: #fff;

    & .contact__details__info__and__download{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .order__details__semibold__text{
        width: 118px;
        height: 20px;
        margin: 12px 58px 0 1px;
        font-family: "Montserrat";
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #26353e;
    }
    .order__details__normal__text{
        width: 437px;
        height: 23px;
 
        font-family: "Montserrat";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        color: #26353e;

        &.num{
            margin-top: 25px;
        }
     
    }

    .order__details__light__text{
        width: 100%;
        height: 19px;
        margin: 26px 139px 1px 1px;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7d8c96;
    }

    .order__details__light__text__seller__infos{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .order__details__light__text__seller__infos__right{
           margin-left: 50px;
           margin-top: 20px;
        }
    }
}


.contact__details__about__text{
    width: 170px;
    height: 24px;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #26353e;

    &.bottom{
        width: 131px;
        height: 24px;
        margin: 50px 45px 9px 1px;
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #26353e;
    }
}

.order__details__normal__text__details{
    
    width: 733px;
    height: 46px;
    margin-bottom: 10px;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #26353e;
 
}
.order__details__4__rows{
    width: 100%;
    display: flex;
    margin-top: -5px;

    align-items: center;
    .order__details__row{
        width: 25%;
    }
}

.order__details__signatures{
    display: flex;
    justify-content: space-between;
    & img{
        height: 80px;
    }
}


.download__pdf{
    width: 67px;
    height: 60px;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 4;
    letter-spacing: normal;
    color: #1e8659;
    text-decoration: none !important;
    outline: none;
    display: inline-block;
    cursor: pointer;
    padding-left: 20px;

    &.loading{
        color: rgb(143, 20, 20);
    }
}
.imports__details__container{
    // margin-left: 200px;
    background-color:#fdfdfd;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        margin-left: 0;
      }

    & .uploaded__csv__container{
        position: relative;
        width: 100% !important;
        height: 100% !important;
        margin-top: 40px;
        & .loader__contianer{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
   
        }
        .rdg {
            --background-color: #ffffff;
            --header-background-color: #ffffff;
            --row-hover-background-color: #f5f5f5;
            width: 100% !important;
            height: 80vh !important;
            border: none;
      
            .rdg-header-row {
             font-family: "Montserrat";
              background-color: #fafafa;
              height: 40px;
              margin-left: 5px;
      
              .rdg-cell {
                color: #000 !important;
              }
            }
      
            .rowErrors {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 6px;
              width: 27px;
              height: 50px;
              top: 0;
              background-color: #D00a12;
              font-family: "Montserrat";

      
              &.white {
                position: fixed;
                top: 40px;
                z-index: 10;
                height: 50px;
                width: 25px;
                background-color: white;
              }
              svg {
                fill:white
              }

            }
            .succesRows{
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 6px;
                width: 27px;
                height: 50px;
                top: 0;
                background-color: #e7f6f0;
                font-family: "Montserrat";
  
        
                &.white {
                  position: fixed;
                  top: 40px;
                  z-index: 10;
                  height: 50px;
                  width: 25px;
                  background-color: white;
                }
                svg {
                  fill:#1e8659;
                }
            }
            .rdg-row {
              margin-left: 32.5px;
              font-family: "Montserrat";

            }
      
            .error {
                background-color: #D00a1215;
      
              &.rdg-row {
                background-color: #D00a1215;
              }
            }
      
            .rdg-cell {
              display: flex;
              align-items: center;
              color: #131313;
              border: 0.5px solid #ececec !important;
            }
      
            .rdg-cell-editing {
              color: black !important;
            }
          }
    }
  
    
    & .imports__details__top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
        .save__buttons-wrapper {
            display: flex;
            align-items: center;
            gap: 20px;
          }
        &__img{
            cursor: pointer;
            width: 30px;
        }
        & img{
            cursor: pointer;
            width: 30px;
        }
        & .back_icon{
            cursor: pointer;
            width: 30px;
        }

        & p{
            font-family: "Montserrat";
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 4.62;
            letter-spacing: normal;
            color: #3b505d;

            & span{
                color: #008955;
            }
        }

        & h1{
            font-family: "Montserrat";
            font-size: 21px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -1.45px;
            color: #26353e;
        }
        
    }
}
.custom__row-formatter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-actions {
        display: flex;
        gap: 5px;
        align-items: center;
        span {
            display: flex;
            height: 100%;
            align-items: center;
            cursor: pointer;
        }
    }
}
.rdg-header-row {
    font-family: "Montserrat";
     background-color: #f0f0f0;

     .rdg-cell {
       color: #000 !important;
     }
   }