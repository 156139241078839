@import '../../abstracts/variables';

.messages__container {
  @media screen and (max-width: 500px) {
    margin-left: 0;
  }

  & .messages__header {
    width: 100%;
    overflow: hidden;
    height: 60px;
    padding: 13px;
    background-color: #1e8659;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .messages__header__logo__and__search {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .messages__header__logo__and__text {
        & .messages__header__text {
          width: 123px;
          height: 19px;
          margin: 2px 0 1px 6px;
          font-family: 'Montserrat';
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
        }
      }
      & .messages__header__searchbar {
        width: 590px;
        height: 35px;
        padding-left: 10px;
        border-radius: 5px;
        border: solid 1px #e8e8e8;
        background-color: #fff;
        display: flex;
        align-items: center;
        & .form_input {
          padding: 0;
          width: 100%;
          & input {
            flex: 1;
            width: 100%;
            height: 33px;
            padding: 10px;
            outline: none;
            border: none;
            background: transparent;
            font-family: 'Montserrat';
            font-size: 15px;
            line-height: 1.67;
            color: #3b505d;
            margin: 0;
            &::placeholder {
              opacity: 0.5;
            }
          }
        }
        & img {
          margin-top: 4px;
          opacity: 0.5;
        }
      }
    }
    & .messages__header__user__info {
      display: flex;
      align-items: center;
      & p {
        height: 15px;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        margin-right: 5px;
      }
      & .messages__header__user__info__img {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        &.char {
          background-color: coral;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: 'Montserrat';
        }
      }
    }
  }
  & .messages__bottom__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-leftRight {
      display: flex;
      height: calc(100vh - 60px);
    }
  }
  & .notifications-right-container{
    width: calc(100% - 230px);
    height: auto;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    max-height: calc(100vh - 60px);
    gap: 20px;
    padding: 10px 20px 10px 20px;
    overflow-y: scroll;
    .empty{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: calc(100vh - 60px);
      .empty-text{
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 13px;
      }
    }
    .notifications__loading {
      position: absolute;
      bottom: 0;
      left: 300px;
      right: 0;
      width: calc(100% - 230px);
      z-index: 2;
      padding: 10px 15px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      display: flex;
      justify-content: center;
      background-color: transparent;
      &-group{
        position: unset;
        align-items: center;
      }
    }
  }
  & .messages__right__side__container {
    width: calc(100% - 230px);
    height: auto;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    .form_input input {
      padding-left: 20px;
    }
    & .message__sent__time-head {
      margin-left: 10px;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.1px;
      color: #a4a4ac;
      align-self: center;
      width: 100%;
      display: flex;
      border-left: none;
      justify-content: center;
    }
    .inside__topic__container__loader-up {
      overflow: auto;
      width: 100%;
      height: calc(100vh - 120px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }
    .inside__topic__container__loader-down {
      overflow: auto;
      width: 100%;
      height: calc(100vh - 120px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 70px;
    }
    & .MuiFormControl-root {
      &:focus-visible {
        outline: none;
      }
    }
    & .autocompleteClass {
      min-width: 100%;
    }

    &
      .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon.css-1kpdewa-MuiAutocomplete-root {
      width: 100%;
      &:focus-visible {
        outline: none;
      }
    }
    &
      .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      &:hover {
        outline: none;
      }
    }
    &
      .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
      min-height: 70px;
      &:hover {
        outline: none;
        border: none;
      }
    }
    &
      .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
      display: none;
    }
    & .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
    & .bottom__components__form {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    & .bottom__components__container {
      border-left: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      background-color: white;
      box-shadow: 4px 4px 6px 0px #00000015;
      border-radius: 40px;
      z-index: 51;
      & .emoji__and__attachments {
        height: 39px;
        border-left: none;
        padding-left: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        &-uploadComplete {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .vertical_border {
          background-color: #979797;
          width: 1px;
          height: 30px;
        }
        & .attach__logo {
          margin-top: 8px;
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
        & .emoji__logo {
          fill: #707070;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .selected_images_wrapper {
        display: flex;
        gap: 5px;
        &-img {
          position: relative;
          &-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            border: 1px solid #252525;
            border-radius: 5px;
            box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
            .file-loader {
              font-size: 3.4px !important;
            }
          }
          img {
            height: 40px;
            width: 40px;
            border: 1px solid #252525;
            border-radius: 5px;
            box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
          }
          p {
            height: 40px;
            width: 60px;
            border: 1px solid #252525;
            border-radius: 5px;
            box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: nowrap;
            line-height: 40px;
          }
          .remove_img_icon {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #94989a;
            font-size: 8px;
            cursor: pointer;
            position: absolute;
            top: -7px;
            right: -5px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      & .input__and__send__icon {
        display: flex;
        height: 62px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 20px 20px 20px 0;
        & .form_input {
          width: 97%;
        }
        & .send__message__input {
          font-family: 'Montserrat';
          border: none;
          outline: none;
          margin: 0px;
          &.disabled {
            cursor: not-allowed;
          }
        }
        & .send__message__input ::placeholder {
          color: #a4a4ac;
        }
        & .send__logo {
          width: 24px;
          height: 24px;
          &.disabled {
            cursor: not-allowed;
          }
          &:hover {
            & g {
              fill: $secondaryhover;
            }
          }
          cursor: pointer;
          & g {
            fill: #707070;
          }
        }
      }
    }

    & .inside__topic__container {
      overflow: auto;
      width: 100%;
      height: calc(100vh - 120px);
      display: flex;
      flex-direction: column-reverse;
      padding-top: 10px;
      background-color: #f5f5f5;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(119.24deg, #9815965c 0%, #1c4ccf6f 100%);
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
        border-radius: 10px;
        background: rgba(68, 34, 139, 0.8);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
      }

      & .single__message__container {
        width: 100%;
        display: flex;
        gap: 10px;
        margin-bottom: 30px;
        padding-left: 14px;
        padding-right: 14px;
        &-threeDots {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-threeDotsAndContainer {
          display: flex;
          flex-direction: row-reverse;
          gap: 10px;
          &.myMessage {
            flex-direction: row;
            gap: 10px;
          }
        }

        .message__content {
          display: flex;
          flex-direction: column-reverse;

          .breakLine {
            border-bottom: 0.5px solid $primary;
            &.myMessage {
              border-color: #fff;
            }
          }
          & .message__text {
            max-width: 222px;
            font-family: 'Montserrat';
            font-size: 13px;
            font-weight: 500;
            text-align: left;
            line-height: 20px;
            &.myMessage {
              text-align: left;
            }

            &.myMessage {
              text-align: left;
            }
            & .mentioned__user {
              font-weight: 600;
              text-decoration: underline;
              color: #f5f5f5;
              cursor: pointer;
              margin-right: 5px;
            }
          }
          & .file__container {
            max-width: 350px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            & .image__and__imageName {
              background-color: #f5f5f5;
              color: #979797;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-radius: 8px;
              padding: 10px 15px 10px 15px;
              cursor: pointer;
              &.myMessage {
                color: #979797;
              }
              .download_icon-box {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                border: 1px solid white;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                & svg {
                  path {
                    fill: #fff;
                  }
                }
                &.myMessage {
                  border-color: $primary;
                  & svg {
                    path {
                      fill: $primary;
                    }
                  }
                }
              }
              & .uploaded__image__info {
                font-family: 'Montserrat';
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.1px;
                background-color: transparent;
                word-wrap: break-word;
                color: #fff;
                display: flex;
                gap: 10px;
                align-items: center;
                &-upload {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                }

                &-textAndKb {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;

                  & .myMessage {
                    width: 150px;
                    display: flex;
                    flex-direction: column;
                    color: #979797;
                    gap: 5px;
                  }
                }

                &-name {
                  font-weight: 600;
                  color: #979797;
                  max-width: 150px;
                  font-size: 11px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                &-size {
                  font-size: 8px;
                  color: #979797;
                  align-items: flex-start;
                  &.myMessage {
                    color: #979797;
                    font-size: 7px;
                  }
                }
              }
              & .uploaded__image {
                width: 213px;
                height: 159.8px;
                object-fit: cover;
              }
              &.myMessage {
                .uploaded__image__info {
                  color: white;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }
              }
              .file_type {
                display: flex;
                align-items: flex-end;
                gap: 5px;
                width: auto;
                &-icon {
                  padding: unset;
                }
                &-name {
                  background-color: transparent;
                  margin-bottom: 10px;
                  color: $primary;
                  &.myMessage {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .nameAndChar {
          display: flex;
          justify-content: flex-start;
          & .myMessage {
            display: flex;
            justify-content: flex-end;
          }
        }

        .bottom {
          position: relative;
          color: unset;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          word-break: break-word;
          align-items: baseline;
          display: flex;
          gap: 5px;
          width: 100%;

          & .myMessage {
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            word-break: break-word;

            display: flex;
          }
        }
        &-box {
          color: $primary;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          word-break: break-word;
          background-color: #fff;
          display: flex;
          padding: 15px 20px;
          gap: 10px;
          border-radius: 0px 25px 25px 25px;
          box-shadow: 0 4px 8px rgb(0 0 0 / 2%);
          flex-direction: column;
          .message__popover-icon {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(-90deg);
          }
          &.myMessage {
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            word-break: break-word;
            background-color: $primary;
            display: flex;
            padding: 15px 20px;
            gap: 10px;
            border-radius: 25px 0px 25px 25px;
            box-shadow: 0 4px 8px rgb(0 0 0 / 2%);
            .single__message__image__container {
              .single__message__image {
                background-color: #fff;
                color: $primary;
              }
            }
          }
        }
        & .single__message__container-box-wrapper {
          display: flex;
          gap: 10px;
          &.myMessage {
            flex-direction: row-reverse;
          }
          & .message__sent__time {
            font-family: 'Montserrat';
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            color: #a4a4ac;
            margin-top: 5px;
            align-items: flex-end;
          }
        }
        &.myMessage {
          justify-content: flex-end;
          gap: 10px;
        }
        & .single__message__image__container {
          & .single__message__image {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 18px;
            background-color: $primary;
            color: #fff;
            font-family: 'Montserrat';
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
        & .name__message__time__container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 15px;

          &.myMessage {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
          }
          & .name__and__time {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            & .message__sender__name {
              font-family: 'Montserrat';
              font-size: 12px;
              font-weight: 600;
              letter-spacing: -0.7px;
              color: $primary;
              & span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.61px;
                color: #a4a4ac;
              }
            }
          }
        }
      }
    }
    .new_messages-indicator {
      margin-bottom: 40px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      &__breakline {
        width: 100%;
        height: 0.3px;
        background: #979797;
      }
      &__text {
        font-size: 12px;
        background-color: #fbfafb;
        color: #979797;
        border-radius: 50px;
        padding: 5px 20px;
        font-weight: 500;
        font-family: 'Montserrat';
        border: 1px solid #979797;
        position: absolute;
      }
    }
    .new_activities-indicator {
      padding: 0 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__text {
        font-size: 12px;
        background-color: #fbfafb;
        color: $primary;
        font-weight: 600;
        font-family: 'Montserrat';
        padding: 10px 20px;
        border-radius: 25px;
        border: 1px solid $primary;
      }
    }
  }

  & .messages__left__side__container {
    padding: 15px;
    width: 350px;
    height: auto;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    @media screen and (max-width: 500px) {
      padding-top: 55px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #c1c1c1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
    & .system-notifications-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      padding: 10px 20px;
      color: $primary;
      min-height: 50px;
      cursor: pointer;
      &.active{
        background-color: $primary;
        color: #ffffff;
      }
      &-title{
        cursor: pointer;
        height: 15px;
        font-family: 'Montserrat';
        font-size: 12.9px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.2px;
      }
      &-icons{
        display: flex;
        align-items: center;
        gap: 10px;
        .count{
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgb(225, 5, 5);
          border-radius: 100%;
          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          max-height: 20px;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
    & .messages__topic__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      padding: 10px 20px;
      & .messages__left__topic {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        & .messages__topic__container__name__and__icon {
          display: flex;
          align-items: center;

          & .messages__topic__container__name {
            cursor: pointer;
            height: 15px;
            font-family: 'Montserrat';
            font-size: 12.9px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: $primary;
          }
          & .messages__topic__expand__icon {
            cursor: pointer;
            width: 25px;
            height: 25px;
            fill: $primary;
            &.rotate {
              transform: rotate(-90deg);
            }
          }
        }
        & .messages__topic__add__icon {
          cursor: pointer;
          svg{
            margin-top: 5px;
          }
        }
        & .messages__topic__name {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 40px;
          padding-top: 5px;
          font-family: 'Montserrat';
          &-topic {
            cursor: pointer;
            font-size: 13px;
            color: $primary;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              font-size: 10px;
              font-weight: 400;
              padding-left: 5px;
            }
            &.active {
              font-weight: bold;
              color: #fff;
            }
          }
          & .last-message {
            color: #979797;
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.active {
              color: #fff;
              font-weight: 500;
            }
          }
          &.privateUnread {
            font-family: 'Montserrat';
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #26353e;
          }
        }
      }
      & .messages__left__private__topic {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        margin-top: 5px;
        &.active {
          color: #26353e;
          background: $primary;
          border-radius: 5px;
        }

        & .messages__topic__name {
          margin-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Montserrat';
          font-size: 13px;
          width: 100%;
          padding: 0 8px;
          color: $primary;
        }
        & .messages__left__private__topic__color {
          min-width: 30px;
          min-height: 30px;
          border-radius: 50%;
          margin-right: 4px;
          font-size: 12px;
          font-family: 'Montserrat';
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $primary !important;
          color: #fff;
          font-weight: 900;

          &.active {
            color: $primary;
            background: white !important;
          }
        }

        & .message-group-color {
          border-radius: 50%;
          background-color: $primary !important;
          color: #fff;
          font-weight: 600;
        }
        & .messages__topic__name__unread {
          width: 33px;
          height: 17px;
          border-radius: 2px;
          background-color: #d46d6b;
          color: white;
          font-family: 'Montserrat';
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
        &-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          & .messages__topic__name__unseen {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #d13532;
            font-size: 10px;
            color: #fff;
            display: flex;
            font-weight: 600;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
          & .last-message-time {
            font-family: 'Montserrat';
            font-size: 10px;
            color: #979797;
          }
          &.active {
            & .last-message-time {
              color: white;
            }
          }
        }
      }
    }
    &-header {
      padding:  10px 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      background: #ffffff;
      border-radius: 8px;
      .search__input {
        background-color: #f0f0f0;
        border-radius: 7px;
        position: relative;
        span {
          cursor: pointer;
          position: absolute;
          right: 10px;
          bottom: 0;
          top: 7px;
        }
      }
      &-actions {
        display: flex;
        gap: 5px;
        .search-icon,
        .notifications-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 31px;
          height: 31px;
          background: #f0f0f0;
          border: 1px solid #E0DFE0;
          border-radius: 7px;
          cursor: pointer;
        }
        .notifications-icon {
          position: relative;
          & .messages__topic__name__unseen {
            position: absolute;
            top: -2px;
            right: 2px;
            background-color: #df6675;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
      }
      &-user {
        display: flex;
        align-items: center;
        gap: 7px;
        .user-photo {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .user-name {
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 12px;
          color: $primary;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &-search{
      max-height: 50px;
    }
  }
}
.messages__modal {
  padding: 20px;
  max-height: 700px;
  min-height: 200px;
  overflow-y: hidden;
  & .modal__container {
    max-height: 100%;
    max-width: 100%;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icons {
        display: flex;
        gap: 10px;
        & > * {
          cursor: pointer;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &-content {
      max-height: 600px;
      max-width: 100%;
      margin: 15px auto;
      img {
        width: 100%;
        max-height: 600px;
        object-fit: cover;
        border-radius: 15px;
      }
      iframe {
        min-height: 600px;
        min-width: 650px;
        border-radius: 15px;
      }
    }
  }
}
.find__user__container {
  & .user__name {
    display: flex;
    width: 140px;
    border: solid 1px #e8e8e8;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    overflow-x: none !important;
    & h1 {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 500 !important;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #252525 !important;
      margin: 0;
    }
    & p {
      padding: 15px;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #1e8659;
      cursor: pointer;
    }
  }
}

.popup_find__user__modal {
  max-width: 300px;
}
.popup_create__channel__modal {
  width: 417px;
  & .create__channel {
    padding: 26px;
    & .primaryButton {
      width: 100%;
      height: 46px;
      margin: 12px 0 11.1px;
      padding: 16px 147px 15px;
      background-color: $primary;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-weight: 600;
    }
    & .close__modal__icon__and__name {
      display: flex;
      width: 100%;
      justify-content: space-between;
      & h1 {
        height: 25px;
        font-family: 'Montserrat';
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -1.06px;
        color: $primary;
      }
      & .close__icon__div {
        width: 26px;
        height: 26px;
        cursor: pointer;
        background-color: #f6f6f6;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & .close__modal__icon {
        cursor: pointer;
        width: 17px;
        height: 17px;
      }
    }
  }
}
.find__user__container {
  width: 100%;
  & .user__name {
    min-height: 50px;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 0 10px;
    &:hover {
      background-color: #f5f5f5;
    }
    border: none;
    .status-wrapper{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      &_title {
        background-color: $primary;
        border-radius: 50%;
        min-width: 35px;
        min-height: 35px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat';
        font-size: 14px;
        color: #fff;
      }
      & h1 {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #26353e;
      }
    }
    & p {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #0e88f2;
      padding: 0;
    }
  }
}
.dropdown__first__char {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
  background-color: $primary;
  color: white;
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.dropdown__user__imagee {
  margin-right: 8px;
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}
.click__to__create__chat {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  & p {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #1e8659;
    cursor: pointer;
    padding-left: 15px;
  }
}
.add{
  gap: 20px;
  .MuiIconButton-root{
    padding-right: 12px;
  }
  .Mui-focused{
    display: none;
  }
}
.datepicker-teams {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 51.69px;
  &--start {
    height: auto !important;
    margin: 0 !important;
  }
  div {
    height: auto;
    input {
      height: 51.69px !important;
    }
  }
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    top: 10 !important;
  }
  .MuiFormLabel-root.Mui-error {
    color: #a8a8a8 !important;
  }
  .MuiInput-underline.Mui-error:after {
    border: none !important;
  }
}
.blank-message-right-side {
  height: 100%;
}
.leave__channel__wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & p {
    color: rgb(170, 2, 2);
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 15px;
  }
}
.reply__message__container {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  &-box {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    background: #fff;
    display: flex;
    padding: 8px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    max-width: 222px;
    background-color: #f5f5f5;
    word-wrap: break-word;
    &.myMessage {
      background-color: #ffffff;
      max-width: 272px;
      min-width: 270px;
    }
    .message__content {
      display: flex;
      flex-direction: column;

      & .message__text {
        font-family: 'Montserrat';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        & .mentioned__user {
          font-weight: 600;
          text-decoration: underline;
          color: #f5f5f5;
          cursor: pointer;
          margin-right: 5px;
        }
      }
      & .file__container {
        width: 100%;
        & .image__and__imageName {
          background: #ffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .download_icon-box {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid white;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
          }
          & .uploaded__image__info {
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: 0.1px;
            background-color: transparent;
            word-wrap: break-word;
            color: #fff;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &-upload {
              display: flex;
              align-items: center;
              gap: 6px;
            }

            &-size {
              font-size: 8px;
              color: #fff;
            }
          }
          & .uploaded__image {
            width: 213px;
            height: 159.8px;
            object-fit: cover;
          }
          &.myMessage {
            .uploaded__image__info {
              color: white;
            }
          }
          .file_type {
            display: flex;
            align-items: flex-end;
            gap: 5px;
            width: 30%;
            &-name {
              background-color: transparent;
              color: #fff;
            }
          }
        }
      }
    }
    .top__part__active {
      gap: 0px !important;
    }
    .top__part {
      display: flex;
      align-items: baseline;
      min-width: 170px;
      margin-bottom: 10px;
      & .reply__message__image__container {
        & .reply__message__image {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 18px;
          background-color: $primary;
          color: #fff;
          font-family: 'Montserrat';
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
      & .name__message__time__container {
        width: calc(100% - 40px);
        margin-left: 5px;
        display: flex;
        justify-content: space-between;
        &-time {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          span {
            font-size: 8px;
            font-weight: 600;
            font-family: 'Montserrat';
            color: #979797;
            display: flex;
            align-items: flex-start;
            height: 60px;
          }
        }
        & .name__and__time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          padding-right: 10px;
          color: #6c1eb0;

          .close_reply_message {
            color: $primary;
            font-weight: 600;
          }
          & .message__sender__name {
            font-family: 'Montserrat';
            font-size: 10px;
            font-weight: bold;
            letter-spacing: -0.7px;

            & span {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: -0.61px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  & .reply__message__container-box-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    & .message__sent__time {
      margin-left: 10px;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.1px;
      color: $primary;
    }
  }
  & .message__text {
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #979797;
  }
  .reply__message {
    padding: 10px 0 0 4px;
    cursor: pointer;
  }
}
.popper__container {
  background-color: #fff;
  max-height: 300px;
  width: 400px;
  margin-bottom: 15px;
  border: 1px solid #dadada;
  overflow: auto;
  border-radius: 15px 15px;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: #e9eefa;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(68, 34, 139, 0.8);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
  }
  &-user {
    padding: 5px 15px;
    font-family: 'Montserrat';
    color: #252525;
    cursor: pointer;
    font-size: 14px;
    &:first-of-type {
      margin-top: 14px;
    }
    &:last-of-type {
      margin-bottom: 14px;
    }
    &.active {
      background-color: $secondaryhover;
    }
    &:hover {
      background-color: #f5f5f5;
      opacity: 0.5;
    }
  }
}
.chat_info_container {
  overflow: auto;
  min-height: 100vh;
  &-header {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    padding: 10px 15px;
    align-items: center;
    .close_modal-icon {
      cursor: pointer;
    }
    .title {
      font-size: 16px;
      color: $primary;
      font-weight: 600;
      font-family: 'Montserrat';
    }
  }
  &-info {
    padding-top: 5px;
    .photo_and_name {
      gap: 7px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Montserrat';
      .image {
        width: 100px;
        height: 100px;
        .channel_logo {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $primary;
          border-radius: 50%;
          color: #fff;
          font-size: 40px;
          font-weight: 600;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .name {
        margin-top: 14px;
        font-weight: 700;
        font-size: 20px;
        color: $primary;
      }
      .user_role {
        font-size: 14px;
        font-weight: 500;
        color: #979797;
      }
    }
  }
  &-actions {
    background: #fff;
    position: absolute;
    bottom: 0;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px;
    border-top: 1px solid #f5f5f5;
    span {
      font-family: 'Montserrat';
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      color: #979797;
    }
  }
  &-tabs {
    width: 93%;
    margin: 15px auto;
    display: flex;
    border-radius: 8px;
    background: #e5e5e5;
    padding: 3px;
    & > * {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
      color: #252525;
      cursor: pointer;
    }
    & .active {
      background: #fff;
      color: #252525;
      border-radius: 7px;
      box-shadow: 1px 2px 4px 0px #00000026;
      pointer-events: none;
    }
  }
  .personal__info {
    padding: 0px 20px;
    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: $primary;
      padding-bottom: 10px;
    }
    .informations {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-details {
        width: 50%;
        padding: 5px 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        & p {
          font-family: 'Montserrat';
          font-size: 14px;
          color: #979797;
        }
        & span {
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 14px;
          color: #252525;
          padding-left: 10px;
        }
      }
    }
  }

  .channel_participiants {
    overflow: hidden;
    height: calc(100vh - 350px);
    &-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      color: $primary;
      font-size: 16px;
      padding-bottom: 20px;
      padding-left: 20px;
    }
    &-admins {
      padding-left: 20px;
      .top_part {
        margin-bottom: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Montserrat';
        .chat_admins-top {
          font-size: 14px;
          font-weight: 600;
          color: #979797;
        }
        .actions-top {
          width: 36px;
          height: 16px;
          background: $primary;
          border-radius: 20px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .bottom_part {
        margin-bottom: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Montserrat';
        font-weight: 600;
        .chat_admins-bottom {
          color: #252525;
          padding: 0 20px 10px 20px;
        }
        .actions-bottom {
          cursor: pointer;
          padding-right: 10px;
        }
      }
    }
    &-users {
      padding-right: 20px;
      padding-bottom: 30px;
      height: calc(100vh - 440px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #e9eefa;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(68, 34, 139, 0.8);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
      }
      .top_part {
        padding-left: 20px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Montserrat';
        .chat_users-top {
          font-size: 14px;
          font-weight: 600;
          color: #979797;
        }
        .actions-top {
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          font-family: 'Montserrat';
          cursor: pointer;
          font-weight: 700;
        }
      }
      .bottom_part {
        margin-left: 20px;
        &-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: 'Montserrat';
          font-weight: 600;
          padding-left: 10px;
          padding: 10px 20px 10px 20px;
          &:hover {
            background-color: #f0f0f0;
            border-radius: 8px;
          }
          .chat_users-bottom {
            color: #252525;
            .deactivated{
              color: #707070;
            }
          }
          .actions-bottom {
            color: #979797;
            font-size: 12px;
            padding-right: 10px;
            cursor: pointer;
          }
          &-actions {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
  &-media-messages{
      .files{
        padding-top: 0px !important;
      }
  }
  &-media {
    &:first-child {
      margin-top: 20px;
    }
    height: calc(100vh - 291px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: linear-gradient(119.24deg, #9815965c 0%, #1c4ccf6f 100%);
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
      border-radius: 10px;
      background: rgba(68, 34, 139, 0.8);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
    }
    &-loader {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-wrapperbox{
      margin: 20px;
      border: 1px solid #d9d9d9;
      padding: 20px;
      border-radius: 10px;
    }
    &-wrapper {
      height: 100%;
      .title {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Montserrat';
        font-size: 14px;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        width: 430px;
        padding: 8px 20px;
        &__input {
          label {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
        &__Upload-icon {
          position: absolute;
          background-color: #d9d9d9;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          top: 0px;
          right: 0px;
        }
        .close_modal_icon {
          cursor: pointer;
        }
        .media__text {
          color: #c1c1c1;
        }
        .media__action {
          color: #979797;
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
        }
      }
      .chat-info-no-data{
        justify-content: center;
        text-align: center;
        align-items: center;
        display: flex;
        height: 100%;
        width: 100%;
        &__text{
          color: #979797;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 600;
        }
      }
      .files {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        padding-top: 20px;
        .box {
          width: 113px;
          height: 140px;
          border: 2px solid #d9d9d9;
          border-radius: 10px;
          cursor: pointer;
          position: relative;
          .pdf-logo {
            background-color: #fff;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
          & .download__icon,
          .preview__icon {
            visibility: hidden;
            display: none;
          }
          &:hover {
            opacity: 0.5;
            position: relative;
            .pdf-logo {
              visibility: hidden;
            display: none;
            }
            .download__icon,
            .preview__icon {
              visibility: visible;
              position: absolute;
              display: block;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;
            }
          }
          .remove__icon {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: #d9d9d9;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 8px;
          }
        }
      }
    }
    &-loader {
      height: calc(100vh - 291px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.messages-modal-links {
  min-height: 100% !important;
}
.search__messages-modal {
  min-height: 100vh;
  max-height: 100vh;
  padding: 20px;
  overflow: auto;
  position: relative;
  &-header {
    justify-content: space-between;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .close_modal-icon {
      cursor: pointer;
    }
    .modal-title {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
      color: #979797;
    }
  }
  .search__input {
    background-color: #f0f0f0;
    width: 100%;
    border-radius: 7px;
    position: relative;
    & .clear_input_icon {
      position: absolute;
      right: 20px;
      top: 7px;
    }
    & input {
      width: 90%;
    }
  }
  .search_results {
    overflow: auto;
    &-wrapper {
      font-family: 'Montserrat';
      padding: 15px 0;
      border-bottom: 1px solid #f5f5f5;
      font-weight: 600;
      font-size: 12px;
      color: #979797;
      &-header {
        display: flex;
        justify-content: space-between;
        .reply_searched_message {
          visibility: hidden;
        }
      }
      &:hover {
        .reply_searched_message {
          visibility: visible;
          cursor: pointer;
        }
      }
      &-files {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        .file-box {
          background-color: $primary;
          padding: 10px;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
  }

  .result-message {
    cursor: pointer;
    display: flex;
    gap: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-user {
      font-weight: 700;
      color: $primary;
    }
    &-message {
      .matched-word {
        font-weight: 600;
        color: $secondary;
      }
    }
  }
}
.delete_user_modal {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px;
  &-title {
    color: $primary;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    & .primaryButton {
      width: 100px;
      height: 46px;
      margin: 12px 0 11.1px;
      padding: 16px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      color: white;
      border-radius: 8px;
      font-weight: 600;
    }
    & .go_back-btn {
      background-color: #fff;
      border: 1px solid #979797;
      color: #979797;
    }
  }
  .assign-admin-dropdown {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    &-title {
      font-weight: 600;
      color: $primary;
    }
  }
}
.add_users-channel_modal {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #d9d9d9;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  height: calc(100vh - 382px);
  overflow: hidden;
  .MuiAutocomplete-endAdornment {
    margin-top: 4px;
    margin-right: 20px;
  }
  .MuiOutlinedInput-notchedOutline {
    legend {
      width: 0 !important;
    }
  }
  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      :hover {
        border: 1px solid #d9d9d9;
      }
    }
  }
  .edit__and__create__form {
    .close__modal__icon__and__name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 20px;
        font-family: 'Montserrat';
        color: $primary;
        font-weight: 700;
      }
    }
    div {
      .MuiAutocomplete-root {
        .MuiFormControl-root {
          .MuiInputBase-input {
            min-height: 20px;
            max-height: unset;
          }
        }
      }
    }
    .find__user__container {
      max-height: calc(100vh - 540px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #e9eefa;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(68, 34, 139, 0.8);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
      }
    }
    .primaryButton {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      height: 46px;
      margin: 10px auto;
      padding: 16px 147px 15px;
      background-color: $primary;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-weight: 600;
    }
  }
}
.MuiAutocomplete-popper {
  z-index: 9999 !important;
}
.deleted__message__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
  &-inner {
    border-radius: 50px;
    border: 1px solid #c1c1c1;
    padding: 10px 20px;
    border-radius: 0px 25px 25px 25px;
    &.myMessage {
      border-radius: 25px 0px 25px 25px;
    }
    &-text {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      color: #c1c1c1;
    }
  }
  &-time {
    margin-left: 10px;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.1px;
    color: #a4a4ac;
    &.myMessage {
      align-self: flex-end;
    }
  }
}

.clickable__link {
  font-family: 'Montserrat';
  font-size: inherit;
  text-decoration: underline;
  color: #1c4ccf;
  &.myMessage {
    color: #fff;
  }
}
.reply__text--withIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 5px;
  svg {
    width: 20px;
    height: 20px;
  }
}
.reply__text__active {
  padding: 7px 0 5px 50px;
}
.delete-message {
  color: #d00a12 !important;
}

.top {
  width: 100%;
}
.reply__container{
  border-radius: 100px;
  box-shadow: 2px 3px 8px 0px #00000026;
}
.upload__container {
  width: 80%;
  display: flex;
  padding: 10px 40px;
  gap: 20px;
  background: #ffffff;
  margin: 0 auto;
  &-close {
    display: flex;
  }
  &.hasReply {
    position: relative;
    top: 70px;
  }
  &-loader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    &-text {
      font-size: 10px;
      font-weight: 500;
      color: #979797;
    }
    &-box {
      width: 100%;
      height: 6px;
      background-color: #dadada;
      border-radius: 10px;
      overflow: hidden;
      &-progress {
        height: 100%;
        background-color: $primary;
        width: 0;
        color: white;
        text-align: center;
        transition: width 0.3s ease;
      }
    }
  }
  &-main {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
  .image__and__imageName {
    background-color: #f5f5f5;
    color: #979797;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px 10px 8px 10px;
    cursor: pointer;
    flex-direction: column;
    &.myMessage {
      color: #979797;
      min-width: 200px;
    }
    .download_icon-box {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      & svg {
        path {
          fill: #fff;
        }
      }
      &.myMessage {
        border-color: $primary;
        & svg {
          path {
            fill: $primary;
          }
        }
      }
    }
    & .uploaded__image__info {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.1px;
      background-color: transparent;
      word-wrap: break-word;
      color: #fff;
      display: flex;
      gap: 10px;
      &-upload {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      &-textAndKb {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & .myMessage {
          width: 150px;
          display: flex;
          flex-direction: column;
          color: #979797;
          gap: 5px;
        }
      }

      &-name {
        font-weight: 600;
        color: #979797;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 11px;
      }
      &-size {
        font-size: 8px;
        color: #979797;
        align-items: flex-start;
        &.myMessage {
          color: #979797;
          width: unset;
        }
      }
    }
    & .uploaded__image {
      width: 213px;
      height: 159.8px;
      object-fit: cover;
    }
    &.myMessage {
      .uploaded__image__info {
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &-upload {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
    .file_type {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      width: auto;
      &-icon {
        padding: unset;
      }
      &-name {
        background-color: transparent;
        margin-bottom: 10px;
        color: $primary;
        &.myMessage {
          color: #fff;
        }
      }
    }
  }
}
.replyMessage__container {
  position: relative;
  top: 82px;
  right: 0px;
  width: 80%;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e4e4e4;
  margin: 0 auto;
  border-radius: 40px;

  &-main {
    width: 100%;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
    z-index: 50;

    &-nameAndIcon {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Montserrat';

      &-name {
        font-size: 12px;
        font-weight: 600;
        color: $primary;
      }
      &-icon {
        cursor: pointer;
      }
    }

    &-descAndFile {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        display: flex;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #979797;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
        line-height: 12px;
      }
      &-text {
        width: 100%;
        display: flex;
        width: 100%;
        max-height: 49px;
        gap: 20px;

        &-txt {
          width: 50%;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        &-file {
          width: 50%;
          font-size: 10px;
        }
      }
    }
  }
}

.replyClass {
  display: flex;
  align-items: center;
  gap: 10px;
  .uploaded__image__info-upload {
    display: flex;
    gap: 6px;
  }
}

.imageAndName {
  display: flex;
  border: 1px solid #fff;
  border-radius: 8px;
  background: #fff;
  padding: 3px 20px;
  width: 234px;
  height: 40px;
  cursor: pointer;
}

.replyFileSize {
  font-size: 7px;
}

.replyInfoName {
  font-size: 12px !important;
}

.messageTime {
  .myMessage {
    display: flex;
    justify-content: flex-end;
  }
}

.messages__right__side__header__name__and__options {
  width: calc(100% - 230px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 31px;
  background: #fff;
}

.messages__right__side__header__container {
  width: 100%;
  min-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #eaeaea;
  border-left: none;
  height: 60px;

  &-multiSelect {
    min-width: 20%;
    max-width: 70%;
  }
  .MuiAutocomplete-root{
    min-width: 200px;
  } 
  .MuiInputBase-inputAdornedStart{
    width: 124px !important;
    padding-left: 20px !important;
  }

  &-name {
    width: 350px;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    &-title {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 700;
      color: $primary;
    }
  }
  &-name-second{
    width: 316px;
  }
  .MuiAutocomplete-root {
    margin-bottom: 0;
  }
  & .messages__header__searchbar {
    width: 35%;
    padding-left: 10px;
    border-left: solid 1px #e8e8e8;
    background-color: #fff;
    display: flex;
    align-items: center;
    & .form_input {
      padding: 0;
      width: 100%;
      & input {
        flex: 1;
        width: 100%;
        height: 33px;
        padding: 10px;
        outline: none;
        border: none;
        background: transparent;
        font-family: 'Montserrat';
        font-size: 15px;
        line-height: 1.67;
        color: #3b505d;
        margin: 0;
        &::placeholder {
          opacity: 0.5;
        }
      }
    }
    & img {
      margin-top: 4px;
      opacity: 0.5;
    }
  }
  & .header__name__and__members {
    width: 100%;

    &-imageName {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      color: #fff;
      background-color: $primary;
      display: flex;
      justify-content: center;
      span {
        font-size: 20px;
      }
    }
    &-charName {
      display: flex;
      align-items: center;
      gap: 12px;

      &-nameMember {
        display: flex;
        flex-direction: column;
      }
    }

    & .messages__right__side__header__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $primary;
      cursor: pointer;
      &-status {
        padding-left: 5px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  & .messages__right__side__header__number__of__members {
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #7d8c96;
    cursor: pointer;
    & span {
      font-size: 13px;
    }
  }
  .search--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    background: #f0f0f0;
    border: 1px solid #E0DFE0;
    border-radius: 7px;
    cursor: pointer;
  }
  .MuiAutocomplete-endAdornment {
    top: 15px;
    right: 20px;
  }
}

.emptyMessage__continer{
    display: flex;
    flex-direction: column;
    align-items: center;
    &-text{
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      &-first{
        font-family: "Montserrat";
        color: #979797;
        font-weight: 600;
        font-size: 24px;
      }
      &-second{
        width: 250px;
        text-align: center;
        font-family: "Montserrat";
        color: #979797;
        font-weight: 600;
        font-size: 16px;
      }
    }
}

.settle_order_modal{
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-titleAndIcon{
    display: flex;
    justify-content: space-between;
  &-title{
    font-size: 20px;
    font-weight: 700;
    color: $primary;
  }
  &-icon{
   cursor: pointer;
  }
  }
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-input-messages{
  width: 100%;
  input{
    font-size: 13px;
  }
}
