.salesorg-container {
  padding: 20px;
  .table__wrapper {
    padding: 10px;
    .rdg-light {
        font-family: 'Montserrat';
        height: 100%;
        font-weight: 500;
          @media print {
            height: 1000px !important;
          }
        .rdg-header-row {
          background-color: #f0f0f0;
          & .rdg-cell {
            color: #000;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
          }
        }
        .role-cell {
          display: flex;
          align-items: center;
        }
    }
    .roleName{
      color: #828282;
      font-weight: 600;
    }
    .role {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        color: #979797;
        border: 1px solid #6DB070;
        background-color: rgba(109, 176, 112, 0.6509803922);
        height: 50%;
        height: 34px;
        border-radius: 3px;
        padding: 0px 10px 0px 10px;
      &-head {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
}
