@import "../../abstracts/variables";

.contactless__superContainer {
  width: 60%;
  padding: 10px;
  height: 100%;
  font-family: 'Montserrat';
  margin: auto;
}

.contactless__container {
  width: 100%;
  padding: 20px 30px 20px 30px;
  height: auto;
  // font-family: "Montserrat";
  border: 1px solid lightgray;
  border-radius: 20px;

  &--preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.4%; /* 21.504px */
    letter-spacing: -0.72px;
  }

  &--username {
    display: flex;
    gap: 5px;
  }

  &--steps {
    padding: 15px 0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    & .steps__container {
      border-radius: 8px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 10px 30px;
      max-width: max-content;
      & .step {
        color: rgba(37, 37, 37, 0.5);
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        & .icon {
          height: 100%;
          display: flex;
          align-items: center;
        }
        &.active {
          .name {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &--contractData {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-icon-text {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 15px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      font-family: 'Montserrat';
      font-size: 14px;
      &:first-child {
        color: #252525;
        font-weight: 600;
      }
      &:last-child {
        color: rgba(37, 37, 37, 0.5);
        font-size: 12px;
      }
    }
    &--infos {
      display: flex;
      flex-wrap: wrap;
      margin: 15px 0;
      & .group_container {
        width: 50%;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #e8e8e8;
        padding: 10px 0;
        &:nth-child(odd) {
          border-right: 1px solid #e0e0e0;
        }
        &:nth-child(even) {
          padding-left: 20px;
        }
        &__title {
          color: #252525;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 600;
          margin: 20px 0;
        }
        .field__info {
          color: rgba(37, 37, 37, 0.5);
          font-family: 'Montserrat';
          font-size: 13px;
          display: flex;
          padding: 5px 0;
          border-top: 1px solid #e0e0e0;
          &__name {
            width: 30%;
          }
          &__value {
            width: 70%;
          }
        }
      }
    }
  }

  &--deliveryAndBill {
    display: flex;
    &-delivery {
      width: 50%;
      height: auto;
      &-content {
        display: flex;
        flex-direction: column;
      }
      &-phone {
        display: flex;
        justify-content: space-between;
      }
      &-email {
        display: flex;
        justify-content: space-between;
      }
    }

    &-bill {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
    }
  }
}

//OrderViewTitleComponent
.orderview__title {
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &--text {
    display: flex;
    flex-direction: column;
    &-title {
      color: #252525;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 134.4%;
      letter-spacing: -0.63px;
    }
    &-subtitle {
      color: rgba(37, 37, 37, 0.5);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 134.4%;
      letter-spacing: -0.54px;
    }
  }
}

//termConditionsComponent
.termCondition__container {
  width: 100%;
  margin: 10px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  &--borderTop {
    width: 100%;
    border-top: 1px solid lightgray;
    margin: 15px 0px 15px 0px;
  }
  &--title {
    display: flex;
  }
  &--content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    border: 1px solid lightgray;
    border-radius: 8px;
    height: 318px;
    padding: 12px;
    overflow: auto;

    &-firstLine {
      display: flex;
      flex-direction: column;

      &-text {
        color: #252525;
        text-align: justify;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
      }
    }
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(37, 37, 37, 0.5);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
}

//Signature Component

.signatureComponent__container {
  &--firstSection {
    display: flex;

    &-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &--signature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 411px;
    height: 270px;
    border-radius: 8px;
    border: 2px dashed #979797;
    background: #f5f5f5;

    &-component {
      width: 351px;
      height: 176px;
      padding: 7px;
      border: 1px solid #c1c1c1;
      border-radius: 8px;
      background: #fff;
    }

    &-buttons {
      width: 86%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      &-reset {
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 8px 25px;
        border-radius: 8px;
        background: #e4e4e4;
      }
    }
  }
}

.terms__container {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  &-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
    &-title {
      color: #252525;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
    }
  }
  &-content {
    max-height: 250px;
    height: 250px;
    margin: 10px 0;
    overflow-y: auto;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  &-checkbox {
    padding: 0 7px;
    div {
      .MuiFormControlLabel-root {
        .MuiTypography-root {
          color: #252525;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 600;
          padding-left: 7px;
        }
      }
    }
  }
}
.submit__contactless_order-btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & .primaryButton {
    border-radius: 8px;
    background: $primary;
    padding: 7px 35px;
    color: #fff;
    font-family: 'Montserrat';
  }
}
