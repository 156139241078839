@import "../../abstracts/variables";

.order-section-title {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 16px;
}
.order-input {
  width: 24%;
  margin-right: 7px;
  input {
    // padding: 16px 0 24px 10px;
    outline: none;
    height: 40px;
  }
}
.order-inputImage-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.order-inputImage {
  width: 250px;
  background: $primary;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  height: 36px;
  align-items: center;
  // font-family property deleted in this line doesn't affect styles because this selector
  // is repeated twice , so it wasn't neccessary to 
  //have 2 selectors having the same property
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  img{
    cursor: pointer;
  }
}
.order-inputImage-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 10px;
}
.order-inputImage {
  width: 250px;
  background: linear-gradient(94.98deg, $primary 0%, #400077 100%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  height: 36px;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.order-input-dropdown {
  display: flex;
  align-items: flex-end;
  width: 24%;
  margin-right: 7px;
  padding-bottom: 5px;
  &.datepicker{
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled{
      top: 0;
    }
  }
  &.checkbox {
    border-bottom: solid 2px #f5f5f5;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    .MuiFormGroup-root.css-1h7anqn{
      flex-direction: row;
      display: flex;
    }
    .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
      display: flex;
      flex-direction: row;
    //   width: 600px;
    }
  }
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: 0;
    width: 100%;
  }
}
.order-input-media-component {
  width: 100%;
  position: relative;
  &-downloadAll {
    position: absolute;
    top: 10px;
    left: auto;
    right: 0;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.import__order__inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.order__activity {
  margin: 20px 0;
  &--title {
    color: #252525;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
  }
  &--container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin-top: 20px;
  }
}