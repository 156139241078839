@import '../../abstracts/variables';
.container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  .status-container {
    height: 80%;
    display: flex;
    align-items: center;
    border-radius: 3px;
    z-index: 1000;
    transition: 0.3s linear;
    .status-progressing{
      position: absolute;
      z-index: -1;
      height: 100%;
			transition: 0.3s linear;
    }
  }
  .popup_change-status-modal {
      .change-status-modal-container{
        display: flex;
        flex-direction: column;
        gap: 40px;
        height: 100%;
        justify-content: space-between;
        &_body{
          display: flex;
          flex-direction: column;
        }
        &_title{
          color: $primary;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 20px;
        }
        &_buttons{
          display: flex;
          gap: 20px;
          
        }
      }
  }
  .status-icon-container {
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .status-text{
    font-weight: 500;
    padding: 10px;
    color: #fff;
    }
    .flag{
      background-color: red; 
      width: 6px;
      height: 6px;
      border-radius: 50px;
      margin: 0 4px;
    }
    .editable{
      margin-top: 2px;
      padding: 6px;
      cursor: pointer;
    }