.range__picker-wrapper {
    display: flex;
    flex-direction: column;
   &-container {
     display: flex;
     align-items: center;
     gap: 20px;
     .ant-picker {
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        height: 40px;
     }
   }
}