.settings__wrapper {
    .header-component {
        .back__btn {
            display: flex;
            cursor: pointer;
        }
    }
  .items__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    .settings__item {
      background-color: #fff;
      width: 100%;
      padding: 13px 20px;
      border-radius: 8px;
      box-shadow: 3px 4px 12px 0px #00000026;
      display: flex;
      flex-direction: column;
      gap: 5px;
      cursor: pointer;
      &-title {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        color: #252525;
      }
      &-subTitle {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 500;
        color: #252525;
      }
      &:hover {
        transition: 0.3s ease-in;
        background-color: #e4e4e4;
      }
    }
  }
  &-content {
    padding: 20px;
    .userProfile-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .userProfile-section {
        .title {
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 700;
          color: #252525;
        }
        &-content {
          margin-top: 5px;
          border: 1px solid #E4E4E4;
          border-radius: 8px;
          padding: 10px;
          &.colorPickers {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
            & > * {
              width: calc(50% - 20px);
              .picker__title {
                font-family: "Montserrat";
                font-size: 12px;
                font-weight: 600;
                color: #252525;
              }
            }
            .picker__wrapper{
              padding: 0 0 0 20px;
              .color-picker-with-popover__input{
                padding-right: 20px;
              }
            }
          }
          &.settlementID{
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
            .external__id__generator{
              margin-top: 0px;
              &-example{
                font-family: "Montserrat";
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
          .groupedInputs {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
            width: 100%;
            padding-bottom: 15px;
            border-bottom: 1px solid #0000001A;
            padding-top: 15px;
            &:last-of-type {
              border-bottom: none;
              padding-bottom: 0px;
            }
            &:first-of-type {
              padding-top: 0px;
            }
            .custom__input {
              padding: 0px;
              &.hideLabel {
                .form_input__label {
                  visibility: hidden;
                }
              }
              input {
                padding: 16px 10px !important;
                border: 1px solid #E4E4E4 !important;
                color: #979797 !important;
                border-radius: 8px !important;
                height: 40px;
              }
            }
          }
          .upload__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            img {
              height: 80px;
              width: 80px;
              border-radius: 50%;
              object-fit: cover;
            }
            .hideImg {
              visibility: hidden;
            }
            .upload__btn {
              display: none;
            }
            .upload__label {
              border: 1px solid $primary;
              border-radius: 8px;
              padding: 6px 0;
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;    
              cursor: pointer; 
              font-family: "Montserrat";
              font-size: 14px;
              font-weight: 600;
              color: $primary;
              svg {
                height: 15px;
                width: 15px;
                margin-left: 10px;
                path {
                  stroke: $primary;
                }
              }
              &.save {
                background-color: $primary;
                color: #fff;
              }
            }
          }
          &-inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 5px;
            .disabled__input {
              width: 60%;
              padding: 0px;
              input {
                background-color: transparent !important;
                border-radius: 8px !important;
                border: 1px solid #e4e4e4 !important;
                padding: 20px 10px;
                font-family: "Montserrat";
                font-size: 14px;
                font-weight: 600;
                color: #979797 !important;
              }
            }
            .custom__input {
              width: calc(50% - 20px);
              input {
                padding: 16px 20px !important;
                border: 1px solid #E4E4E4 !important;
                color: #979797 !important;
                border-radius: 5px !important;
                height: 40px;
              }
            }
            .iconsWrapper {
                & > * {
                  border: 1px solid #979797;
                  border-radius: 8px;
                  padding: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
            }
            .subtitle {
              font-family: "Montserrat";
              font-size: 14px;
              font-weight: 600;
              color: #979797;
            }
            .language__toggler-wrapper {
               display: flex;
               align-items: center;
               background-color: #E5E5E5;
               border-radius: 10px;
               .item {
                 padding: 5px 25px;
                 font-family: "Montserrat";
                 font-size: 14px;
                 font-weight: 600;
                 color: #252525;
                 cursor: pointer;
                 &.active {
                  background-color: #fff;
                  margin: 3px;
                  border-radius: 8px;
                  box-shadow: 1px 2px 4px 0px #00000026;
                 }
               }
            }
          }
          .draftContent {
            margin-top: 0px;
            .rdw-editor-toolbar {
              border-radius: 8px 8px 0 0;
          }
          .rdw-editor-main {
              border-radius: 0 0 8px 8px;
          }  
          }
        }
        &-bottom {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 5px;
          margin-top: 5px;
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
          .nonClickable {
            color: #979797;
          }
          .clickable {
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }
    .permissions__container {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 20px;
      position: relative;
      .table__wrapper {
        height: calc(100vh - 200px);
        width: 100%;
      }
      & .rdg-light {
        width: 100%;
      }
      .saveButton {
        top: -110px;
        right: 0px;
      }
    }
  }
}
