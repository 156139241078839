@import '../../common/styles/abstracts/mixins';
@import '../../abstracts/variables';

.dashboard__container {
  // padding-top: 30px;
  background-color: #f5f5f5;
  position: relative;
  float: left;
  width: 100%;
  min-height: 100vh;
  // overflow: auto;
  .dashboard__stats {
    width: 100%;
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    .ant-skeleton-element{
      display: flex;
      flex: 1;
    }
    .revenue-skeleton{
      width: 100%;
      background-color: #e1e1e1;
      border-radius: 10px;
      padding: 10px;
    }
  }
  @media screen and (max-width: 500px) {
    width: calc(100% - 100px);
    margin-left: 0;
  }
  .header-component-text-page {
    margin-top: 4px;
  }

  .header-component {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    min-height: 50px;
    border-bottom: 1px solid #efefef;
    flex-wrap: wrap;
    height: fit-content;
    gap: 10px;
    &_lastModified {
      margin-left: 20px;
      font-family: 'Montserrat';
      font-size: 13px;
      font-weight: 600;
      color: $primary;
    }
    &-loader {
      margin: 0 auto 0 40px;
    }

    &-wrapper {
      display: flex;
      gap: 10px;
      min-width: 80px;
      margin-bottom: 5px;
      align-items: center;
      height: 100%;
      padding-right: 20px;
      svg {
        & > * {
          fill: $primary;
        }
      }
    }
    &-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: $primary;
    }
  }

  .no-padding {
    padding: 0;
  }
  // @include respond(tab-port) {
  //   padding: 50px 10px;
  // }

  @include respond(phone) {
    padding: 50px 3px;
  }

  .dashboard__left__side__top {
    justify-content: space-around;
  }

  .clientlist__elements__container {
    padding-right: 30px;
    padding-left: 30px;
  }
  //  .paidorders-filters{
  //     display: flex;
  //     padding: 0 30px;
  //     flex-direction: row;
  // }
  &.singleTraining__container {
    background-color: #fdfdfd;
    padding-left: 40px !important;

    @include respond(tab-port) {
      padding-left: 20px !important;
    }

    @include respond(phone) {
      padding-left: 10px !important;
    }
  }

  .dashboard__left__text {
    & > * {
      font-family: 'Montserrat';
      // margin-top: 20px;
    }

    &.usersCalcDetails {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;

      & .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 400px;
        padding-left: 0px;
        gap: 15px;
      }
      @include respond(tab-port) {
        flex-direction: column;
        flex-wrap: unset;
      }
      .settlementData {
        display: flex;
        align-items: center;
        flex-direction: row;
        // width: 100%;
        // justify-content: space-between;
        margin: 0 auto 0 25px;

        .set__order__dates {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-right: 10px;
          gap: 10px;
          .MuiInputBase-root {
            height: 36px;
            padding-left: 10px;
            padding-right: 10px;
            background-color: white;
            color: #252525;
            font-family: 'Montserrat';
            font-size: 13px;
            font-weight: 600;
          }
          .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
            top: 0;
          }
          .set__order__dates-button {
            font-size: 14px;
            font-weight: 600;
            height: 36px;
            grid-gap: 4px;
            gap: 4px;
            background: $primary;
            border-radius: 3px;
            cursor: pointer;
            color: #fff;
            font-family: 'Montserrat';
            width: 100px;
          }
        }
        &-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          color: $primary;
          font-weight: 600;
          &-status {
            height: 30px;
            margin-left: 10px;
            .container {
              height: 30px;
              .status-container {
                height: 100%;
                .status-text {
                  padding: 5px 10px;
                }
              }
            }
          }
        }
        &-exports {
          display: flex;
          flex-direction: row;
          width: fit-content;
          justify-content: flex-end;
          align-items: center;
          .createuserExport {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: $primary;
          }
          .download__pdf-loading {
            color: rgb(143, 20, 20);
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
          a {
            text-decoration: none;
          }

          img {
            cursor: pointer;
          }
        }
      }
      & .orders__text {
        margin-right: 5px !important;
      }
      &.calc {
        justify-content: space-between;
        .orders__daterange__picker__filters {
          width: 42%;
          display: flex;
          align-items: center;
          margin-right: 15px;
          max-width: 715px;
          .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
            min-width: 70px !important;
          }
          .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
            margin-bottom: 0px !important;
            border: 2px solid #c1c1c1 !important;
            margin-left: 3px !important;
            border-radius: 5px !important;
          }
          .orders-rangepicker {
            width: 50% !important;
            margin-right: 5px !important;
            border-radius: 5px;
            height: 41px;
          }
          @include respond(tab-land) {
            width: 80%;
          }
        }
        .searchbar_small {
          min-width: 250px;
        }
        & .dashboard__left__text_calc {
          margin-right: 10%;
        }
      }
      &.dashboard {
        align-items: flex-end;
        @include respond(tab-port) {
          align-items: flex-start;
        }
        .orders__daterange__picker {
          @include respond(tab-port) {
            width: 100% !important;
            max-width: 300px;
          }
        }
        .css-17vdz66 {
          @include respond(tab-port) {
            width: 100% !important;
          }
          @include respond(phone) {
            flex-direction: column;
          }
        }
        .orders-rangepicker {
          @include respond(phone) {
            margin-bottom: 5px;
          }
        }
        .datepicker__input__and__error {
          @include respond(phone) {
            width: 100% !important;
          }
        }
      }
      .dashboard__left__text__close__button {
        font-size: 20px;
        opacity: 0.5;
        cursor: pointer;
      }
      .dashboard__left__text__create__user__h1 {
        margin-left: 20px;
        font-family: 'Montserrat';
        font-size: 15px;
        font-weight: 900;
        line-height: 4;
        color: #1e8659;
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        @include respond(tab-port) {
          display: flex;
          align-self: flex-end;
        }
      }
    }
    &.users {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 0 20px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
      .back__button__users {
        cursor: pointer;
      }
      .dashboard__left__text-header {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 35px;
        height: 100%;
        @media screen and (max-width: 1200px) {
          width: 80%;
        }
      }
      .dashboard__left__text-actions {
        width: 60%;
        @media screen and (max-width: 1200px) {
          width: 80%;
          flex-direction: column;
          align-items: flex-end;
          // justify-content: flex-start;
        }
      }
      .big__search__bar__container {
        border-radius: 0px;
        height: 36px;
        @media screen and (max-width: 1200px) {
          margin: 0;
        }
      }
      //   .createuserButton{
      //     padding-left: 8px;
      //     padding-right: 8px;
      //     color: white;
      //     display: flex;
      //     align-items: center;
      //     height: 36px;
      //     font-family: "Montserrat";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 12px;
      //     gap: 5px;
      //     background: rgba(68, 34, 139, 0.85);
      //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      //     border-radius: 8px 0px 0px 8px;
      //     cursor: pointer;
      //     justify-content: center;
      //     span{
      //         margin-top: 1px;
      //     }
      // }
      .createteamButton {
        padding-left: 8px;
        padding-right: 8px;
        color: white;
        display: flex;
        align-items: center;
        height: 36px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        gap: 5px;
        background: rgba(68, 34, 139, 0.7);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        justify-content: center;
        span {
          margin-top: 1px;
        }
      }

      .createuserExport {
        background: $primary;
        border-radius: 0px 5px 5px 0px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // justify-self: flex-end;
        svg {
          width: 26px;
          margin-left: 6px;
          margin-top: 2px;
          height: 26px;
        }
        path {
          // display: none;
          fill: white;
          stroke: white;
          stroke-width: 0.2px;
        }
      }
      @include respond(tab-port) {
        flex-direction: column;
        flex-wrap: unset;
      }

      & .orders__text {
        margin-right: 5px !important;
      }
      &.calc {
        justify-content: space-between;
        &.active {
          display: none;
        }
        .paid-orders-filters {
          padding: 10px 0;
          display: flex;
          justify-content: flex-end;
          .orders__daterange__picker__filters {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 5px;
            width: fit-content;
            .popover-filters__dropdowns {
              .MuiAutocomplete-root {
                margin-bottom: 0px !important;
              }
              .MuiAutocomplete-inputRoot {
                border: 1px solid #c1c1c1 !important;
                height: 35px;
                width: 170px;
                .MuiAutocomplete-popupIndicator {
                  color: #c1c1c1;
                }
              }
            }
          }
          .orders-rangepicker {
            border: none;
            margin-right: 2px !important;
            .date__input {
              min-width: 120px;
              width: 170px !important;
              height: 35px;
              border: 1px solid #c1c1c1 !important;
              border-radius: 5px;
            }
          }
        }
        .searchbar_small {
          min-width: 250px;
        }
        & .dashboard__left__text_calc {
          margin-right: 10%;
        }
      }

      &.dashboard {
        align-items: center;
        h1 {
          margin-bottom: 0px;
        }

        @include respond(tab-port) {
          align-items: flex-start;
        }

        .orders__daterange__picker {
          @include respond(tab-port) {
            width: 100% !important;
            max-width: 300px;
          }
        }

        .css-17vdz66 {
          @include respond(tab-port) {
            width: 100% !important;
          }

          @include respond(phone) {
            flex-direction: column;
          }
        }

        .orders-rangepicker {
          @include respond(phone) {
            margin-bottom: 5px;
          }
        }

        .datepicker__input__and__error {
          @include respond(phone) {
            width: 100% !important;
          }
        }
      }

      .dashboard__left__text__close__button {
        font-size: 20px;
        opacity: 0.5;
        cursor: pointer;
      }
      .dashboard__left__text__create__user__h1 {
        margin-left: 20px;
        font-family: 'Montserrat';
        font-size: 15px;
        font-weight: 900;
        line-height: 4;
        color: #0e88f2;
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        @include respond(tab-port) {
          display: flex;
          align-self: flex-end;
        }
      }
    }
    &.clients {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 0px;
      // margin-bottom: 15px;

      .header {
        display: flex;
        align-items: center;
        gap: 35px;
        .back__button_client {
          padding: 0px;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      //   .createuserButton{
      //     // background: #1E8659;
      //     background: rgba(68, 34, 139, 0.85);
      //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      //     border-radius: 8px 0px 0px 8px;
      //     color: white;
      //     display: flex;
      //     align-items: center;
      //     height: 36px;
      //     font-family: "Montserrat";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 12px;
      //     gap: 5px;
      //     padding-left: 4px;
      //     padding-right: 4px;
      //     cursor: pointer;
      //     justify-content: center;
      //     span{
      //         margin-top: 1px;
      //     }
      // }
      .big__search__bar__container {
        // border-radius: 0px 5px 5px 0px;
      }
      .createuserExport {
        background: $primary;
        border-radius: 2px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // justify-self: flex-end;
        svg {
          width: 26px;
          margin-left: 6px;
          margin-top: 2px;
          height: 26px;
        }
        path {
          // display: none;
          fill: white;
          stroke: white;
          stroke-width: 0.2px;
        }
      }
      @include respond(tab-port) {
        flex-direction: column;
        flex-wrap: unset;
      }

      & .orders__text {
        margin-right: 5px !important;
      }
      &.calc {
        justify-content: space-between;
        .orders__daterange__picker__filters {
          width: 70%;
          display: flex;
          align-items: center;
          // max-width: 715px;
          @media (max-width: 1300px) {
            width: unset;
          }
          .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
            min-width: 70px !important;
          }
          .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
            margin-bottom: 0px !important;
            border: 2px solid #c1c1c1 !important;
            margin-left: 3px !important;
            border-radius: 5px !important;
          }
          .orders-rangepicker {
            width: 50% !important;
            margin-right: 5px !important;
            border-radius: 5px;
            height: 47px;
            min-width: 120px;
          }

          @include respond(tab-land) {
            width: 80%;
          }
        }
        .searchbar_small {
          min-width: 250px;
        }
        & .dashboard__left__text_calc {
          margin-right: 10%;
        }
      }

      &.dashboard {
        align-items: center;
        h1 {
          margin-bottom: 0px;
        }

        @include respond(tab-port) {
          align-items: flex-start;
        }

        .orders__daterange__picker {
          @include respond(tab-port) {
            width: 100% !important;
            max-width: 300px;
          }
        }

        .css-17vdz66 {
          @include respond(tab-port) {
            width: 100% !important;
          }

          @include respond(phone) {
            flex-direction: column;
          }
        }

        .orders-rangepicker {
          @include respond(phone) {
            margin-bottom: 5px;
          }
        }

        .datepicker__input__and__error {
          @include respond(phone) {
            width: 100% !important;
          }
        }
      }

      .dashboard__left__text__close__button {
        font-size: 20px;
        opacity: 0.5;
        cursor: pointer;
      }
      .dashboard__left__text__create__user__h1 {
        margin-left: 20px;
        font-family: 'Montserrat';
        font-size: 15px;
        font-weight: 900;
        line-height: 4;
        color: #0e88f2;
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        @include respond(tab-port) {
          display: flex;
          align-self: flex-end;
        }
      }
    }
    // padding: 0 30px;

    & h1 {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 600;
      color: $primary;
      margin-bottom: 0px;
    }

    // & p {
    //   opacity: 0.8;
    //   font-family: "Montserrat";
    //   font-size: 18px;
    //   font-weight: 300;
    //   letter-spacing: -0.87px;
    //   color: #3b505d;
    // }
  }

  @media only screen and (max-width: 1250px) {
    .dashboard__container {
      overflow-y: scroll;
    }
    .dashboard__left__and__right__container {
      flex-direction: column;
      align-items: center;
    }
    .dashboard__left__side__top {
      margin-bottom: 50px;
      flex-wrap: wrap;
    }
    .dashboard__right__side {
      width: 100% !important;
      margin-left: -1px !important;

      @include respond(phone) {
        margin-left: 0 !important;
      }
    }
    .dashboard__left__side {
      width: 100% !important;
    }
  }

  .dashboard__left__and__right__container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background: #f5f5f5f5;
    @media screen and (max-width: 500px) {
      padding: 0 10px;
    }

    .dashboard__right__side {
      width: 100%;
      margin-top: 10px;
      border-radius: 10px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
      background-color: #fff;

      .dashboard__last__product__sales {
        .info__bar__actions {
          height: 45px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 41px 0 26px;
          border-bottom: 1px solid #f5f5f5;
          align-items: center;

          .info__bar__product {
            width: 22%;
            font-size: 12px;
          }
          .info__bar__product,
          & h2 {
            font-family: 'Montserrat';
            font-weight: 600;
            line-height: 4.29;
            color: #26353e;
          }
          & h2 {
            font-size: 11px;
            width: 10%;
            margin-right: 20px;
          }
        }

        .dashboard__last__product__sales__info {
          height: 45px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 41px 0 26px;
          border-bottom: 1px solid #f5f5f5;
          align-items: center;

          & h1 {
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 600;
            line-height: 4.29;
            color: #26353e;
          }
          & p {
            font-size: 30px;
            color: #d8d8d8;
            margin-bottom: 18px;
            cursor: pointer;
          }
        }
      }
    }
    @media only screen and (max-width: 1399px) {
      .dashboard__right__side {
        margin-top: 10px;
      }
    }
    .dashboard__left__side {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include respond(phone) {
        padding: 0 20px !important;
      }

      @include respond(phone) {
        padding: 0 !important;
      }

      width: 100%;
      margin-top: 10px;
      .dashboard__left__side__top {
        // margin-top: -10px;
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        margin-top: 10px;
        justify-content: space-between;
      }
      .dashboard__left__side__bottom {
        width: 100%;
        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
        .graph-skeleton{
          background-color: #e1e1e1;
          height: 600px;
          border-radius: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .leaderboard-skeleton{
          background-color: #e1e1e1;
          border-radius: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          gap: 20px;
        }
      }
    }
  }

  .clientTable {
    padding: 20px;
    margin-bottom: 20px;
    height: 450px;
    .loader__and__table {
      height: calc(100vh - 150px);
    }
    & .rdg-light {
      height: 100%;
      font-family: 'Montserrat';
      .empty-rows-view {
        width: 100%;
        height: 100%;
        justify-content: center;
        color: #8c8c8c;
        display: flex;
        align-items: center;
      }
      .custom__client__cell {
        background-color: transparent;
      }
    }
  }
  .conditions__container {
    padding: 20px;
    display: flex;
    &-tabs {
      display: flex;
      flex-direction: column;
      padding-right: 30px;
      min-height: calc(100vh - 83px);
      flex: 0 1 auto;
      border-right: 1px solid #cecece;
      & .tab_wrapper {
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        .tab {
          color: $primary;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 600;
          padding: 10px 15px;
          border-radius: 5px;
          border-radius: 8px;
          margin-bottom: 5px;
          cursor: pointer;
          &:hover {
            background-color: #f5f5f5;
          }
          &.active {
            background-color: $primary;
            color: #fff;
          }
        }
      }
    }
    &-tables {
      padding: 0 20px;
      padding-right: 0;
      display: flex;
      height: 100%;
      flex: 1 1 auto;
      flex-direction: column;
      gap: 20px;
      .row{
        height: 50px;
        background-color: #e1e1e1;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        .ant-skeleton-element{
          width: auto;
        }
      }
      .wrapper-structure{
        .rdg-light {
          margin-bottom: 40px !important;
          }
      }
      .MuiAccordion-root:before{
          opacity: 0 !important;
      }
      &-title {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        color: #252525;
      }
      &__accordion {
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 0 20px;
        &-header {
          border-bottom: none !important;
          padding: 0;
        }
        .managerVO-container {
        .rdg-light {
          font-family: "Montserrat";
          }
          &_list {
            border: 1px solid #e4e4e4;
            margin-bottom: 10px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            height: 42px;
            font-family: 'Montserrat';
            font-weight: 600;
            &-title {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              padding: 10px;
              background: #f0f0f0;
              border-radius: 8px 0px 0px 8px;
              border-right: 1px solid #e4e4e4;
            }
            &-items {
              display: flex;
              height: 100%;
              align-items: center;
              padding: 10px;
              gap: 5px;
            }
          }
        }
        .rdg-light {
          width: 100%;
          max-width: 100%;
          height: 149px;
          overflow-x: scroll;
          overflow-y: hidden;
          border-radius: 10px;
          margin-bottom: 20px;
          .empty-rows-view {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
          }
        }
        &-header {
          height: 30px;
          font-family: 'Montserrat';
          .custom--header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            &-title {
              color: #252525;
              font-family: 'Montserrat';
              font-size: 14px;
              font-weight: 600;
            }
            &-teams,
            &-dates {
              color: #1c4ccf;
              font-size: 14px;
              font-family: 'Montserrat';
              font-weight: 500;
              display: flex;
              gap: 5px;
            }
            &-icons {
              padding: 0 20px;
              padding-right: 30px;
              min-width: fit-content;
              display: flex;
              align-items: center;
              gap: 20px;
              & > * {
                cursor: pointer;
              }
            }
          }
        }
      }
      &__loader {
        height: calc(100vh - 120px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media only screen and (max-width: 1399px) {
  .dashboard__right__side {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 1191px) {
  .dashboard__right__side {
    margin-top: 45px !important;
  }
}

.user__table-fullName {
  display: flex;
  align-items: center;
}

.user__table__photo__and__name {
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    margin-right: 10px;
    min-width: 30px;
    width: 30px;
    min-height: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: white;
    padding: 3px;
  }
  & p {
    text-decoration: underline;
  }
}

.user__table-green {
  color: $primary;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.user__table__initials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: $primary;
  & span {
    width: 9px;
    height: 18px;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    color: #fff;
  }
}

@media only screen and (min-width: 1300px) {
  .user__details__left__side {
    width: 23% !important;
  }
  .user__details__right__side {
    width: 72% !important;
  }
}

.user__details__tabs {
  display: flex;
  width: 100%;
  gap: 1px;
  .edit__and__create__user__single__tabs {
    background-color: $secondaryhover;
    color: white;
    font-weight: 600;
    &.active {
      background-color: $primary;
      border-radius: 0;
      p {
        background: none;
      }
    }
  }
}

.user__details__bank__tab {
  font-family: Helvetica;
  font-size: 13px;
  color: #556874;
}

.user__details__uploads__tab {
  display: flex;
  flex-direction: column;
  height: 55%;
  width: 100%;
  align-items: center;
  overflow-y: auto;
}

.user__details__outline {
  width: 85%;
  height: 84px;
  margin: 23px 11px 7px 0;
  padding: 9px 0 10px 11px;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
}

.user__details__inside {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.user_detials_pdf_file {
  height: 38px;
  margin-top: 14px;
  color: #555459;
}

.user__details__file__info {
  align-self: center;
  margin-left: 20px;
  margin-top: 15px;
}

.user__detials__file__name {
  font-family: Helvetica;
  font-size: 11px;
  color: #555459;
  cursor: pointer;
}

.clientPage__client-left {
  cursor: pointer;
}

.datepicker__input__and__error {
  border: 2px solid #c1c1c1;
  &.rangepicker__orders {
    display: none;
  }
}

.orders__date__and__create {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.calculation__tabs {
  margin-top: 10px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  width: 20%;

  & h1 {
    font-size: 14px;
    font-family: 'Montserrat';
    padding: 10px 12px;
    font-weight: unset;
    cursor: pointer;
    margin-left: 15px;
    &.active {
      background-color: #e9eefa;
    }
  }
}

@media screen and (max-width: 1515px) {
  .Component-horizontalScrollContainer-6 {
    max-height: 500px;
  }
}

.dashboard__left__text__create__user__h1 {
  margin-left: 20px;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 900;
  color: #1e8659;
  cursor: pointer;
}
.rows-selected-message {
  font-family: 'Montserrat';
  width: 240px;
  max-width: 240px;
  min-width: 240px;
  height: 0px;
  margin-bottom: 3px;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s linear all;
  &-active {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    background: white;
    font-family: 'Montserrat';
    width: 240px;
    max-width: 240px;
    min-width: 240px;
    height: 40px;
    margin-bottom: 1px;
    margin-top: 3px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s linear all;
    .rows-selected-message-box {
      height: 30px;
      color: #3b505d;
      transition: 0.3s linear all;
    }
  }
  &-box {
    height: 0;
    overflow: hidden;
    width: 180px;
    max-width: 180px;
    background: #e9eefa;
    border-radius: 1px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
    transition: 0.3s linear all;
    img {
      margin-top: 1px;
      cursor: pointer;
    }
  }
}
.orders {
  .header-checkboxall {
    .MuiCheckbox-root {
      color: #ffffff !important;
      // padding: 5px !important;
      svg {
        width: 18px;
        fill: #ffffff;
      }
    }
    .Mui-checked {
      color: $primary !important;
      // padding: 5px !important;
    }
  }

  & .toggle__orders__imports {
    max-width: 200px;
    display: flex;
    margin-top: 35px;
    margin-left: 20px;
  }
  & .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .wrapper__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat';
    gap: 10px;
    .createuserExport {
      background: $primary;
      // border-radius: 2px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .createuserExportDisabled {
      background: #cacaca;
      // border-radius: 2px;
      // width: 40px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      // cursor: pointer;
    }

    &-download {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      color: red;
      .download_button {
        cursor: pointer;
        display: flex;
      }
    }
    & .primaryButton {
      background: #f5f5f5;
      color: #1e8659;
      font-size: 14px;
      font-weight: 600;
      border: 2px solid #1e8659;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      width: 100px;
    }
  }
  .wrapper__toggle__orders__imports {
    // border-bottom: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    // @media (max-width: 1200px) {
    //   font-size: 11px;
    // }
    .edit__and__create__user__single__tabs {
      width: unset;
      // max-width: 100px;
      color: $primary;
      font-weight: 600;
    }
    .edit__and__create__user__single__tabs.active {
      // max-width: 100px;
      background-color: white;
      color: white;
      font-weight: 600;
      transition: all ease 0.1s;
      border-radius: 8px;
      p {
        background: rgba(68, 34, 139, 0.5);
        border-radius: 5px;
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
  .wrapper__change-status {
    display: flex;
    align-items: center;
    justify-content: center;
    .createuserButton {
      background-color: $primary;
      &:disabled {
        border: 1px solid #979797;
        img {
          border-radius: 3px;
        }
      }
    }
    .MuiInputBase-root fieldset {
      border: none !important;
    }
    .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
      margin-bottom: 0px;
      width: 230px;
      background-color: $primary;
      height: 36px !important;
      border-radius: 8px;
    }
    .MuiAutocomplete-input {
      color: #ffffff !important;
      font-weight: 500 !important;
      font-family: 'Montserrat';
      font-size: 12px;
      &::placeholder {
        font-weight: 500;
      }
    }
    .MuiIconButton-root {
      // color: white !important;
      color: #ffffff !important;
    }
  }
  .status_btn {
    height: 36px;
    font-family: 'Montserrat';
    font-size: 12px;
    width: 47px;
    background: #cacaca;
    color: white;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .status_btn:hover {
    background: #f5f5f5;
    path {
      stroke: #0e88f2;
    }
  }
  & .noOrderText {
    padding: 5px 10px;
  }
  & .orders__date__and__create {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    & .orders__daterange__picker {
      margin-left: 30px;
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 1025px) {
        flex-direction: column;
      }

      & .orders__daterange__picker__filters {
        width: 100%;
        display: flex;
        align-items: center;
        margin-right: 15px;
        max-width: 715px;

        @include respond(tab-land) {
          width: 80%;
        }

        .orders-rangepicker {
          width: 100% !important;
          margin-right: 5px !important;

          @include respond(tab-land) {
            width: 50% !important;
          }
        }
        .css-17vdz66 {
          width: 100% !important;

          @include respond(tab-land) {
            width: 50% !important;
          }
        }

        & > * {
          width: 50% !important;
        }
      }
      @media only screen and (max-width: 1025px) {
        & .orders__daterange__picker__filters {
          flex-wrap: wrap;
        }

        & .orders__daterange__picker__filters .orders-rangepicker {
          width: 50% !important;
          margin-right: 5px !important;
        }
        & .orders__daterange__picker__filters .css-17vdz66 {
          width: 50% !important;
        }
        & .orders__daterange__picker__filters > * {
          margin-bottom: 10px !important;
        }
      }
      @media only screen and (max-width: 900px) {
        & .orders__daterange__picker__filters {
          align-items: baseline;
          flex-wrap: nowrap;
          flex-direction: column;
        }
        & .orders__daterange__picker__filters .orders-rangepicker {
          width: 100% !important;
          margin-right: 5px !important;
        }
        & .orders__daterange__picker__filters .css-17vdz66 {
          width: 100% !important;
        }
        & .orders__daterange__picker__filters > * {
          margin-bottom: 10px !important;
        }
      }
      & .import__order__container__upload {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat';
        font-size: 17px;
        color: #1e8659;
        font-weight: 600;
        margin-right: 30px;

        @media only screen and (max-width: 1025px) {
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 10px;
        }

        & .order__upload__file__name {
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 10px;
          background-color: white;
          height: 40px;
          padding: 0 10px;
          border: 1px solid #1e8659;
          & .loader {
            font-size: 3px;
          }
        }
        & #order__upload__file__inputupload-photo {
          display: none;
        }
        & .loader__browse__container {
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px !important;
        }
      }
      @include respond(tab-land) {
        width: 100%;
      }

      @include respond(tab-port) {
        flex-wrap: wrap;
        width: 100%;
      }

      & .css-17vdz66 {
        width: 30% !important;

        @include respond(tab-land) {
          width: 50% !important;
        }

        @include respond(tab-port) {
          width: unset !important;
        }

        @include respond(phone) {
          flex-direction: column;
          width: 100% !important;
        }

        & > * {
          @include respond(phone) {
            width: 100% !important;
            flex-direction: column;
            margin-top: 10px;
          }
        }
      }
      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 6px 9px;
      }

      &
        .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
        width: 15%;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        border: 2px solid #c1c1c1;
        border-radius: 5px;
        margin-left: 10px;

        @include respond(tab-land) {
          width: 30% !important;
        }

        @include respond(tab-port) {
          width: unset !important;
        }

        @include respond(phone) {
          width: 100% !important;
          margin-top: 10px !important;
          margin-left: 0;
        }

        & div {
          &
            .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
            height: 44px;
            min-width: 70px !important;
            &:hover {
              outline: none !important;
              border: none !important;
            }

            & input::placeholder {
              color: #84858a;
            }
          }
        }
      }

      @media only screen and (max-width: 900px) {
        .orders__daterange__picker__filters
          .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
          width: 100% !important;
          margin-left: 0;
          margin-bottom: 10px !important;
        }
      }

      // .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
      //   margin-top: 10px !important;
      // }

      .datepicker__input__and__error {
        // border: 2px solid #c1c1c1;
        // padding: 2px !important;
        border-radius: 5px;
        outline: none;
      }
    }
  }
}

.ordersImports {
  .orders__daterange__picker__filters {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 15px;
    @media screen and (min-width: 1200px) {
      width: 100%;
      max-width: 450px !important;
    }
  }
}

.userSpClass {
  min-height: 100vh;
  background: #f5f5f5;
}

.userSpList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  // padding: 0 30px;
  // border-bottom: 1px solid #E0E0E0;
  flex-wrap: wrap;
  .edit__and__create__user__single__tabs {
    color: $primary;
    font-weight: 600;
    font-size: 14px;
  }
  .edit__and__create__user__single__tabs.active {
    background-color: white;
    color: white;
    transition: all ease 0.1s;
    border-radius: 8px;
    p {
      background: rgba(68, 34, 139, 0.5);
      border-radius: 5px;
      padding-left: 6px;
      padding-right: 6px;
      color: white;
    }
  }
  & > * {
    // margin-top: 10px;
  }

  &__activeTab {
    background-color: #d5f7e9;
    // border-bottom: 1px solid #1e8659;
  }

  &__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* grid-gap: 6px; */
    /* gap: 6px; */
    background: white;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 8px;
    width: 320px;
    @media screen and (max-width: 1200px) {
      width: 250px;
    }
    .download_button {
      cursor: pointer;
      margin-right: 10px;
    }

    @include respond(phone) {
      flex-wrap: wrap;
    }

    & > * {
      // margin-top: 10px;
    }

    & .userSpList__flex__select__users {
      color: #1e8659 !important;
      font-family: 'Montserrat';
      cursor: pointer;
      font-weight: 600;
      font-size: 15px;
      margin-right: 15px;
      padding: 15px;
      background-color: white;
      margin-bottom: 0px;
      &.active {
        color: #f50057 !important;
      }
    }
  }

  &__userList {
    margin-right: 15px;
  }

  &__button {
    padding: 16px;
    font-family: 'Montserrat';
  }

  &__btn1 {
    font-size: 13px;
    background-color: rgba(30, 134, 89, 0.21);
  }

  &__btn2 {
    background-color: white;
    color: #1e8659 !important;
    font-size: 15px !important;
    font-weight: bold !important;
  }

  .clientPage__client {
    cursor: pointer;
    padding: 15px;
  }
}
.MuiToolbar-root {
  min-height: 0 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.userSpClass {
  .MuiToolbar-root {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.popover-exports {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 200px;
  gap: 5px;
  a {
    color: #3c3c3c;
    cursor: pointer;
  }
  span {
    color: #3c3c3c;
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
}
.popover-filters {
  display: none;
  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.5;
    color: #3b505d;
  }
  &__dropdowns {
    display: flex;
    justify-content: space-between;
    .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
      width: 49%;
    }
    .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
      margin-right: 0px;
    }
  }
  img {
    align-self: flex-end;
  }
  .orders__daterange__picker__filters {
    display: flex;
    gap: 8px;
    margin-top: 10px;
    .datepicker__input__and__error {
      border: none;
      margin-bottom: 10px;
      .date__input {
        // border-radius: 4px;
      }
    }
  }
}
.popover-downloadpdf {
  width: 300px;
  height: auto;
  background-color: #fff;
  font-family: 'Montserrat';
  display: flex;
  align-items: flex-start;
  // justify-content: space-between;
  flex-direction: column;
  .title-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .checkboxes-button {
    padding: 10px;
  }
  .paid__contract__status_btn {
    background-color: $primary;
    height: 28px;
  }
  .title {
    padding: 10px;
    font-size: 20px;
  }
  .checkboxes-wrapper {
    height: 80%;
  }
  .checkboxes {
    display: flex;
    width: 100%;
    align-items: center;
  }
}
.img__see__more {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #f5f5f5;
  height: 35px;
  cursor: pointer;
  &:hover {
    color: #0e88f2;
  }
}

.createUser {
  & .MuiInputBase-root {
    & div {
      & button {
        padding: 8px !important;
        margin-right: 4px;
      }
    }
  }

  .input__type__file__bordered__name {
    height: unset;
    min-height: 37px;
    word-break: break-all;
    .MuiAccordion-root {
      width: 100%;
      height: auto;
      padding: 10px;
    }
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 20px);
  }
}

.hamburger-menu {
  display: none;
  transform: scale(0.6);
  z-index: 20;

  @media screen and (max-width: 500px) {
    display: block;
    position: absolute;
    top: 5px;
    left: 10px;
    transition: 0.5s ease-in-out;
  }
}

.hamburger-menu-margin {
  @media screen and (max-width: 500px) {
    left: 115px;
  }
}

.order_loading_csv_export {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
}
// .MTableToolbar-root-10.MuiToolbar-gutters{
//   background-color: #f5f5f5 !important;
// }

.paid__contracts__wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.paid__contract__wrapper__buttons {
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  gap: 6px;
  .createuserExport {
    background: $primary;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .wrapper__buttons-download {
    justify-content: space-around;
  }
  .paid__contract__status_btn {
    font-size: 14px;
    font-weight: 600;
    height: 36px;
    grid-gap: 4px;
    gap: 4px;
    // background: #1C4CCF;
    background: linear-gradient(
      119deg,
      rgb(152, 21, 144) 0%,
      rgb(28, 76, 192) 100%
    );
    border-radius: 2px !important;
    cursor: pointer;
    color: #fff;
    font-family: 'Montserrat';
    width: 120px;
  }
}

.paid__contract__status_btn {
  height: 42px;
  font-family: 'Montserrat';
  width: 110px;
  background: #c0c0c0;
  color: white;
  font-weight: 400;
  border-radius: 4px !important;
  cursor: pointer;
}
// .paid__contract__status_btn:hover {
//   background-color: #f5f5f5;
//   color: #26861e;
//   font-weight: 600;
//   font-size: 14px;
//   moz-transition: all 0.2s ease-in-out;
//   -o-transition: all 0.2s ease-in-out;
//   -webkit-transition: all 0.2s ease-in-out;
//   transition: all 0.2s ease-in-out;
// }
.paid-orders-filters-wrapper {
  display: flex;
  align-items: center;
  padding: 0 20px;
  max-width: 100%;
}
@media only screen and (max-width: 800px) {
  .paid-orders-filters-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.tenant_settings__container {
  padding: 20px;
  &-pageList {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  &-content {
    .user__profile__container {
      padding-left: 0;
      min-height: calc(100vh - 150px);
      .user__profile__right {
        padding-left: 20px;
      }
      .edit__and__create__form {
        div {
          .user__public__profile__right {
            margin-left: 20px;
          }
        }
      }
      .permissions__container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 20px;
        position: relative;
        .table__wrapper {
          height: calc(100vh - 200px);
          width: 100%;
        }
        & .rdg-light {
          width: 100%;
        }
        .saveButton {
          top: -100px;
          right: 10px;
        }
      }
    }
  }
}
.permissionHeaderCell,
.permission-checkbox-cell {
  display: flex;
  justify-content: center;
}
.permission-custom-cell {
  color: #252525;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
}
.calculation__import-container {
  max-height: calc(100vh - 150px);
  height: calc(100vh - 150px);
  width: 100%;
  &__tabs {
    display: flex;
    align-items: center;
    max-width: calc(100vw - 200px);
    width: calc(100vw - 200px);
    overflow-x: auto;
    gap: 10px;
    margin: 10px;
    padding-bottom: 20px;
    & .tab {
      padding: 3px 7px;
      background-color: #fff;
      color: $primary;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      min-width: 100px;
      white-space: nowrap;
      flex: 0 0 auto;
      &.active {
        background: linear-gradient(95deg, $primary 0%, #400077 100%);
        color: #fff;
        padding: 5px 8px;
      }
    }
  }
  &-table {
    height: calc(100vh - 200px);
    & .rdg-light {
      height: 100% !important;
    }
  }
}
.calculation__fileName {
  color: $primary;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
}
.condition-team__tooltip {
  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    color: #1c4ccf;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
  }
}
.edit__and__create__form {
  .form_dropdown-teams {
    .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
      height: 50px;
    }
  }
}
.publish__popover {
  .ant-dropdown-menu {
    border-radius: 8px;
    box-shadow: 2px 4px 4px 0px #00000026;
    &-item {
      padding: 0;
      cursor: auto;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.publish__popover__component {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  &-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-select {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      padding: 5px;
      & .icon {
        height: 100%;
        display: flex;
        align-items: center;
      }
      & .title {
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 500;
      }
      &.active {
        background-color: $secondaryhover;
        border-radius: 5px;
      }
    }
    .date__picker {
      width: 100%;
      .ant-picker {
        font-family: 'Montserrat';
        padding: 5px 20px;
        border-radius: 5px;
        width: 100%;
        &-input {
          margin: 0;
          padding: 0;
        }
      }
      &-bottom {
        display: flex;
        width: 100%;
        position: relative;
        margin: 3px 0;
        margin-bottom: 10px;
        .clear__btn {
          position: absolute;
          left: auto;
          right: 0;
          font-family: 'Montserrat';
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
          color: $primary;
        }
      }
    }
    &-actions {
      margin-top: 10px;
      .confirm__button {
        width: 100%;
        border: 1px solid $primary;
        background: $primary;
        color: white;
        font-family: 'Montserrat';
        font-size: 14px;
        border-radius: 8px;
        font-weight: 600;
        padding: 10px 0;
      }
    }
  }
}
.export__calc__container {
  padding: 10px;
  width: 230px;
  &-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-picker {
      width: 100%;
      .ant-picker {
        font-family: 'Montserrat';
        padding: 5px 20px;
        border-radius: 5px;
        width: 100%;
        &-input {
          margin: 0;
          padding: 0;
        }
      }
    }
    &-actions {
      margin-top: 10px;
      .confirm__button {
        width: 100%;
        border: 1px solid $primary;
        background: $primary;
        color: white;
        font-family: 'Montserrat';
        font-size: 14px;
        border-radius: 8px;
        font-weight: 600;
        padding: 10px 0;
      }
    }
  }
}
.borderLess__actions__cell {
  border-right: none;
}

.dashboard__container__tenant__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    cursor: pointer;
  }
}

.lockedPage__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #afafaf;
  gap: 30px;

  &_iconTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    font-size: 20px;
    gap: 20px;
  }
  &_text {
    width: 303px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
}

.popup_reactivated_user {
  top: 60px !important;
  width: 417px;
  min-height: 100%;
  padding: 20px;
  border: solid 1px #ececec;
  background-color: #fff;
  font-family: 'Montserrat';
  &.paddingLess {
    padding: 0px;
    box-shadow: 2px 4px 4px 0px #00000040;
    border-radius: 8px;
    .ant-popover-inner {
      border-radius: 8px;
      &-content {
        width: 100%;
        padding: 20px;
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    .createuserButton:first-of-type {
      background-color: #fff;
      border: 1px solid #979797 !important;
      color: #979797 !important;
    }
    & > * {
      padding: 24px 0px;
      width: 50% !important;
      border: 1px solid $primary !important;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.5px;
      text-align: left;
    }
  }
  .textarea_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    padding: 15px 0;
    &-bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .textarea__input {
      display: flex;
      flex-direction: column;
      width: 60%;
      font-family: 'Montserrat';
      textarea {
        width: 100%;
        background-color: #fff;
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        margin-top: 5px;
        resize: none;
        height: 200px;
        padding: 5px;
        color: rgba(37, 37, 37, 0.5);
      }
      .textarea_max_length {
        color: #ddd;
        font-size: 14px;
        align-self: flex-end;
        margin: 5px 0;
      }
    }
  }

  .close__modal__icon__and__name {
    font-family: 'Montserrat';
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    span {
      svg {
        height: 16px;
        width: 16px;
        & > * {
          fill: $primary;
        }
      }
    }

    .close__icon__div {
      width: 26px;
      height: 26px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .close__modal__icon {
      cursor: pointer;
      align-self: center;
    }
  }

  .datepicker__input__and__error {
    border: none !important;
    min-height: 51.69px;
    div {
      height: 51.69px;
      margin: 9px 0;
      input {
        height: 51.69px !important;
      }
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
      top: 3px;
      left: 8px;
      background-color: white;
      padding: 0 5px !important;
    }

    label {
      font-size: 13px;
    }

    label + .MuiInput-formControl {
      margin-top: 10px;
    }
  }

  .edit__and__create__form {
    & .modal__cancel__confirm__buttons {
      display: flex;
      justify-content: space-between;
      & .primaryButton {
        width: 48%;
        padding: 0;
        border-radius: 8px;
        &.cancel {
          width: 100%;
          background-color: white;
          border: 1px solid #cc0000;
          color: #cc0000;
          &:hover {
            background-color: $secondaryhover;
          }
        }
      }
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
      top: -11px;
      left: -16px;
      font-size: 16px;
      position: absolute;
      font-family: 'Montserrat';
      color: #252525;
      font-weight: 600;
    }
    .MuiOutlinedInput-notchedOutline {
      legend {
        max-width: 0px !important;
      }
    }
    .MuiAutocomplete-endAdornment {
      right: 20px !important;
      top: 10px;
    }
    .AutocompleteCl {
      .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
        height: 20px;
      }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #d9d9d9 !important;
      border-width: 1px;
      border-radius: 5px;
    }
    .MuiOutlinedInput-root:hover {
      border: none !important;
    }

    & .forget__password__modal__text {
      margin-top: 15px;
      margin-bottom: 10px;
      margin-left: 5px;
      color: #7d8c96;
      font-size: 13px;
    }

    //Remove input type number up and down icons
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // -||- Firefox
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .inline__errors {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      margin-bottom: 15px;
    }

    .value__and__currency {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .input__value {
      width: 300px;
    }

    .dropown_currency {
      .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
        font-size: 13px;
      }

      & .dropdown-option {
        &
          .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
          font-size: 13px;
        }

        height: 40px;
        min-width: 130px;
        font-size: 10px;
      }
      margin-bottom: -5px;
    }

    .dropdown-commission {
      margin-bottom: 0 !important;
      padding: 5px 0;
    }

    & h5 {
      margin-bottom: 5px;
      margin-left: 4px;
    }
    & h1 {
      // width: 112px;
      height: 29px;
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: $primary;
      margin: 0;
    }

    & div {
      margin: 4px 0;
      &.form_input {
        & input {
          height: 51.69px;
          margin: 3px 0 1px 1px;
          padding: 15px;
          border: solid 1px #d9d9d9;
          background-color: #fff;
        }
        & span {
          margin-bottom: 3px;
        }
      }
    }
    & div > label {
      margin-left: 0px;
      & span {
        font-family: 'Montserrat';
      }
    }
    &-custom_input {
      padding: 0;
      .form_input__label {
        color: #252525 !important;
        margin-bottom: -4px !important;
      }
      input {
        border: 1px solid #d9d9d9 !important;
        border-radius: 8px !important;
        padding: 0;
        &::placeholder {
          color: #c1c1c1;
          font-size: 14px;
          font-weight: 500;
        }
        &:disabled {
          background-color: #f5f5f5 !important;
        }
      }
    }
  }
  & .primaryButton {
    width: 100%;
    height: 46px;
    // padding: 14px 155px 14px 156px;
    // background-color: #1e8659;
    background: $primary;
    border-radius: 8px;
    color: white;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .orderInput {
    margin-bottom: 10px;
  }
}
.tooltip__wrapper {
  padding: 5px;
  &-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    span {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      color: #252525;
    }
  }
  &-body {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    color: #252525;
    white-space: pre-line;
  }
}
