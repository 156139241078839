@import '../../common/styles/abstracts/mixins';

.info__small__box{
    box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    height: 74px;

    .info__small__box__icon{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 7px 9px 0px 9px;
    }
    .info__small__box__right__part{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 8px;
    }
    .info__small__box__number{
        font-family: "Montserrat";
        font-size: 20px;
        font-weight: bold;
        line-height: 1.25;
        color: #26353e;
        font-weight: 600;
        padding-bottom: 8px;

    }
    .info__small__box__name__of__info {
        font-family: "Montserrat";
        font-size: 12px;
        line-height: 2.14;
        color: #3b505d;
        font-weight: 600;
        max-width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
    }
    
 
}
@media only screen and (max-width: 1190px) {
       
    .info__small__box{
        flex-direction: column;
        min-width: 64px;
        margin-top: 5px;

    }
    
}
@media only screen and (min-width: 1400px) {
       
    .info__small__box{
    //   margin-bottom: 20px;
 
    }
    
}

@media only screen and (max-width: 1400px) {



    
       
    .info__small__box__icon img{
        // width: 85% !important;
    }
    .info__small__box__number{
        font-size: 18px !important;
    }
    .info__small__box__name__of__info{
        font-size: 10px !important;
    }
    .info__small__box{
        max-height: 100px;
        // height: 120px;
    }
}

@media only screen and (max-width: 1520px)  and (min-width: 1300px) {
    .info__small__box{
        height: 100%;
        // padding:4% 0;
     
    }
    .info__small__box__icon img{
       margin-right: 5px;
    //    width: 85% !important;
    }
    .info__small__box__number{
        font-size: 20px !important;

    }
    .info__small__box__name__of__info{
        font-size: 12px !important;

    }
}