.order__form__modal {
  background-color: #f5f5f5;
  .ant-modal-header {
    background-color: transparent;
  }
  .ant-modal-content {
    background-color: transparent;
    .ant-modal-body {
      .form__loader {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-header {
    position: absolute;
    top: 0;
    right: 50px;
    left: auto;
    .container {
      height: 54px !important;
      display: flex;
      align-items: center;
      .status-container {
        height: 70% !important;
      }
    }
  }
}
.order__form {
  margin-bottom: 70px;
  .section__box-wrapper {
    position: relative;
    height: unset;
    border-radius: 8px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #d9d9d9;
    &-header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-family: 'Montserrat';
        font-weight: 600;
      }
      .collapse-icon {
        display: flex;
        align-items: center;
      }
    }
  }
  &-actions {
    position: absolute;
    inset: 0;
    top: auto;
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    .order-edit-button {
      background-color: $primary;
      width: 100% !important;
      border-radius: 8px;
      color: #fff;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
  .order__activity {
    &--container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
.inputs__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .single__input {
    .email-input__wrapper {
      &-input {
        .inputWrapper {
          width: 100%;
        }
      }
      .errorClass {
        display: block;
        margin-top: 5px;
        margin-left: 5px;
        color: #d00a12;
        font-family: 'Montserrat';
        font-size: 10px;
        font-style: italic;
        font-weight: 500;
      }
    }
    .iban-input__wrapper {
      &-input {
        .inputWrapper {
          flex: 1;
        }
      }
      .iban-validator-wrapper {
        width: 20%;
        .primaryButton {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    .signature__wrapper {
      span {
        font-family: 'Montserrat';
        font-size: 12px;
        margin-left: 1px;
        color: #252525;
        font-weight: 600;
        display: block;
      }
      canvas , img {
        background-color: #fff;
        margin: 5px 0;
          &.disabled {
            background-color: transparent;
            border: 1px solid #dadada;
            border-radius: 5px;
        }
      }
      .errorClass {
        display: block;
        margin-top: 5px;
        margin-left: 5px;
        color: #d00a12;
        font-family: 'Montserrat';
        font-size: 10px;
        font-style: italic;
        font-weight: 500;
      }
    }
    .label {
      font-family: 'Montserrat';
      font-size: 12px;
      margin-left: 1px;
      color: #252525;
      font-weight: 600;
    }
    .picker__wrapper {
      width: 100%;
      margin: 5px 0;
    }
    .errorText {
      display: block;
      margin-top: 5px;
      margin-left: 5px;
      color: #d00a12;
      font-family: 'Montserrat';
      font-size: 10px;
      font-style: italic;
      font-weight: 500;
    }
    .textarea_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: auto;
      padding: 15px 0;
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .textarea__input {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat';
        textarea {
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          outline: none;
          margin-top: 5px;
          resize: none;
          height: 100px;
          padding: 5px;
          color: #252525;
        }
        .textarea_max_length {
          color: #ddd;
          font-size: 14px;
          align-self: flex-end;
          margin: 5px 0;
        }
      }
    }
    .chat_info_container-media:first-child {
      margin-top: 0;
    }
    .chat_info_container-media-wrapper {
      .title {
        width: unset;
      }
    }
    input:disabled {
      background-color: transparent !important;
      cursor: not-allowed;
    }
  }
}

.tabs__view-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e5e5e5;
  border-radius: 10px;
  padding: 3px;
  width: 450px;
  margin: 0 auto;
  .single__tab {
    background-color: transparent;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    cursor: pointer;
    &.active {
      background-color: #fff;
    }
  }
}

.order__form__view {
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
}
.device {
  position: relative;
  margin: 0 auto;
  border-radius: 40px;
  background: #f5f5f5;
  border: 12px solid #00000095;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  .content {
    position: absolute;
    top: 40px;
    bottom: 20px;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 60px);
    background: #f5f5f5;
    padding: 20px 0px;
    overflow-y: auto;
    .order__form {
      padding: 0 20px;
      margin-bottom: 0px;
      &-actions {
        background-color: transparent;
        position: static;
        padding: 20px 0 0;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 5px;
    top: auto;
    left: 50%;
    height: 5px;
    transform: translate(-50%, -50%);
    width: 100px;
    background-color: #000;
    border-radius: 8px;
  }
}
.smartphone {
  width: 350px;
  height: 632px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: auto;
    left: 50%;
    margin-top: 20px;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 20px;
    background: #000;
    border-radius: 20px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .content {
    .order__form {
      .section__box-wrapper {
        .single__input {
          .signature__wrapper {
            canvas {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.tablet {
  width: 570px;
  height: 700px;
  border-width: 14px;
  margin-top: 40px;
  .content {
    top: 10px;
    bottom: 20px;
    height: calc(100% - 30px);
    padding-top: 0;
  }
}
