.skeleton-wrapper{
    width: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .ant-skeleton-element{
      width: auto;
    }
    &-body{
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
  }