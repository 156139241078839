@media only screen and (max-width:520px) {
    .settlements__table__range__settings,.settlements__tabble__date__settings{
        display: flex;
        flex-direction: column !important;
 
    }

}


.settlements__table__container{

    margin-top:50px;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #ececec;
   

    .settlements__table__and__rows__container{
        width: 100%;
        overflow-y: auto;

    }

    .settlements__tabble__date__settings{
        padding: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-bottom:solid 2px #f5f5f5; 
    
       .settlements__table__month__picker{
            font-family: "Montserrat";
            font-size: 18px;
            font-weight: 500;
            line-height: 1.67;
            letter-spacing: normal;
            color: #666;
        }
    }
    
    .settlements__table__range__settings{
        display: flex;
        justify-content: space-around;
       .settlements__table__date__radio{
           display: flex;
           justify-content: center;
           align-items: center;
            width: 62px;
            height: 35px;
            border-radius: 4px;
            border: solid 1px #f5f5f5;
            background-color: #fff;
            font-family: "Montserrat";
            font-size: 10px;
            font-weight: 900;
            line-height: 4;
            letter-spacing: 1.25px;
            color: #99a4ac;
            cursor: pointer;
            &.active{
                background-color: #fafafa;
                color: #3b505d;
                font-weight: 900;
            }
        }
      
    }
}
.settlements__table__pagination__info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    //Removed for now
    // & p{
    //     text-align: right;
    //     padding-bottom: 5px;
    //     padding-right: 8px;
    //     font-family: "Montserrat";
    //     font-size: 13px !important;
    //     font-weight: 900 !important; 
    //     color: #3b505d !important;
    //     font-weight: bold;
    // }
}