@import '../../abstracts/variables';

.energy__layout {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  font-family: "Montserrat";
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 120px);
    width: calc(100vw - 200px);
  }
}
.filters__modal {
  height: 100vh;
  padding: 20px;
  font-family: "Montserrat";
  &-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    &-close {
      cursor: pointer;
    }
    .filters__tabs {
      display: flex;
      background-color: #f2f2f2;
      border-radius: 50px;
      & .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        color: #5c5c5c;
        font-family: 'Montserrat';
        font-size: 14px;
        padding: 15px 0;
        font-weight: 600;
        cursor: pointer;
        &.active {
          border-radius: 50px;
          background: $primary;
          color: #fff;
        }
      }
    }
  }
  &-form {
    padding: 40px 0 20px 0;
    overflow: auto;
    max-height: calc(100vh - 120px);
    &-inputs {
      padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      &-singleInput {
        flex: 1;
        .form_input {
          padding: 0;
      
          input {
            height: 53px;
            margin: 0;
            border: 2px solid #e8e8e8;
            &:disabled {
              background-color: #dadada !important;
            }
          }
        }
        .MuiAutocomplete-root {
          margin-bottom: 0;
          .Mui-disabled {
            background-color: #dadada;
            border-radius: 8px;
          }
          .MuiFormControl-root {
            .MuiInputBase-root {
              fieldset {
                border-radius: 8px;
                border: 2px solid #e8e8e8 !important;
              }
            }
          }
        }
        .custom__title {
          color: #252525;
          font-family: 'Montserrat';
          font-size: 16px;
          font-weight: 600;
        }
        .options-container {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          .single__option {
            height: 55px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #e8e8e8;
            cursor: pointer;
            &.active {
              background-color: rgba(108, 30, 176, 0.15);
            }
            &__wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              &-item {
                .countValue {
                  font-family: 'Montserrat';
                  font-weight: 500;
                  color: #252525;
                  font-size: 12px;
                  &.active {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .custom__datepicker {
          .MuiFormControl-root {
            height: 53px;
            border: 2px solid #e8e8e8;
            width: 100%;
            border-radius: 8px;
            .MuiFormLabel-root {
              padding-left: 20px;
              color: #252525;
              font-family: 'Montserrat';
              font-weight: 600;
              font-size: 14px;
            }
            .MuiInputBase-root {
              padding: 0 20px;
            }
            .MuiInput-underline:before,
            .MuiInput-underline:after {
              border-bottom: none;
            }
          }
        }
        .custom__checkbox {
          padding: 0 5px;
          .MuiFormControlLabel-root {
            .MuiTypography-root {
              color: #252525;
              font-family: 'Montserrat';
              font-size: 13px;
              font-weight: 600;
              padding-left: 5px;
            }
          }
        }
      }
    }
    .primaryButton {
      position: absolute;
      bottom: 20px;
      right: 20px;
      left: 20px;
      border-radius: 8px;
      background: $primary;
      color: #fff;
      padding: 14px 0;
    }
    .MuiAutocomplete-endAdornment {
      padding-right: 10px;
    }
  }
}
.orderForm__modal {
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  font-family: "Montserrat";
  .custom__autocomplete{
    margin-bottom: 0px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-close {
      cursor: pointer;
      align-self: flex-end;
    }
    &-tariff {
      border-radius: 8px;
      border: 1px solid #e8e8e8;
      padding: 10px;
      display: flex;
      align-items: center;
      &-name {
        font-family: 'Montserrat';
        font-size: 11px;
        font-weight: 600;
        color: #252525;
        padding: 0 35px 0 15px;
        p {
          color: #979797;
        }
      }
      &-prices {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        .priceYear {
          color: #252525;
          font-family: 'Montserrat';
          font-size: 11px;
          & p:last-of-type {
            font-weight: 600;
          }
        }
        .laborPrice,
        .basicPrice {
          color: #979797;
          font-family: 'Montserrat';
          font-size: 11px;
          margin-left: 10px;
          padding: 0 10px;
          border-left: 1px solid #e8e8e8;
          & p:last-of-type {
            font-weight: 600;
          }
        }
      }
    }
    &-newTariff {
      border-radius: 8px;
      padding: 10px 20px;
      color: $primary;
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin-bottom: 20px;
      border-radius: 8px;
      border: 1px solid $primary;
      display: flex;
      align-items: center;
      gap: 10px;
      & span {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__inputs {
      .primaryButton {
        width: 100%;
        font-weight: 500;
      }
      .label{
      font-size: 12px;
      font-weight: 600;
      color: #252525;
      }
      &-singleInput {
        padding-bottom: 20px;
        &:last-of-type {
          padding-bottom: 0px;
        }
        position: relative;
        .custom__datepicker {
          .MuiFormControl-root {
            height: 53px;
            border: 2px solid #e8e8e8;
            width: 100%;
            border-radius: 8px;
            .MuiFormLabel-root {
              padding-left: 20px;
              color: #252525;
              font-family: 'Montserrat';
              font-weight: 600;
              font-size: 14px;
            }
            .MuiInputBase-root {
              padding: 0 20px;
            }
            .MuiInput-underline:before,
            .MuiInput-underline:after {
              border-bottom: none;
            }
          }
        }
        .custom__checkbox {
          padding: 0 5px;
          .MuiFormControlLabel-root {
            display: flex;
            align-items: flex-start;
            .MuiTypography-root {
              color: #252525;
              font-family: 'Montserrat';
              font-size: 13px;
              padding-left: 5px;
            }
          }
        }
        .iban-validator-wrapper{
          margin-top: 8px;
          .primaryButton {
            width: 100px;
            background: $primary;
            color: #fff;
          }
        }
        .form_input {
          padding: 0;
          
          input {
            margin: 0;
            border: 2px solid #e8e8e8;
            height: 53px;
          }
          & .disabled__input {
            border: 1px solid #979797 !important;
            background: #dadada !important;
          }
        }
        .input__info__toggler {
          position: absolute;
          right: 0;
          left: auto;
          top: 0px;
          width: 55px;
          height: 53px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #dadada;
          cursor: pointer;
        }
        .MuiAutocomplete-root {
          margin-bottom: 0;
          .MuiFormControl-root {
            .MuiInputBase-root {
              fieldset {
                border-radius: 8px;
                border: 2px solid #e8e8e8 !important;
              }
            }
          }
        }
        & .disabled__input {
          .MuiFormControl-root {
            .MuiInputBase-root {
              background: #dadada;
              border-radius: 8px;
              fieldset {
                border: 1px solid #979797 !important;
              }
            }
          }
        }
      }
      & .custom__title {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 20px;
      }
    }
    & .primaryButton {
      border-radius: 8px;
      border: 1px solid $primary;
      padding: 15px 0;
      color: $primary;
      background-color: #fff;
    }
  }
}

.card__info__container {
  margin: 5px 0;
  border-left: 5px solid;
  border-radius: 8px;
  padding: 10px 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  h3 {
    font-size: 16px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
    white-space: pre-line;
  }
}
