@import "../abstracts/variables";

.dashboardOne{
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
    &__bottom{
        width: 80%;
        height: 40px;
        display: flex;
        margin-bottom: 10px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        background: $primary;
        border-radius: 10px;
        padding: 0px 15px 0px 15px;
        .edit__and__create__user__single__tabs{
          width: 108px;
          border: none;
          p{
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px;
          padding: 0px;
          }
        }
        .edit__and__create__user__single__tabs.active{
          width: 108px;
          border-radius: 5px;
          height: 65%;
          background-color: #fff;
          p{
            padding: 0px;
            background-color: transparent;
            color: $primary;
            font-size: 14px;
            font-weight: 600;
            font-family: "Montserrat";
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            display: flex;
            margin-bottom: 0px;
          }
        }
    
      }
    &__second{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        .noDataMsg{
          width: 100%;
          height: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #939393
        }
       &__selectedDate{
        width: 200px;
        min-width: 210px;
        margin-bottom: 10px;
        background-color: $primary;
        border-radius: 8px;
        background-color: #f0f0f0;
        color: #c7c7c7;
        height: 40px;
        padding-top: 10px;
        text-align: center;
       }
    }
    &__contanier{
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      span{
        font-weight: 600;
      }
       &__content{
        display: flex;
        gap: 10px;
        overflow: auto;
        height: 132%;
        display: flex;
        width: 55%;
        padding: 4px;
        justify-content: flex-start;
        align-self: baseline;
        align-items: self-end;
        &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        cursor: pointer;
        }
        &::-webkit-scrollbar-track {
         background: #E9EEFA;
         background: $secondaryhover;

         }
         &::-webkit-scrollbar-thumb {
          background: $primary;
           border-radius: 15px;
         }
        &__item{
          border-radius: 5px;
          padding: 0px 5px 0px 5px;
          color: white;
          font-weight: 700;
          font-family: "Montserrat";
        }
       }
    }
    &__userStats{
        max-height: 900px;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
         &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        cursor: pointer;
        }
        &::-webkit-scrollbar-track {
         background: #E9EEFA;
         background: $secondaryhover;

         }
         &::-webkit-scrollbar-thumb {
          background: $primary;
           border-radius: 15px;
         }

        &__content{
          margin-top: 20px;
            border-right: 1px solid #d9d9d9;
            min-width: 500px;
            &__box{
                display: flex;
                align-items: center;
                gap: 20px;
                &__chart{
                  width: 45%;
                  max-width: 45%;
                }
            }
        }
    }
}