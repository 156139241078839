@mixin respond($breakpoint) {
    @if $breakpoint == phone {
      // < 600px
      @media (max-width: 37.5em) {
        @content;
      }
    }
  
    @if $breakpoint == tab-port {
      @media (max-width: 56.25em) {
        // 900px
        @content;
      }
    }
    @if $breakpoint == tab-land {
      @media (max-width: 75em) {
        // 1200px
        @content;
      }
    }
    @if $breakpoint == normal-desktop {
      @media (max-width: 112.5em) and (min-width: 87.5em) {
        // 1400px - 1800px
        @content;
      }
    }
    @if $breakpoint == big-desktop {
      @media (min-width: 112.5em) {
        // 1800px
        @content;
      }
    }
  }