.custom__modal {
    height: 100vh;
    width: 100vw;
    position: absolute;
    inset: 0 0 0 auto;
    overflow: hidden;
  
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100vh;
  
      .ant-modal-header {
        flex-shrink: 0;
        border-bottom: none;
  
        .ant-modal-title {
          font-family: 'Montserrat';
          font-size: 18px;
          font-weight: 600;
          color: $primary;
        }
      }
  
      .ant-modal-body {
        flex: 1;
        overflow: auto;
        padding-top: 0;
      }
    }
  }
  