@import '../abstracts/variables';
.ant-skeleton-element{
    width: 100%;
}
.statistics__container {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  flex-direction: column;

  canvas {
    min-height: 300px;
    max-height: 450px;
  }

  .statistics__information-bottom {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    background: $primary;
    border-radius: 10px;
    padding: 3px 15px;
    .edit__and__create__user__single__tabs {
      width: 108px;
      border: none;
      p {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
        padding: 0px;
      }
    }
    .edit__and__create__user__single__tabs.active {
      width: 108px;
      border-radius: 5px;
      height: 80%;
      background-color: #fff;
      p {
        // color: #0E88F2;
        padding: 0px;
        background-color: transparent;
        color: $primary;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat';
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        display: flex;
        margin-bottom: 0px;
      }
    }
  }

  .statistics-link {
    position: relative;
    text-decoration: none;
    // color: #252525;
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    min-width: 20%;
  }

  .statistics__information__upLegend {
    overflow: auto;
    height: 132%;
    display: flex;
    justify-content: flex-start;
    align-self: baseline;
    align-items: self-end;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background: #e9eefa;
      background-color: $secondaryhover;
    }
    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 15px;
    }
  }

  .statistics__information {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 20px 0;
    font-weight: 400;
    gap: 20px;

    &__selectedDate {
      display: flex;
      background-color: #f0f0f0;
      align-items: center;
      justify-content: flex-end;
      color: #c7c7c7;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      font-family: 'Montserrat';
      padding-left: 10px;
      padding-right: 10px;
      min-width: 210px;
    }
    &-second {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .noDataMsg {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #939393;
      }
      .statistics__information__text {
        width: auto;

        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #26353e;
      }
    }

    .statistics__information__text {
      // width: 15%;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #26353e;
      // min-width: 141px;
    }
    .statistics__information__contentCategory {
      display: flex;
      align-items: center;
      // padding-left: 50px;
      gap: 20px;
      width: 73%;
    }
    .statistics__information__category {
      font-family: 'Montserrat';
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      color: $primary;
      border-radius: 5px;
      padding: 1px 30px 1px 30px;
      border: 1px solid $primary;
      &.active {
        background-color: $primary;
        color: #fff;
      }
    }

    .statistics__information__legend {
      min-width: 50px;
      max-width: fit-content;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      white-space: nowrap;
      flex: 0 0 auto;
      &.clickable {
        cursor: pointer;
        .statistics__information__legend-color {
          border: 1px solid;
        }
      }

      &-text {
        width: auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #212121;
      }
      &-color {
        // width: 18px;
        // height: 18px;
        border-radius: 5px;
        padding: 0px 5px 0px 5px;
        color: white;
        font-weight: 600;
        font-family: 'Montserrat';
      }
    }
  }
  @media screen and (max-width: 1080px) {
    .statistics__information-bottom {
      height: 30px;
      width: 100%;
    }
    .edit__and__create__user__single__tabs {
      height: 26px;
    }
    .statistics__information-bottom .edit__and__create__user__single__tabs p {
      font-size: 13px;
    }

    .statistics__information-bottom
      .edit__and__create__user__single__tabs.active
      p {
      font-size: 13px;
    }
    .edit__and__create__user__single__tabs {
      max-width: 60px;
      width: 60px;
    }
    .edit__and__create__user__single__tabs.active {
      max-width: 60px;
      width: 60px;
    }
  }
}
.project_categories_wrapper {
  height: 50px;
  min-height: 50px;
  .statistics-nav {
    display: flex;
    align-items: center;
    padding: 10px 0;
    text-align: center;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background: #e9eefa;
      background-color: $secondaryhover;
    }
    &::-webkit-scrollbar-thumb {
      background: $primary;
      border-radius: 15px;
    }
  }
}
