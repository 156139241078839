.draggedform{
    width: 200px;
	height: 50px;
	display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #87898E;
    margin-top: 10px;
    .draggedform-grabLogo:focus{
        outline: none;
    }
    &:hover{
        background: linear-gradient(119.24deg, #981596 0%, #1C4CCF 100%);;
        box-shadow: 0px 4px 6px rgba(128, 128, 128, 0.25);
        color: white;
    .draggedform-grabLogo{
            cursor: grab;
            path{
             stroke: white;
            }
         }
    }
    &-input{
        	display: flex;
			align-items: center;
			width: 100%;
			justify-content: flex-start;
			gap: 10px;
			margin-left: 8px;
          
    }
}
.draggedformInside{
    width: 100%;
	display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #C1C1C1;
    color: #979797;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    .draggedformInside-grabLogo:focus{
        outline: none;
    }
    &-removeLogo{
        width: 13px;
        cursor: pointer;
        position: relative;
        right: -5px;
    }
    &-grabLogo{
        width: 8px;
        cursor: grab;
    }
    &-editLogo{
        width: 15px;
        cursor: pointer;
    }
        &-input{
            display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-evenly;
            span{
            // cursor: initial;        
            background: #f5f5f5;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.06);
            border-radius: 8px;
            width: 75%;
            height: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px;
            }
        }   
}