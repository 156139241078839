.changeStatus-select {
  width: 200px;
  .ant-select-selector {
    background-color: $primary !important;
    color: #fff !important;
    border-radius: 8px !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-select-selector:focus,
  .ant-select-selector:hover {
    box-shadow: none !important;
    border-color: transparent !important;
  }
  .ant-select-arrow {
    color: #fff !important;
  }
  .ant-select-selection-item{
     color: #fff !important;
  }
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    & .primaryButton {
      width: 90%;
      border: 1px solid $primary;
      background-color: #fff;
      color: $primary;
      font-family: 'Montserrat';
      height: 35px;
      border-radius: 5px;
      font-weight: 600;
      &:hover {
        color: #fff;
        background-color: $primary;
      }
    }
  }
}
