.switch__wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &-title {
    flex: 1;
    &-label {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      text-align: left;
      color: #252525;
    }
    .info__icon {
      margin-left: 5px;
      cursor: pointer;
      position: relative; 
      top: -3px;
    }
  }
  .ant-switch {
    &-checked {
      .ant-switch-handle::before {
        background-color: $primary;
      }
    }
  }
}
