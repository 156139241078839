@import '../../../abstracts/variables';

.user__details__big__container {
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;

  .user__details__left__side {
    width: 30%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 550px;

    .user__details__picture__name__role {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 30%;
      & img {
        border-radius: 100%;
        width: 70px;
        height: 70px;
        margin-bottom: 9px;
      }
      .user__details__name {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 500;
        color: #3b505d;
        margin-bottom: 5px;
      }
      .user__details__role {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 500;
        color: #99a4ac;
      }
    }
    .user__details__other__details {
      display: flex;
      flex-direction: column;
      height: 55%;
      width: 80%;
      justify-content: space-evenly;
      margin-left: 20px;

      .user__details__info__title {
        font-family: 'Montserrat';
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #3b505d;
      }
      .user__details__infos {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 500;
        color: #3b505d;
        word-break: break-word;
      }
    }
  }

  .user__details__right__side {
    margin-right: 20px;
    width: 65%;
    padding: 50px;
    background-color: white;
    position: relative;

    .user__details__right__side__rounds {
      flex-wrap: wrap;
      padding-left: 30px;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
    .user__details__statistics__text {
      font-family: 'Montserrat';
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1.22px;
      color: #3b505d;
    }
    .user__details__right__side__table {
      margin-top: 30px;
      background-color: #f8fafb;
      padding: 30px;

      .user__details__right__side__table__shadow {
        border-radius: 6px;
      }
    }
  }
}
.settle__button {
  position: absolute;
  width: 120px;
  height: 30px;
  top: 25px;
  right: 25px;
  background-color: #1e8659;
  color: white;
}
.dashboard__left__text__close__button {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 900px) {
  .user__details__big__container {
    flex-direction: column;
  }
  .user__details__left__side {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .user__details__right__side {
    width: 100% !important;
  }
}

.dashboard__left__text-ordersheader {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.dashboard__left__text-ordersactions {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  max-height: 30px;
  padding-right: 30px;
  gap: 6px;
  flex-wrap: wrap;
  @media screen and (max-width: 762px) {
    justify-content: flex-start;
    margin: 0 0 45px 20px;
  }
}
.change-status-energy{
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-header{
    display: flex;
    justify-content: space-between;
    color: $primary;
    font-weight: 600;
    font-size: 18px;
  }
  &-buttons{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    .primaryButton {
      &.cancel{
        background-color: #fff;
        border: 1px solid #979797;
        color: #979797;
      }
    }
  }
}
.dashboard__left__text-header {
  display: flex;
  padding-left: 20px;
  align-items: center;
  height: 100%;
}
.tab-popover{
  .ant-dropdown-menu {
    min-width: 100px;
    font-family: "Montserrat";
    border-radius: 4px;
    &-item {
      font-size: 12px;
      font-weight: 500;
      color: $primary;
      text-align: center;
      .border-wrapper{
          position: relative;
          display: inline-block;
          .border{
            display: block;
            height: 4px;
            width: 100%;
            margin-top: 4px;
            border-radius: 10px 10px 0 0;
          }
      }
      &.active {
        span {
          color: $primary;
          font-size: 12px;
        }
      }
    }
  }
}
.dashboard__left__text-header-clientList {
  align-items: normal;
  .header-component-wrapper{
    height: unset;
  }
}
.dashboard__left__text-actions {
  // width: 60%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 10px;
  .trainingsTop__rightSide {
    .trainingsTop__btns {
      gap: 10px;
    }
  }
  @media screen and (max-width: 1300px) {
    padding-left: 20px;
  }
  .createuseractions-Wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    // width: 45%;
    .createuserExport {
      // background: #1E8659;
      // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      background: $primary;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      cursor: pointer;
    }
    .print-icon {
      background-color: $primary;
      border-radius: 8px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .createteamButton {
      background: rgba(68, 34, 139, 0.7);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      // border-radius: 8px 0px 0px 8px;
      cursor: pointer;
      justify-content: center;
      span {
        margin-top: 1px;
      }
    }
  }
}
.createuseractions_popover__actions-wrapper {
  & > ul {
    padding: 5px;
    background-color: #fff;
    border-radius: 8px;
    color: #252525;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    & > .ant-dropdown-menu-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 4px 10px;
      font-family: 'Montserrat';
      font-weight: 600;
      cursor: pointer;
      font-size: 12px;
      &.active{
        background-color: #f5f5f5;
        border-radius: 8px;
      }
      &:hover {
        background-color: #f5f5f5;
        border-radius: 8px;
      }
      & .action-icon {
        display: flex;
        align-items: center;
      }
      & .action-title {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 500;
        width: 100%;
      }
      &.no-actions {
        font-weight: 600;
        span {
          color: $primary;
        }
      }
    }
    & .ant-dropdown-menu-submenu {
      & .ant-dropdown-menu-submenu-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 8px 10px;
        font-weight: 600;
        &:hover {
          background-color: #f5f5f5;
          border-radius: 8px;
        }
      }
    }
  }
}
.edit__and__create__user__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 30px 0 30px;
  & .edit__and__create__user__tabs {
    display: flex;
    width: 390px;
    & .edit__and__create__user__single__tabs {
      cursor: default;
      min-width: 28%;
      display: flex;
      height: 37px;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: #e9eefa;
        color: #1e8659;
        & p {
          color: #1e8659;
          margin-bottom: 0px;
        }

        & .edit__and__create__user__single__tabs__img {
          fill: #0e88f2 !important;
        }
      }

      & p {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.68px;
        color: #7d8c96;
        padding-right: 5px;
      }
      & .edit__and__create__user__single__tabs__img {
        width: 13px;
        height: 13px;
        margin-right: 5px;
        margin-left: 10px;
      }
    }
  }
  & .MuiFormControl-root.MuiTextField-root {
    background-color: white;
    padding: 8px;
    width: 99%;
    margin-top: 1.5px;

    & div {
      & input {
        font-size: 15px;
      }
    }

    & div::before {
      display: none !important;
    }
  }

  & .edit__and__create__user__step {
    margin-top: 20px;
    width: 100%;

    & .edit__and__create__user__step__two__fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & div {
        width: 49%;
      }
    }
  }
}

.edit__and__create__user__inputs__cat {
  width: 45%;
  padding-bottom: 20px;
}
.edit__and__create__user__inputs__cat__icon {
  display: flex;
  & h1 {
    margin-left: 5px;
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
  }
}

.submit__user {
  width: 341px;
  height: 46px;
  margin-top: 12px;
  margin-bottom: 10px;
  background-color: $primary;
  // background: $primary;
  color: white;
}

.add_user__back_button {
  width: 341px;
  height: 46px;
  margin-bottom: 10px;
  border: 2px solid #1e8659;
  background-color: white;
  color: #1e8659;
}

.add__user__complete__button {
  width: 52px;
  height: 18px;
  margin: auto;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.63px;
  color: #c4c4c4;
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
  pointer-events: none;

  &.complete {
    color: #1e8659;
    pointer-events: visible;
  }
}

.edit__and__create__user__single__tabs {
  cursor: default;
  width: 100%;
  display: flex;
  height: 37px;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  cursor: pointer;
  & p {
    margin-bottom: 0px;
  }
  &.templates {
    cursor: pointer;
    // min-width: 100px;
  }
  p {
    // background: rgba(68, 34, 139, 0.5);
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.active {
    border-radius: 8px;
    color: white;
    p {
      background: rgba(68, 34, 139, 0.5);
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    & .edit__and__create__user__single__tabs__img {
      fill: #0e88f2 !important;
    }
  }
}
