@import '../../abstracts/variables';

.addTraining {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat';
  padding: 15px;
  background-color: #f5f5f5;

  &__container {
    margin-left: unset !important;
  }

  &__form {
    &-content {
      width: 100%;
      margin-top: 20px;
    }

    &-input {
      input {
        height: 47px;
        border: 2px solid #f5f5f5;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.addTopContent {
  .MuiInputBase-root{
    legend{
      max-width: 0 !important;
    }
  }
  .MuiOutlinedInput-notchedOutline{
    border: 1px solid #D9D9D9 !important;
  }
  .MuiAutocomplete-inputRoot{
    height: 40px !important;
    border-radius: 5px;
  }
  .MuiAutocomplete-inputRoot:hover{
    border: 1px solid #D9D9D9 !important;
  }
  .MuiAutocomplete-endAdornment{
    top: 9px !important;
    right: 20px !important;
  }
  .PrivateNotchedOutline-legendNotched-7{
    max-width: 0 !important;
  } 
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
    display: none;
  }
  .PrivateNotchedOutline-legendNotched-25{
    max-width: 0 !important;
  }
  .MuiInputBase-input{
    font-size: 14px !important;
    font-family: "Montserrat" !important;
    font-weight: 600 !important;
    color: #252525 !important;
    opacity: 90;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }

  &__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  &__backBtn {
    font-size: 14px;
    font-weight:700;
    color: $primary;
    cursor: pointer;
    display: flex;
    align-items: end;
    gap: 5px;
    span{
      padding-bottom: 2px;
    }
  }

  &__saveBtn {
    width: 120px;
    padding: 15px 0;
    color: white;
    background: $primary;
    cursor: pointer;

    &--edit {
      margin-right: 10px;
    }
  }

  &__category {
    &-title {
      font-size: 12px;
      color: #7d8c96;
    }

    &-content {
      input {
        visibility: hidden;
      }
    }
  }
  & .top__content__add__cover__container {
    display: flex;
    margin: 10px 0;
    align-items: center;
    & .top__content__add__cover__image__name {
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.77px;
      color: #979797;
      &.active {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #252525;
      }
    }

    & div {
      display: flex;
      align-items: center;
      &.active {
        padding-left: 10px;
      }
      & .image {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
      & .top__content__add__cover__input {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-decoration: underline;
        letter-spacing: -0.77px;
        color: $primary;
        cursor: pointer;
      }
    }
  }

  &__categories {
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;

   
    &
      .MuiAutocomplete-root.trainingsTop__dropdown.MuiAutocomplete-hasPopupIcon {
      width: 260px;
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
      top: -3px;
    }

    &-box {
      margin-right: 5px;
      position: relative;

      // last category item with this class name
      &:nth-last-child(-n + 2) {
        margin-right: 10px;
      }

      // first category with this class name
      &:nth-of-type(1) {
        .addTopContent__categories-name {
          padding-left: 0;
        }
      }

      &:hover {
        .addTopContent__categories-delete {
          display: block;
        }
      }
    }

    &-input {
      input {
        height: unset;
        padding: 5px;
        border: 1px solid #278b60;
        background-color: #fff;
        margin-top: 5px;
      }
    }

    &-name {
      font-size: 13px;
      font-weight: bold;
      color: #3b505d;
      padding: 5px;
      margin-top: 5px;
    }

    &-delete {
      font-size: 12px;
      position: absolute;
      top: 0;
      right: -2px;
      color: red;
      cursor: pointer;
      display: none;
    }
  }
}



.addPage {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: #E4E4E4;
  margin-top: 20px;
  padding: 15px 25px;
  border-radius: 8px;

  &__page {
    width: 100px;
    height: 110px;
    background-color: white;
    border: 2px solid white;
    margin: 10px 0;
    margin-right: 12px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #7d8c96;
    cursor: pointer;
    position: relative;
    border-radius: 8px;

    & .close__icon__div {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 5px 5px;
      height: 18px;
      & .green__icon {
        fill: $primary;
      }
    }

    &--active {
      border: 2px solid $primary;
    }

    &-add {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #888;
    }
  }
}

.draftContent {
  margin-top: 20px;

  .rdw-editor-toolbar {
    margin-bottom: 20px !important;
  }

  .rdw-option-wrapper {
    height: unset;
    img {
      padding: 5px;
    }
  }
  .rdw-link-modal{
    height: auto !important;
  }
  .rdw-editor-main {
    min-height: 250px;
    background-color: white;
    border: 1px solid #f5f5f5;
    padding: 10px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.2em 0;
  }
}
.addTraining__form-input{
  border: 1px solid black;
}

.flex__categories{
    flex-direction: row;
    gap: 10px;
}