.monitoring__container {
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before{
    display: none !important;
  }
  .MuiPaper-root{
    box-shadow: 2px 3px 8px 0px #00000026 !important;
  }
  .MuiCollapse-wrapperInner{
    margin-top: -20px;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
   }

  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    color: #252525;
  }
}
.edit__pointsRange__modal {
  padding: 20px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;
      color: $primary;
    }
    span:last-of-type {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        height: 14px;
        & > * {
          fill: $primary;
        }
      }
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    & > * {
      padding: 0;
      .form_input__label {
        font-family: 'Montserrat';
        font-size: 12px;
        color: #252525;
        font-weight: 600;
        margin-bottom: 5px !important;
      }
      input {
        margin: 0;
        height: 55px;
      }
    }
    .primaryButton {
      background-color: $primary;
      color: #fff;
      border-radius: 8px;
      height: 45px;
    }
  }
}