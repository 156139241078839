.picker__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  background-color: #fff;
  height: 40px;
  border-radius: 5px;
  padding: 0 20px;
  border: 1px solid #dadada;
  &.withError {
    border-color: red;
  }
  &-inputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;
    .singleInput {
      padding-bottom: 5px;
      color: #252525;
      border: none;
      border-bottom: 1px solid transparent;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      overflow: hidden;
      &[type='number']::-webkit-outer-spin-button,
      &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        display: none;
      }

      /* For Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
      &.focused {
        border-color: #dadada;
      }
      &.big {
        width: 90px;
      }
      &.small {
        width: 30px;
      }
    }
    .slash {
      color: #dadada;
      //   padding: 0 5px;
    }
  }
  .calendarIcon {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &.disabled {
    background-color: transparent;
  }
}
.errorText {
  font-size: 12;
  font-family: 'Montserrat';
  color: red;
  margin: 5px 0;
}
