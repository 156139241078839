@import "../../abstracts/variables";

.createuserButton{
    padding-left: 12px;
    padding-right: 12px;
    color: white;
    display: flex;
    align-items: center;
    height: 30px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    gap: 5px;
    background: $primary;
    cursor: pointer;
    justify-content: center;
    min-width: 100px;
    img{
        width: 20px;
    }
    span{
        margin-top: 1px;
    }
     &.none{
        border-radius: 0px 0px 0px 0px;
     }
}