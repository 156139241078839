@import "../../../abstracts/variables";

.delete__modal__component__import{
    width: 470px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .edit__and__create__form{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 25px;
        .datepicker-teams{
            padding-top: 0;
        }
    }
    &-header{
        width: 100%;
        justify-content: space-between;
        display: flex;
        padding: 10px 10px 0px 10px;
        align-items: center;
        .close__icon{
            width: 18px;
            height: 18px;
            background-color: #f6f6f6;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        &-title{
            font-family: "Montserrat";
            font-size: 18px;
            color: $primary;
            font-weight: 700;
        }
        &-body{
            width: 85%;
            padding: 10px 0px 30px 0px;
            .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon{
                margin-bottom: 0px;
              }
        }
    }
    & h1{
        padding-top: 50px;
        font-family: "Montserrat";
        font-size: 17px;
        color: $primary;
    }
    &__autocomplete {
        width: 80%;
        padding: 0;
        margin-top: 0 !important;
    }
    &__buttons {
        margin-top: -20px;
        padding:50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .cancel__button{
            height: 50px;
            width: 120px;
            border: 2px solid;
            border: 1px solid $primary;
            color: $primary;
            font-family: "Montserrat";
            background-color: white;
            font-size: 17px;
          
        }
        .confirm__button{
            margin-left: 10px;
            height: 50px;
            width: 120px;
            background: $primary;
            color:white;
            font-family: "Montserrat";
            font-size: 17px; 
        }
    }
}
.intern_modal{
    width: 100%;
}
.delete__modal__component{
    // width: 470px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .users__dropdown{
        margin: 0;
    }
    &__autocomplete {
        width: 80%;
        padding: 0;
        margin-top: 0 !important;
    }

    & h1{
        padding-top: 50px;
        font-family: "Montserrat";
        font-size: 17px;
        color: $primary;
        // padding-left: 40px;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        font-weight: 700;
        color: $primary
    }

    &__button{
        gap: 20px;
        padding: 30px 0 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .cancel__button{
            height: 50px;
            width: 150px;
            border: 1px solid #979797;
            color: #979797;
            font-family: "Montserrat";
            background-color: white;
            font-size: 16px;
            border-radius: 8px;
            font-weight: 500;
          
        }
        .confirm__button{
            height: 50px;
            width: 150px;
            border: 1px solid $primary;
            // background: linear-gradient(45deg, #981596, #1C4CCF) 1;
            background: $primary;
            color:white;
            font-family: "Montserrat";
            // background-color: #1C4CCF;
            font-size: 16px; 
            border-radius: 8px;
            font-weight: 500;

        }
    }
}