@import '../../abstracts/variables';

.filter__container {
  padding: 20px 20px 0 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .filters__dropdowns {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    & [class*='dropdown'] {
      .MuiAutocomplete-inputRoot {
        height: 35px;
        width: 150px;
        color: #c1c1c1;
        border: 1px solid #c1c1c1;
        padding: 0 5px;
        .MuiAutocomplete-input {
          color: #252525;
          font-weight: 600;
        }
      }
      .MuiAutocomplete-popupIndicator {
        color: #c1c1c1;
      }
    }
    .rangepicker__picker {
      background: transparent;
      border: 1px solid #c1c1c1;
      width: 230px;
      height: 35px;
    }
    .status__multiple {
      .MuiAutocomplete-inputRoot {
        min-height: 35px;
        max-height: 300px;
        padding: 0 5px;
        min-width: 190px;
        max-width: 500px;
        border: 1px solid #c1c1c1;
        .MuiAutocomplete-input {
          color: #252525;
          font-weight: 600;
        }
      }
      .MuiAutocomplete-popupIndicator {
        color: #c1c1c1;
      }
      .MuiAutocomplete-tag {
        height: 25px;
      }
    }
  }
}
.sales_tracker_content {
  padding: 20px;
  width: 100%;
  display: flex;
  gap: 15px;
  overflow-x: auto;
  overflow-y: visible;
  &-loader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 120px);
    gap: 20px;
    &-skeleton{
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #e1e1e1;
      min-width: 250px;
      overflow: scroll;
      border-radius: 8px;
      align-items: center;
    }
  }
  .sales_tracker_box_container {
    min-width: 250px;
    width: 24%;
    font-family: 'Montserrat';
    background-color: #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: fit-content;
    overflow-y: hidden;
    max-height: calc(100vh - 115px);
    min-height: 100px;
    position: relative;

    .sales_tracker_boxes {
      height: fit-content;
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      color: #252525;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c8c8c8;
        border-radius: 6px;
      }
      &__loader {
        position: absolute;
        right: 0;
        left: 0;
        top: auto;
        background-color: transparent;
      }
    }
    .sales_tracker_title {
      width: 100%;
      color: #fff;
      display: flex;
      width: 100%;
      padding: 5px 10px;
      align-items: center;
      border-radius: 8px 8px 0px 0px;
      font-weight: 700;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        gap: 10px;
        max-width: 60%;
      }
      &-right {
        display: flex;
        align-items: center;
        gap: 5px;
        &-icon {
          height: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          svg {
            path {
              fill: $primary;
              &:first-of-type {
                fill: #fff;
              }
            }
          }
          &.active {
            svg {
              path {
                fill: #fff;
                &:first-of-type {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
      .sales_tracker_status {
        color: #fff;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sales_tracker_selected-count {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 11px;
        font-weight: 400;
      }
      .three-dots-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 0 auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
    .sales_tracker_checkbox {
      width: 13px;
      height: 13px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .MuiButtonBase-root {
        svg {
          rect:last-of-type {
            fill: $secondary;
          }
        }
      }
    }
  }
}
.user__tooltip {
  color: $primary;
}
.user_img_avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.users__modal {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  &__container {
    padding: 10px 15px;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 10px 30px;
      h3 {
        color: $primary;
        font-weight: 600;
      }
      .close__modal__icon {
        width: 20px;
        height: 20px;
        background-color: #f6f6f6;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
      }
    }
    .users {
      margin-bottom: 15px;
      padding: 15px;
      min-height: 120px;
      max-height: 500px;
      overflow-y: auto;
      &__wrapper {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delete_user_icon {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      &__avatar {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c8c8c8;
        border-radius: 6px;
      }
    }
  }
}
.ant-dropdown.popover-exports {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 200px;
  gap: 5px;
  ul {
    padding: 5px;
    li {
      &.no-actions {
        font-weight: 600;
        span {
          color: $primary;
        }
      }
      span {
        color: #3c3c3c;
        cursor: pointer;
        height: 30px;
        display: flex;
        align-items: center;
        font-family: 'Montserrat';
      }
      &:not(.no-actions):hover {
        background-color: $secondaryhover;
      }
    }
  }
}
.createuserExport {
  background: $primary;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  img{
    width: 16px;
  }
  &:hover {
    background-color: $secondaryhover;
  }
  &_loader {
    font-size: 2.7px !important;
  }
}
.add_a_card_btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  padding: 10px 15px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(2.5px);
}
.sales__tracker__modal_duplicate {
  background-color: #fff;
  height: 100vh;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.sales__tracker__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  .sales__tracker__actions__modal {
    background-color: #fff;
    padding: 20px 40px 20px 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &-add{
      background-color: #fff;
      padding: 20px 40px 20px 40px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      height: 420px;
      .grouped__select{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .ant-select{
          width: 100%;
        }
        .select__title{
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
          color: #252525;
          margin-bottom: 5px;
        }
        .ant-select-selector{
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          height: 50px;
          width: 100%;
        }
      }
      .actionsModal {
        &-wrapper {
          width: 100%;
          background-color: 'blue';
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 70px;
          &-title {
            font-family: 'Montserrat';
            font-size: 20px;
            color: $primary;
            font-weight: 700;
          }
        }
        &-body {
          height: 80px;
          &-text {
            font-size: 14;
            font-family: 'Montserrat';
            color: $primary;
            font-weight: 500;
          }
        }
        &-footer {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .actionsModal {
      &-wrapper {
        width: 100%;
        background-color: 'blue';
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        &-title {
          font-family: 'Montserrat';
          font-size: 20px;
          color: $primary;
          font-weight: 700;
        }
      }
      &-body {
        height: 80px;
        &-text {
          font-size: 14;
          font-family: 'Montserrat';
          color: $primary;
          font-weight: 500;
        }
      }
      &-footer {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.sales__tracker__modal {
  background-color: #fff !important;
  height: 100vh !important;
  padding: 0 20px 70px 20px !important;
  border-radius: 8px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  overflow-y: auto !important;
  &-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  &-previewPdf {
    padding: 0;
    background-color: #fff;
    height: 100vh;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 10px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--id {
        color: $primary;
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 700;
      }
      &--closeicon {
        width: 20px;
        height: 20px;
        background-color: #f6f6f6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .modal_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 20px 0;
    background-color: #fff;
    z-index: 1;
    .lead__id {
      display: flex;
      gap: 5px;
      color: #252525;
      font-family: 'Montserrat';
      font-size: 14px;
      .lead__icon {
        svg {
          & > * {
            fill: $primary;
          }
        }
      }
      .lead__text {
        font-weight: 600;
      }
      &__value {
        font-weight: 800;
      }
    }
    .tabs_wrapper {
      font-family: 'Montserrat';
      display: flex;
      gap: 0;
      max-width: 300px;
      overflow-x: auto;
      padding-bottom: 7px;
      white-space: nowrap;
      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 6px;
      }
    }

    .tab {
      background-color: #f2f2f2;
      border-radius: 50px;
      padding: 2px 5px;
      font-weight: 600;
      min-width: 40px;
      color: #5c5c5c;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .active {
      background-color: $primary;
      color: #fff;
      z-index: 2;
    }

    .modal__icons {
      display: flex;
      gap: 10px;
      width: 80px;
      justify-content: flex-end;
      .close__icon,
      .duplicate__icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: #fff;
        cursor: pointer;
      }
    }
  }
  .custom-input,
  .disabled-input {
    margin: 0 !important;
    border-radius: 5px;
    font-weight: 500;
    outline: none;
    height: 30px;
    font-family: 'Montserrat';
    color: #252525;
    font-size: 14px;
  }
  .custom-input {
    border: 1px solid #d9d9d9;
  }
  .disabled-input {
    background: #f0f0f0 !important;
  }
  .modal__inputs {
    .form_input__label {
      color: #252525;
      font-weight: 600;
      font-size: 14px;
    }
    .name__surname__inputs {
      margin: 0;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
    .errorsModal {
      color: red;
      font-size: 12px;
    }
    .other__inputs {
      display: grid;
      gap: 10px;
      grid-template-columns: 3fr 9fr;
    }
    .adress__inputs {
      gap: 10px;
      display: grid;
      grid-template-columns: 3fr 7fr 2fr;
      margin: 15px 0;
    }
    .textarea_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: auto;
      padding: 15px 0;
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .textarea__input {
        display: flex;
        flex-direction: column;
        width: 60%;
        font-family: 'Montserrat';
        textarea {
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          outline: none;
          margin-top: 5px;
          resize: none;
          height: 200px;
          padding: 5px;
          color: #252525;
        }
        .textarea_max_length {
          color: #ddd;
          font-size: 14px;
          align-self: flex-end;
          margin: 5px 0;
        }
      }
    }

    .modal__datepicker {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 15px 0;
      .title {
        color: #252525;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .modal__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat';
    padding: 20px;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    .submit__btn {
      background-color: $primary;
      color: white;
      margin: 0 0 0 auto;
      padding: 10px 15px;
      font-weight: 600;
      width: 100%;
      border-radius: 8px;
    }
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    border-radius: 6px;
    // &:hover {
    //   background-color: #a0a0a0;
    // }
  }

  // &:hover {
  //   &::-webkit-scrollbar-thumb {
  //     background-color: #a0a0a0;
  //   }
  // }
}
.breakLine {
  height: 1px;
  background-color: rgba(37, 37, 37, 0.1);
  margin: 10px 0;
}
.imports__table {
  height: calc(100vh - 290px);
  margin: 20px;
  position: relative;
  .rdg-light {
    height: 100%;
    font-weight: 600;
  }
}
.upload__overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .upload__container {
    background-color: #fff;
    position: absolute;
    right: 0;
    width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 762px) {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 3;
    }
    .errors {
      color: red;
    }
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 90%;
      position: absolute;
      bottom: 30px;
    }
    .close__upload__modal {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      &__title {
        font-family: 'Montserrat';
        color: $primary;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .steps {
      width: 90%;
      margin: 10px auto;
      display: flex;
      justify-content: space-between;

      .step_1,
      .step_2 {
        width: 40px;
        height: 40px;
        background-color: #f2f2f2;
        color: #671baa;
        font-family: 'Montserrat';
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .step_1_active,
      .step_2_active {
        background-color: $primary;
        color: white;
      }
      .steps__border {
        width: 90%;
        height: 4px;
        background-color: $primary;
        align-self: center;
      }
    }

    .upload__input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      align-items: center;
      width: 90%;
      &.drag-over {
        border: 1px dashed #007bff;
        background-color: #f0f0f0;
        opacity: 0.5;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-self: flex-end;
        align-items: center;
        width: 60%;
        .input__title {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: $primary;
        }
      }
      .bottom {
        width: 100%;
        cursor: pointer;
        .file__name {
          display: flex;
          justify-content: center;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 500;
          color: $primary;
          text-align: center;
        }
        .label_input_csv {
          margin: 5px 0;
          display: flex;
          justify-content: center;
          background: $primary;
          color: white;
          border-radius: 8px;
          padding: 10px 0;
          font-family: 'Montserrat';
          cursor: pointer;
        }
      }
      .middle {
        text-align: center;
        border: 2px dashed #e8e8e8;
      }
    }
    .upload__content {
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #979797;
      color: #c1c1c1;
      padding: 5px;
      width: 100%;
      justify-content: space-between;
      padding-right: 20px;
      &__second {
        display: flex;
        align-items: center;
        float: right;
        gap: 5px;
        &__delete {
          padding-top: 4px;
        }
      }
      &__first {
        gap: 5px;
        display: flex;
        align-items: center;
        &__type {
          background-color: #ebf7f2;
          width: 40px;
          font-family: 'Montserrat';
          color: #31b073;
          height: 40px;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      }
    }
    .step_2_container {
      width: 90%;
      font-family: 'Montserrat';
      height: 80%;
      .dropdownlabel {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      .chooseClientDropdown {
        height: auto;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(37, 37, 37, 0.1);
        .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
          margin-top: 10px;
        }
      }
      .chooseUsersDropdown {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .search_bar {
        margin-top: 10px;
        .big__search__bar__container {
          width: 100% !important;
          border: 1px solid #e8e8e8;
          border-radius: 8px 8px 0px 0px;
          background-color: white;
          height: 50px;
        }
      }
      .selected_users_wrapper {
        width: 100%;
        max-height: 300px;
        font-family: 'Montserrat';
        .selected_users_title {
          padding: 20px;
          color: $primary;
          font-weight: 700;
        }
        .selected__users__display {
          padding: 10px 15px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          overflow-y: auto;
          max-height: 250px;
          background: #f7f7f7;
          border: 1px solid #e8e8e8;
          border-radius: 5px;
          .selected_users_container {
            width: 48%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 45px;
            font-weight: 600;
            background: #e1e1e1;
            border-radius: 50px;
            padding: 5px 10px 5px 15px;
            margin: 6px 0;
            .selected__user__name {
              color: #252525;
              font-size: 12px;
            }
            .remove_btn {
              background-color: #8d8d8d;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 6px;
            // &:hover {
            //   background-color: #a0a0a0;
            // }
          }

          // &:hover {
          //   &::-webkit-scrollbar-thumb {
          //     background-color: #a0a0a0;
          //   }
          // }
          .selected_users_errors {
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            margin: 10px auto;
            color: $primary;
          }
        }
      }
    }
  }
}
.middle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;
  background: #f5f5f5;
  border-radius: 8px;
  .uploadFiles__container_attach {
    max-width: 300px !important;
    width: auto;
    min-width: 144px !important;
  }
  .uploadFiles__container_attach {
    height: 40px !important;
  }
  .upload__csv__input {
    display: none;
  }
  .selected__file {
    color: #252525;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
  }
  span {
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align: center;
    margin-bottom: 10px;
  }
  &__content {
    display: block;
    div {
      margin: 10px;
    }
  }
}
.datepicker__dropdown {
  z-index: 99999;
}

.create-status-modal {
  font-family: 'Montserrat';
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100vh;
  overflow: hidden;
  max-height: 100vh;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      cursor: pointer;
    }
    &-title {
      color: $primary;
      font-family: 'Montserrat';
      font-size: 20px;
      // margin: 0 auto;
      font-weight: 700;
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    font-family: 'Montserrat';
    height: calc(100vh - 80px);
    .form-element-row {
      label {
        margin-right: 50px;
        span.MuiButtonBase-root {
          padding-left: 9px;
        }
      }
    }
    .form_input {
      .form_input__label {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
      }
    }

    &-colorPicker {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      &-label {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
      }
      &-indicator {
        padding: 0 20px 0 5px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        .color--indicator {
          align-self: flex-start;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          margin-top: 40px;
        }
        .hex--input {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-self: flex-start;
          &__label {
            color: #252525;
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 600;
          }
          input {
            border-radius: 8px;
            border: 1px solid #e8e8e8;
            background: #fff;
            padding: 10px;
            text-align: center;
          }
        }
      }
      &-error {
        font-family: 'Montserrat';
        display: block;
        margin-top: 5px;
        margin-left: 2px;
        color: red;
        font-size: 12px;
      }
    }
    & .primaryButton {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 46px;
      margin-top: 10px;
      background-color: $primary;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-weight: 600;
    }
    .status-dropdown {
      &__label {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 8.5px;
      }
      .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
        margin-bottom: 3px;
      }
    }
    &-subCategory {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 5px;
      // height: 345px;
      height: calc(100vh - 400px);
      overflow-x: auto;

      &-label {
        color: #252525;
        // font-family: "Montserrat";
        font-size: 14px;
        font-weight: 600;

        &-items {
          display: flex;
          flex-direction: column;
          gap: 20px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            cursor: pointer;
          }
          &::-webkit-scrollbar-track {
            background: #e9eefa;
            background: linear-gradient(
              119.24deg,
              #98159673 0%,
              #1c4ccf5f 100%
            );
          }
          &::-webkit-scrollbar-thumb {
            // background: #0E88F2;
            background: $primary;
            border-radius: 15px;
          }
        }
      }
      &-inMap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 1px dashed $secondaryhover;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%236C1EB0FF' stroke-width='1' stroke-dasharray='6%2c 9' stroke-dashoffset='34' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 8px;
        &:hover {
          background: $secondaryhover;
          border-radius: 8px;
        }
        padding: 10px;
        &-delete {
          visibility: hidden;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:hover {
          .create-status-modal-form-subCategory-inMap-delete {
            visibility: visible;
          }
        }
      }
    }
  }
}
.popover__actions-wrapper {
  z-index: 999999;
  & > ul {
    min-width: 180px;
    padding: 5px;
    background-color: #fff;
    border-radius: 8px;
    color: #252525;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    & > .ant-dropdown-menu-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 5px 8px;
      font-family: 'Montserrat';
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
        border-radius: 8px;
      }
      & .action-icon {
        display: flex;
        align-items: center;
      }
      & .action-title {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 500;
        width: 100%;
      }
      &.no-actions {
        font-weight: 600;
        span {
          color: $primary;
        }
      }
    }
    & .ant-dropdown-menu-submenu {
      & .ant-dropdown-menu-submenu-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 8px 10px;
        font-weight: 600;
        &:hover {
          background-color: #f5f5f5;
          border-radius: 8px;
        }
      }
    }
  }
  &-header {
    width: 100%;
    &:hover {
      background-color: transparent !important;
    }
    .header_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #979797;
      padding-bottom: 5px;
    }
  }
}
.userActions-popper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &-length {
    width: 18px;
    height: 18px;
    background-color: #d00a12;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: 22px;
    font-size: 8px;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  &:hover {
    svg {
      rect {
        fill: $secondaryhover;
      }
    }
  }
}
.custom-popover-leadstatuses {
  & ul {
    .ant-dropdown-menu-item {
      padding: 0;
      cursor: default;
      &:hover {
        background-color: #fff;
      }
    }
  }
  .lead-statuses__popover {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-header {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .leadStatus-container {
      max-height: 260px;
      overflow-y: auto;
      font-weight: 500;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 6px;
      }
      &-wrapper {
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        &-name {
          color: #252525;
          font-family: 'Montserrat';
          font-size: 13px;
        }
        &-icons {
          visibility: hidden;
          display: flex;
          gap: 5px;
          height: 25px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              rect {
                fill: $primary;
                fill-opacity: 1;
              }
            }
          }
        }
        &:hover {
          background: #f5f5f5;
          border-radius: 8px;
          .leadStatus-container-wrapper-icons {
            &-background {
              background-color: #979797;
              border-radius: 50%;
              width: 25px;
              height: 100%;
              height: auto;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
            }
            visibility: visible;
            cursor: pointer;
          }
        }
      }
      &-noResults {
        color: $primary;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 25px 0;
      }
    }
  }
}

.custom-popover-trainingFilters {
  // min-width: 400px !important;
  width: 500px !important;
  // top: 64px !important;
  // left: 958px !important;
}

.autocomplete-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  &-color {
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }
}
.sales_tracker-searchBar {
  display: flex;
  flex-direction: row-reverse;
  gap: 12px !important;
  align-items: center;
}
.dashboard__left__text-actions.salesTracker {
  gap: 12px;
  align-items: center;
}
.ant-dropdown-menu-submenu {
  & > ul {
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    color: #252525;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    & > .ant-dropdown-menu-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 5px 10px;
      cursor: pointer;
      font-family: 'Montserrat';
      font-weight: 500;
      &:hover {
        background-color: #f5f5f5;
        border-radius: 8px;
      }
      & .action-icon {
        display: flex;
        align-items: center;
      }
      & .action-title {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 500;
        width: 100%;
      }
    }
  }
}
.counter-preview-popover {
  .ant-dropdown-arrow::before {
    background-color: #fff;
  }
  &.all-selected {
    .ant-dropdown-arrow::before {
      background-color: $secondaryhover;
    }
  }
  .ant-dropdown-menu {
    border-radius: 5px;
    padding: 0;
    &-item {
      border-radius: 5px;
      &.first-child {
        background-color: $secondaryhover;
      }
      .ant-dropdown-menu-title-content {
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          span {
            color: #252525;
            font-family: 'Montserrat';
            font-size: 11px;
          }
          b {
            padding-left: 5px;
            color: $primary;
            font-family: 'Montserrat';
            font-size: 11px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.selectedIcon__dropdown {
  display: flex;
  align-items: center;
  gap: 5px;
  & .selectedIcon__icon {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.sort-custom-row {
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .tick--icon {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
}

.textarea_container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alertsCards__container--popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  max-width: 30px;
  max-height: 30px;
}

.alertsCards__container--popup-card {
  .ant-dropdown-menu {
    border-radius: 8px !important;
    font-size: 12px;
  }
}

.modal__activity {
  width: 100%;
  .modal__activity__header {
    font-family: 'Montserrat';
    padding: 15px 5px 5px 5px;
    p {
      color: #252525;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
.modal__dropdown {
  display: flex;
  flex-direction: column;
  .chooseuser-wrapper {
    width: 50%;
    height: auto;
    align-self: center;
  }
  .chooseUsersDropdown {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .selected_users_wrapper {
    width: 100%;
    max-height: 300px;
    font-family: 'Montserrat';
    .selected_users_title {
      padding: 20px;
      color: $primary;
      font-weight: 700;
    }
    .selected__users__display {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-y: auto;
      max-height: 250px;
      background: #f7f7f7;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      .selected_users_container {
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 45px;
        font-weight: 600;
        background: #e1e1e1;
        border-radius: 50px;
        padding: 5px 10px 5px 15px;
        margin: 6px 0;
        .selected__user__name {
          color: #252525;
          font-size: 12px;
          text-align: center;
        }
        .remove_btn {
          // background-color: #8d8d8d;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c8c8c8;
        border-radius: 6px;
        // &:hover {
        //   background-color: #a0a0a0;
        // }
      }

      // &:hover {
      //   &::-webkit-scrollbar-thumb {
      //     background-color: #a0a0a0;
      //   }
      // }
      .selected_users_errors {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        margin: 10px auto;
        color: $primary;
      }
    }
  }
  &__menu {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    label {
      color: #252525;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Montserrat';
    }
  }
}
.comments_container {
  width: 100%;
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
  }
  &-tabswrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat';
    .tabs {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    &_item {
      color: #252525;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      &.active {
        color: $primary;
      }
    }
  }
  .comment_wrapper {
    margin: 10px 0;
    display: flex;
    align-items: center;
    .user_avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #a797c7;
      align-self: flex-start;
      margin-top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 500;
    }
    .comment__container {
      margin-left: 13px;
      width: 90%;
      display: flex;
      flex-direction: column;
      .comment_info {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-family: 'Montserrat';
        padding: 0 7px;
        .comment_user {
          font-size: 13px;
          font-weight: 600;
          color: #252525;
        }
        .comment_time {
          color: #8a8a8a;
          font-size: 10px;
          font-weight: 500;
        }
      }
      .comment {
        font-family: 'Montserrat';
        margin: 5px 0;
        background: #f0f0f0;
        border-radius: 10px;
        padding: 5px 8px;
        &_title {
          color: #484848 !important;
          font-size: 13px;
        }
      }
    }
  }
  .userstab-wrapper {
    display: flex;
    flex-direction: column;
    // margin: 20px 0px;
    gap: 15px;
    width: 100%;
    &_add {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-family: 'Montserrat';
      font-weight: 600;
      cursor: pointer;
      padding: 0 18px 0 18px;
    }
    &_itemwrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 20px;
      &-icon {
        width: 14px;
        height: 14px;
      }
      .name {
        display: flex;
        gap: 10px;
        color: #252525;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        align-items: center;
      }
      .avatar {
        width: 24px;
        height: 24px;
        background: $primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 11px;
      }
    }
  }
  .relations-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    gap: 15px;
    width: 100%;
    &_item {
      width: 100%;
      border-radius: 5px;
      padding: 9px 20px 9px 20px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      font-family: 'Montserrat';
      .box {
        &-left {
          display: flex;
          flex-direction: column;
          &-name {
            font-size: 14px;
            font-weight: 600;
            color: #252525;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &-address {
            font-size: 10px;
            color: #252525;
            font-weight: 600;
            overflow-wrap: break-word;
          }
        }
        &-right {
          font-family: 'Montserrat';
          display: flex;
          align-items: center;
          gap: 20px;
          .sales_tracker_icons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 5px;
            gap: 15px;
            &-comments {
              color: #252525;
              font-family: 'Montserrat';
              display: flex;
              align-items: center;
              gap: 3px;
              justify-content: space-between;
              .activity_count {
                display: flex;
                align-items: center;
                font-size: 12px;
              }
            }
            .lead__potential {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &-error {
    padding: 20px 0;
    border-radius: 5px;
    font-size: 18px;
    color: $primary;
    display: flex;
    font-weight: 600;
    justify-content: center;
  }
}
.leadStatistics-container {
  font-family: 'Montserrat';
  padding: 20px;
  display: flex;
  gap: 20px;
  .rdg-light {
    height: 90%;
    font-family: "Montserrat";
    .headerCol{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .table__wrapper{
      padding: 20px 12px 20px 12px;
      border-radius: 0px;
      
    }
  }
  &_left {
    min-width: 400px;
    height: calc(100vh - 150px);
    overflow: scroll;
    &-user {
      width: 378px;
      height: 50px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      cursor: pointer;
      &.active {
        background-color: #d9d9d9;
        &_name {
          color: $primary;
        }
      }
      &_name {
        color: #252525;
        font-size: 14px;
        font-weight: 600;
      }
      &_stats {
        display: flex;
        gap: 5px;
        color: #252525;
        font-weight: 600;
        font-size: 11px;
        align-items: center;
        &.active {
          color: $primary;
        }
        .total {
        font-size: 14px;
        }
      }
    }
  }
}
.address-view__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 130px);
  &-bottomLoader {
    position: absolute;
    bottom: 40px;
    top: auto;
    right: 20px;
    left: 20px;
    background-color: transparent;
    backdrop-filter: blur(5px);
    height: 91px;
    display: flex;
    align-items: center;
  }
}
.lead__users {
  display: flex;
  gap: 2px;
  &-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9682bb;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
}
}
.status__box-cell {
  padding: 0;
  span {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
}
.lead__users-cell{
  display: flex;
  padding: 0;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  .lead__users {
    align-items: center;
  }
}
.activity_count-cell{
  display: flex;
  align-items: center;
  .activity_count-wrapper{
    display: flex;
    padding: 0;
    align-items: center;
    padding-left: 10px;
    position: relative;
    span {
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #C1C1C1;
      color: #fff;
      font-family: 'Montserrat';
      display: flex;
      justify-content: center;
      align-items: center;
      right: -3px;
      bottom: -1px;
      left: auto;
      font-size: 10px;
    }
  }
}
.sales_tracker_box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
  margin: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  &-content {
    max-width: 90%;
    min-width: 90%;
  }
  &:hover {
    .sales_tracker_box-header-icon {
      display: flex;
      width: 25px;
      height: 20px;
      align-items: center;
      align-self: flex-end;
      justify-content: flex-end;
      position: absolute;
      top: 7px;
      right: 5px;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    max-width: 85%;
    &-icon {
      display: none;
      width: 25px;
      height: 20px;
    }
  }
  .user__name {
    font-size: 14px;
    font-weight: 600;
    color: #252525;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat';
  }
  .user__adress {
    font-size: 10px;
    color: #252525;
    font-weight: 600;
    overflow-wrap: break-word;
    font-family: 'Montserrat';
  }
  .statuses{
    margin-top: 10px;
    font-size: 10px;
    color: #252525;
    font-weight: 600;
    gap: 6px;
    display: flex;
  }
  &-footer {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .sales_tracker_icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      gap: 15px;
      &-comments {
        color: #252525;
        font-family: 'Montserrat';
        display: flex;
        align-items: center;
        gap: 3px;
        justify-content: space-between;
        .activity_count {
          display: flex;
          align-items: center;
          font-size: 12px;
        }
      }
      .lead__potential {
        font-size: 12px;
      }
    }
    .user_img_avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
  &-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 14px;
    height: 14px;
    .Mui-checked {
      .MuiIconButton-label {
        svg {
          path {
            fill: $secondary;
          }
        }
      }
    }
  }
  &.modal__box {
    margin: 10px 0;
    .sales_tracker_box-content {
      padding: 0 10px;
      min-width: 100%;
      max-width: 100%;
    }
  }
}