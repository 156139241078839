@import '../../abstracts/variables';

.uploadcomponent__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.uploadFiles__container {
  &_attach {
    cursor: pointer;
    max-width: 300px;
    width: auto;
    min-width: 144px;
    padding: 0px 10px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: linear-gradient(94.98deg, $primary 0%, #400077 100%) ;
    font-family: "Montserrat";
    height: 36px !important;


    &-icon {
      cursor: pointer;
      text-wrap: nowrap;
      font-size: 14px;
      font-weight: 600;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat";
      gap: 20px;
    }
  }
}

.mappedFiles__container {
  display: flex;
  gap: 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  overflow-y: scroll;
}

.uploadCards__container {
  display: flex;
  justify-content: space-between;
  width: 310px;
  min-width: 300px;
  height: auto;
  border-radius: 8px;
  background-color: #e4e4e4;
  padding: 20px;
  gap: 25px;
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    .loader {
      color: $primary;
    }
  }
  &_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_iconAndTitle {
    display: flex;
    gap: 15px;
    &_img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_title {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-s1 {
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: rgba(108, 30, 176, 0.8);
        width: 150px;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 13px;
      }
      &-s2 {
        font-size: 10px;
        font-weight: 500;
        line-height: 8px;
        color: rgba(108, 30, 176, 0.8);
      }
    }
  }
  &_right {
    display: flex;
    flex-direction: column;
    gap: 17px;
    &-imgAndTxt {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      color: rgba(108, 30, 176, 0.8);
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 5px;
      // width: 55px;
      height: auto;
      border-radius: 5px;
      background-color: rgba(108, 30, 176, 0.3);
      cursor: pointer;
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-txt {
        color: white;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
}
