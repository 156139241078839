.title-component {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.previewtitles{
        font-weight: 500;
    }
}