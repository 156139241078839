@import '../../common/styles/abstracts/mixins';
@import "../../abstracts/variables";

.company__logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 30px;
}

.errorClass {
  display: block;
  margin-top: 5px;
  margin-left: 5px;
  color: #D00A12;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal
}
.validation-error {
  // display: block;
  // margin-bottom: 10px;
  // color: red;
  // font-size: 12px;
  display: block;
  margin-top: 5px;
  margin-left: 5px;
  color: #D00A12;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.forgot-password__div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth {
  background-color: #fff;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .token__invalid__div{
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1e8659;
    font-family: "Montserrat";
    & img{
      width: 105px;
 
    }
    & h1{
      margin-top: 40px;
      margin-bottom: 10px;
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: bold;
      color: #26353e;
    }
    & p{
      cursor: pointer;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #1e8659;
      text-decoration: underline;
  

    }

  }
  .left{
    width: 55%;
    height: 100%;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(50.4175px);
  }
  .middle {
    margin: 0 auto;
    width: 450px;
    height: auto;
    padding: 30px 30px 10px 30px;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px 0px #00000033;
    border-radius: 8px;
   
    form{
      width: 100%;
    }
    span{
      font-size: 11px;
    }

    .form_input  {
        padding: 3px 0;
        margin-bottom: 12px;
        font-family: "Montserrat";
        .input-contanier{
          position: relative;
        }
        input {
            margin-top: 2px;
            border: 1px solid;
            border-color: #d9d9d9;
            border-radius: 2px;
            height: 50px !important;
            font-size: 13px;
            font-family: "Montserrat";
        }
        .icon{
          position: absolute;
          right: 20px;
          bottom: 11px;
        }
        input:-webkit-autofill {
          font-family: "Montserrat";
          transition: background-color 1000000s ease-in-out 0s;
      }
    }
  }

  .title {
    width: 100%;
    display: block;
    margin: 0 0 50px 9px;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #26353e;
    text-align: center;
  }

  .authBtn {
      width: 100%;
      padding: 13px 0;
      display: block;
      color: #fff;
      font-size: 13px;
      margin-top: 20px;
      background: #6C1EB0;
      border-radius: 8px;

      &.disabled{
        background-color: rgb(160, 160, 160);
        cursor: not-allowed;
      }

  }

  .login-bottom {
    justify-content: space-between;
    display: flex;
      text-align: center;
      color:#1C4CCF;
      & > * {
          display: block;
          font-weight: 600;
          cursor: pointer;
          font-family: "Montserrat";
      }
  }

  .forgot {
      margin: 18px 0;
      font-size: 12px;
  }

  .no-account {
    color: #7d8c96;
    font-size: 12px;

  }

  .create-account {
    margin-top: 10px;
    font-size: 13px;


  }



  .terms-privacy {
      color: #808f98;
      margin: 20px 0 30px 0;
      display: block;
      font-size: 12px;
      cursor: default;



      span {
          color: #1e8659;
          cursor: pointer;
      }
  }

  .forgot-password {

    &__title {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
    }

    &__text {
        font-size: 14px;
        color: #3b505d;
        margin: 15px 0 25px 0;

     
    }
  }

}
.input-required{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
.PrivateNotchedOutline-root-9.MuiOutlinedInput-notchedOutline{
  border: solid 2px #f5f5f5 !important;
}