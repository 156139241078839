@import '../styles/abstracts/mixins';
@import '../abstracts/variables';

.form_input {
  padding: 5px 0;

  & .form_input__label {
    font-family: 'Montserrat';
    font-size: 12px;
    margin-left: 1px;
    color: '#252525';
  }
  input {
    width: 100%;
    height: 40px;
    margin: 5px 0 0;
    padding: 16px 16px 16px 12px;
    border: solid 1px #d9d9d9;
    background-color: #fff !important;
    font-size: 13px;
    color: $primary;
    border-radius: 5px;
  }
  .bottomBreak {
    position: relative;
    float: left;
    width: 100%;
    height: 1px;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    border-bottom: 0.7px dashed #b3b0b0;
  }
 &.relative {
  width: calc(100% - 40px) !important;
  position: relative;
  input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
  }
  .info__toggler {
    position: absolute;
    bottom: 0;
    top: auto;
    right: -40px;
    left: auto;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #979797;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
  }
 }
}
.form_input__label {
  font-family: 'Montserrat';
  font-size: 12px;
  margin-left: 1px;
  color: #252525;
  font-weight: 600;
}
#upload-photo {
  padding-top: 5px;
}

// INPUT FILE

.input__type__file__bordered {
  width: 100%;
  height: 40px;
  margin: 6.5px 0 0;
  // padding: 16px 16px 16px 12px;
  border: dashed 1px #d9d9d9;
  // border-bottom: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: 'Montserrat';
  border-radius: 8px;

  label {
    height: 45px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    font-size: 12px;
    color: #c1c1c1;
  }
}
.input__type__file__bordered__name {
  width: 100%;
  height: 37px;
  border-radius: 2px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: 'Montserrat';
  padding: 16px 16px 16px 12px;
  border: dashed 2px #efefef;
  border-top: none;
}
.input-description {
  color: #1e8659;
  font-size: 11px;
}

.create__user__form__span {
  width: 100%;

  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  color: #26353e;
  margin: 10px 0 10px 0;
}

.create__user__form__button {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.create_user_company_section {
  display: flex;
  flex-direction: row;
  // flex-wrap: nowrap;
  width: 100%;
  // justify-content: space-between;
}

.create_user_company_inputs {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: space-evenly;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.create_user_agency_settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 30px;
}

// .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
//   border-color: white !important;
// }

@media (max-width: 1150px) {
  .create_user_company_section {
    flex-direction: column;
    .create_user_company_inputs {
      justify-content: space-between;
    }
    .create_user_agency_settings {
      justify-content: space-between;
    }
  }
}

.preview__uploaded__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20;
}
.custom__input__popover {
  z-index: 999999999;
  & ul {
    border-radius: 8px;
    li {
      font-family: 'Montserrat';
      font-size: 12px;
      color: #252525;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
