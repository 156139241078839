.timelineContainer {
  width: 0;
  &-show {
    width: 400px;
    background-color: #f5f5f5;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    .noData {
      font-family: 'Montserrat';
    }
    &_body {
      margin: 20px;
      height: calc(100vh - 160px);
      overflow-y: scroll;
      gap: 15px;
      display: flex;
      flex-direction: column;
      .monthWrapper {
        display: flex;
        flex-direction: column;
      }
      .day {
        color: #252525;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      &-box {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
        min-height: 67px;
        &.current {
          background-color: #d9d9d9;
        }
        .left {
          display: flex;
          flex-direction: column;
        }
        .date {
          color: #252525;
          font-size: 13px;
          font-weight: 600;
        }
        .version {
          display: flex;
          gap: 5px;
          align-items: center;
        }
        .currentVersion {
          color: $primary;
          font-weight: 600;
          font-size: 11px;
        }
      }
    }
    &_footer {
      height: 50px;
      margin: 0px 20px 0px 20px;
      border-top: 1px solid #d9d9d9;
      &-checkbox {
        margin-top: 10px;
        label {
          margin-right: 0;
          margin-left: 0;
        }
        span {
          font-family: 'Montserrat';
          color: #252525;
          font-size: 16px;
        }
      }
    }
  }
}
.icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &_timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &_status {
    margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 45px;
    .container {
      width: unset;
    }
    .status-text {
      font-size: 12px;
      font-weight: 500;
      padding: 10px;
      color: #fff;
    }
  }
}
.previewContainer {
  width: 100%;
  flex: 1;
  transition: none;
  padding-top: 20px;
  &-show {
    transition: none;
    flex: 1;
    width: 600px;
    padding-top: 20px;
  }
}
.orders__modal__icon__and__name {
  font-family: 'Montserrat';
  display: flex;
  width: 100%;
  padding: 0px 20px 0 20px;
  justify-content: space-between;
  align-items: center;
  color: $primary;

  span {
    font-weight: 600;
    color: $primary;
    font-size: 16px;
  }
  span {
    svg {
      height: 20px;
      width: 20px;
      & > * {
        fill: $primary;
      }
    }
  }

  .close__icon__div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .close__modal__icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    // align-self: center;
  }
}

.editOrder__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  // gap: 20px;
  padding: 20px;
}

.editOrder__container-sections {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 17px;

  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: unset !important;
  }
}

.edit-order-input-dropdown {
  width: 49%;
  color: #d9d9d9 !important;

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-endAdornment {
    margin-top: 5px;
    right: 20px !important;
  }

  .MuiInputBase-root fieldset {
    border: 1px solid #d9d9d9 !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    border-radius: 5px;
    // padding: 15px;
  }
}

.order_modals {
  height: calc(100vh - 146px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 6px;
  }
}

.order-edit-input {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: unset !important;

  input {
    height: 40px;
    margin: unset !important;
    // padding: unset !important;
  }
}

.order-edit-button-div {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
}

.order-edit-button {
  width: 50% !important;
  display: flex !important;
  justify-content: center !important;
}

.previewOrder__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: 'Montserrat';
  align-items: center;

  // gap: 20px;
  padding: 20px;
  // overflow: scroll;
  // height: calc(100vh - 66px);
  &-sectionWrapper {
    width: 100%;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
}

// .previewOrder__container-sections{
//       width: 100%;
//       // border: 1px solid #D9D9D9;
//       // border-radius: 8px;
//       display: flex;
//       flex-wrap: wrap;

//       &-keyValue {
//        width: 75%;
//        display: flex;
//        justify-content: space-between;
//        padding: 10px 0;
//        border-bottom: 1px solid #ddd;

//        &-key {
//         width: 30%;
//         text-align: left;
//         font-size: 13px;
//         font-family: "Montserrat";
//         color: #979797;
//      }
//        &-value {
//          width: 70%;
//           font-family: "Montserrat";
//           overflow-wrap: anywhere;
//           font-size: 13px;
//           font-weight: 600;
//        }
//      }
//       .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon{
//         margin-bottom: unset !important;
//       }

// }

.previewOrder__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  padding: 20px;
}

.previewOrder__container-sections {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.previewOrder__container-sectionColumn {
  width: 48%; /* Adjust width as necessary */
  box-sizing: border-box;
}

.previewOrder__container-sections-keyValue {
  width: 100%;
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  gap: 20px;
}

.previewOrder__container-sections-keyValue-key {
  width: 35%;
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  font-family: 'Montserrat';
  color: #979797;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.previewOrder__container-sections-keyValue-value {
  width: 60%;
  font-family: 'Montserrat';
  overflow-wrap: anywhere;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.signatureOnEdit {
  width: 100%;
}

.signatureOnEditDiv {
  border: 1px dashed #d9d9d9;
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
}

.signatureImage {
  width: 250px;
  height: 60px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
    height: 50px;
  }
}

.addPrice__container {
  width: 100%;
  height: auto;
  padding: 20px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_closeIcon {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $primary;
  }

  &_inputs {
    &-textArea {
      textarea {
        width: 100%;
        height: 230px;
        display: flex;
        flex-direction: column;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 10px;
      }
    }
  }
  &_buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    &-button {
      width: 100% !important;
      height: 50px !important;
      span {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.addPrice__textArea {
  height: 183px;
  border: 1px solid #d8d2d2;
  border-radius: 8px;
}
.uploads__wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .image__and__imageName {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    .uploaded__image__info {
      &-upload {
        display: flex;
        gap: 10px;
      }
    }
    .uploaded__image__info-name {
      color: #979797;
    }
  }
}
