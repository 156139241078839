@import "../abstracts/variables";

.popup_add_edit_user {
  width: 417px;
  min-height: 100%;
  padding: 20px;
  border: solid 1px #ececec;
  background-color: #fff;
  font-family: "Montserrat";
  .modal-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: calc(100vh - 45px);
      .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: $primary;
        font-size: 16px;
        font-weight: 600;
      }
  }
  .loading{
    height: 500px;
  }
  &.paddingLess {
    padding: 0px;
    box-shadow: 2px 4px 4px 0px #00000040;
    border-radius: 8px;
    .ant-popover-inner {
      border-radius: 8px;
      &-content {
        width: 100%;
        padding: 20px;
      }
    }
  }

  .buttonsWrapper{
    display: flex;
    gap: 20px;
    align-items: center;
    .createuserButton:first-of-type {
      background-color: #fff;
      border: 1px solid #979797 !important;
      color: #979797 !important;
    }
    & > * {
      padding: 24px 0px;
      width: 50% !important;
      border: 1px solid $primary !important;
      font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 19.5px;
text-align: left;

    }
  }
  .textarea_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    padding: 15px 0;
    &-bottom{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .textarea__input {
      display: flex;
      flex-direction: column;
      width: 60%;
      font-family: 'Montserrat';
      textarea {
        width: 100%;
        background-color: #fff;
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        margin-top: 5px;
        resize: none;
        height: 200px;
        padding: 5px;
        color: rgba(37, 37, 37, 0.5);
      }
      .textarea_max_length {
        color: #ddd;
        font-size: 14px;
        align-self: flex-end;
        margin: 5px 0;
      }
    }
  }

  .close__modal__icon__and__name {
    font-family: "Montserrat";
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
      span {
        svg {
            height: 16px;
            width: 16px;
            & > * {
                fill: $primary;
            }
        }
    }

    .close__icon__div {
      width: 26px;
      height: 26px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .close__modal__icon {
      cursor: pointer;
      align-self: center;
    }
 
  }

  .datepicker__input__and__error {
    border: none !important;
    min-height : 51.69px;
    div {
      height : 51.69px;
      margin : 9px 0 ;
      input {
        height : 51.69px !important;
      }
    }
    

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
      top: 3px;
      left: 8px;
      background-color: white;
      padding: 0 5px !important;
    }

    label {
      font-size: 13px;
    }

    label + .MuiInput-formControl {
      margin-top: 10px;
    }
  }

  .edit__and__create__form {
    & .modal__cancel__confirm__buttons {
      display: flex;
      justify-content: space-between;
      & .primaryButton {
        width: 48%;
        padding: 0;
        border-radius: 8px;
        &.cancel {
          width: 100%;
          background-color: white;
          border: 1px solid #cc0000;
          color: #cc0000;
          &:hover {
            background-color: $secondaryhover;
          }
        }
      }
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
      top: -11px;
      left: -16px;
      font-size: 16px;
      position: absolute;
      font-family: "Montserrat";
      color: #252525;
      font-weight: 600;
  }
    .MuiOutlinedInput-notchedOutline{
      legend{
       max-width: 0px !important;
     }
    }
    .MuiAutocomplete-endAdornment{
      right: 20px !important;
      top: 10px;
    }
    .AutocompleteCl {
      .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
        height: 20px;
      }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #d9d9d9 !important;
      border-width: 1px;
      border-radius: 8px;
    }
    .MuiOutlinedInput-root:hover {
        border: none !important;
    }

    & .forget__password__modal__text {
      margin-top: 15px;
      margin-bottom: 10px;
      margin-left: 5px;
      color: #7d8c96;
      font-size: 13px;
    }

    //Remove input type number up and down icons
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // -||- Firefox
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .inline__errors {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      margin-bottom: 15px;
    }

    .value__and__currency {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .input__value {
      width: 300px;
    }

    .dropown_currency {
      .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
        font-size: 13px;
      }

      & .dropdown-option {
        &
          .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
          font-size: 13px;
        }

        height: 40px;
        min-width: 130px;
        font-size: 10px;
      }
      margin-bottom: -5px;
    }

    .dropdown-commission {
      margin-bottom: 0 !important;
      padding: 5px 0;
    }

    & h5 {
      margin-bottom: 5px;
      margin-left: 4px;
    }
    & h1 {
      // width: 112px;
      height: 29px;
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: $primary;
      margin: 0;
    }

    & div {
       margin : 4px 0;
      &.form_input {
        & input {
          height: 51.69px;
          margin: 3px 0 1px 1px;
          padding: 15px;
          border: solid 1px #d9d9d9;
          background-color: #fff;
        }
        & span {
          margin-bottom: 3px;
          
        }
      }
    }
    & div > label  {
      margin-left: 0px;
      & span {
        font-family: "Montserrat";
      }
    }
    &-custom_input {
      padding: 0;
      .form_input__label {
        color: #252525 !important;
        margin-bottom: -4px !important;
      }
      input {
        border: 1px solid #D9D9D9 !important;
        border-radius: 8px !important;
        padding: 0;
        &::placeholder {
          color: #C1C1C1;
          font-size: 14px;
          font-weight: 500;
        }
        &:disabled {
          background-color: #f5f5f5 !important;
        }
      }
    }
  }
  & .primaryButton {
    width: 100%;
    height: 46px;
    // padding: 14px 155px 14px 156px;
    // background-color: #1e8659;
    background: $primary;
    border-radius: 8px;
    color: white;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .orderInput {
    margin-bottom: 10px;
  }
}
.accordion__container {
  height: 50px;
  display: grid;
  align-items: center;
  width: 100%;
  border: solid 2px #f5f5f5;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
  &-child{
    width: 90%;
    position: relative;
    float: right;
    margin-top: 0;
    height: 50px;
    border: solid 2px #f5f5f5;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.user__accordion__dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .date{
    color: $primary;
    padding: 0;
    font-size: 11px;
    font-weight: 500;
  }
  & .user__accordion__element {
    display: flex;
    align-items: center;
    & .user__accordion__star__and__text {
      display: flex;
      align-items: center;

      & p {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #7d8c96;
        margin-right: 5px;
        margin-top: 2px;
      }

      & img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
  }
  span {
    // margin-top: 3px;
    padding-right: 10px;
    color: #0E88F2;
    cursor: pointer;
  }
}

.accordion__container__menu {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.team_modal_user_accordion {
  padding: 10px;
  padding-left: 5px;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.team_modal_user_accordion:hover {
  background-color: $secondaryhover;
}

.add_extra__product__fields {
  margin-top: 5px;
  background-color: #fbfafb;
  padding: 5px;
  border-radius: 10px;
}

.tariff__modal__icons__remove {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  color: #f73d47;
  cursor: pointer;
}

.tariff__modal__remove__icon {
  width: 15px;
  fill: #f73d47;
}

.tariff__modal__icons__add {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #008a57;
  cursor: pointer;
}
.tariff__modal__add__icon {
  width: 15px;
  fill: #008a57;
}

.input__file__component {
  display: flex;
  justify-content: center;
  align-items: center;
  & input {
    display: none;
  }
  & .change__profile__picture {
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 600;
    color: $primary;
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
  }
}

.add__tariff__fields {
  float: right;
  &.hidden {
    content-visibility: hidden;
  }
}

.close__icon__div {
  cursor: pointer;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
  top: 1px;
  position: absolute;
}

.dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused.Mui-focused {
  top: -5px;
  position: absolute;
  font-size: 16px !important;
}
.crate__user__side__drawer .dropdown-input  .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth label {
  left: -11px;
}
.crate__user__side__drawer .PrivateNotchedOutline-legendNotched-560 {
  max-width: unset !important;
  width: 0px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  top: 12px;
  left: 0px;
  position: absolute;
  color: #252525;
  font-weight: 600;
}
.form__button__container{
  position: absolute;
  bottom: 30px;
  width: 90% !important;
  margin-top: 30px;
}

@media (max-width: 900px) {
  .popup_add_edit_user{
    width: 600px !important;
  }
}
@media (max-width: 600px) {
  .popup_add_edit_user{
    width: 400px !important;
  }
}


