@import "../../../abstracts/variables";

.clientPage {
  height: 100%;
  .tab-bar{
      background-color: white;
      height: 5px;
      width: 50%;
      border-radius: 10px 10px 0px 0px;
      min-width: 50px;
    &-active{
      background: linear-gradient(94.31deg, $primary 0%, #881686 22.84%, #5B0963 43.56%, #1C4CCF 66.94%, #0E88F2 90.85%);
      height: 5px;
      width: 50%;
      border-radius: 10px 10px 0px 0px;
      min-width: 50px;
    }
  }
  &__client {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    color: #3b505d;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: flex-start;
    }

    .big__search__bar__container {
      @include respond(tab-port) {
        margin: 0;
        margin-top: 20px;
      }
    }

    &-name {
      color: #008955;
    }

    &-right {
      @include respond(tab-port) {
        display: block;
        margin-left: auto;
      }

      .dashboard__left__text {
        @include respond(tab-port) {
          padding: 0;
        }

        .dashboard__left__text__create__user__h1 {
          @include respond(tab-port) {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  &__tabs {
    display: flex;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    border-radius: 8px;
    width: auto;
    color: #1C4CCF;
    height: 36px;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    &-wrapp{
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-right: 1px solid #EFEFEF;
      min-width: 100px;
    }
    @media (max-width: 1300px) {
        font-size: 11px;
    }

    @include respond(tab-port) {
      overflow-x: auto;
      // overflow-y: hidden;
      white-space: nowrap;
      flex-wrap: wrap;
    }

    @include respond(phone) {
      overflow-x: hidden;
    }
  }

  &__tab {
    list-style-type: none;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    
    @include respond(tab-port) {
      margin-right: 15px;
    }

    @include respond(phone) {
      width: 45%;
    }
  }

  .clientTab-active {
    border-radius: 5px;
    transition: all ease 0.1s;
    height: 100%;

  }

  &__table  {
    margin-top: 6px;
    .loader__and__table {
      & .rdg-light {
        font-family: "Montserrat";
        & .empty-rows-view {
          color: #8c8c8c;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .clientPage__table {
      & .rdg-light {
        font-family: "Montserrat";
        & .empty-rows-view {
          color: #8c8c8c;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.clientList-text1{
  font-size: 14px;
  color: $primary !important;
  font-weight: 700;
  font-family: "Montserrat";
  padding-top: 10px;
  // margin-left: -5px;
}

.calculation__details__panel {
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  &.notLast {
    border-bottom: 1px solid rgb(230, 230, 230);
  }

  & .calculation__details__panel__single__div {
    width: 30%;
    & h1 {
      margin-left: 60px;
      font-family: "Montserrat";
      font-size: 13px;
      font-weight: 500;
      line-height: 2.31;
      color: #26353e;
      min-width: 150px;
    }
  }
}
.calculation__details__panel__bonuses-br {
  border-top: 1px solid #b1b1b1;
  background-color: #ebebeb;
}
.calculation__details__panel__bonuses {
  background-color: #ebebeb;
}

.Toastify {
  z-index: 99999999;
}

.clientList {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  color: $secondary;
  margin-left: 3px;
  margin-bottom: 6px;

  &-text1 {
    cursor: pointer;
    color: $primary;
  }

  &-text2 {
    color: $secondary;
    cursor: text;
  }
}
