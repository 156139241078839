.notificationBoxWrapper{
    background: rgb(255, 255, 255);
    width: 100%;
    height: 90px;
    border-radius: 12px;
    padding: 10px 10px;
    min-height: 90px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &_iconWrapper{
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        min-height: 70px;
        max-height: 70px;
        height: 100%;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &_contentWrapper{
        width: 100%;
        height: 100%;
        border-radius: 12px;
        display: flex;
        font-family: "Montserrat";
        justify-content: space-between;
        &_leftSide{
            display: flex;
            flex-direction: column;
            .name{
                font-size: 14px;
                font-weight: 600;
            }
            .description{
                font-size: 12px;
                font-weight: 500;
                overflow: scroll;
            }
        }
        &_rightSide{
            display: flex;
            align-items: center;
            justify-content: center;
            .date{
                color: #979797;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }
}