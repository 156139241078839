@import "../../abstracts/variables";

//Merkmale Index File
.merkmale__container{
    width: 540px;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    box-shadow: 3px 4px 12px 0px rgba(0, 0, 0, 0.15);
    font-family: "Montserrat";

    &--header{
        padding: 20px;
    }

    &--calculation{
        padding: 20px;
    }

    &--thirdSection{
        padding: 20px;
    }

    &--tabs{
        padding: 20px;
    }

}

//Merkmale Header
.merkmaleHeader__container{
    display: flex;
    gap: 20px;

    &--number{
        display: flex;
        width: 40px;
        height: 40px;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #E8E8E8;
        background: #FFF;

        span{
            color: #252525;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    &--title{
        display: flex;
        flex-direction: column;

        &-up{
            color: #252525;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        &-down{
            color: #979797;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    &--bottomBorder{
        width: 100%;
        border-bottom: 1px solid #E8E8E8;
        // margin: 20px 0 20px 0;
    }

    &--shortBottomBorder{
        width: 90%;
        border-bottom: 1px solid #E8E8E8;
        display: flex;
        margin-left: 25px;
    }
}

//Merkmale Calculation
.merkmaleCalculation__superContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--bottomText{
        color: #979797;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
   
}

.merkmaleCalculation__container{
    display: flex;
    justify-content: space-between;

    &--line{
        width: 1px;
        height: 50px;
        border-right: 2px solid #E8E8E8;
    }

    &--first{
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        &-text{
            color: #252525;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &-number{
            color: #252525;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        
    }
    &--second{
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        &-text{
            color: #979797;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &-number{
            color: #979797;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    &--third{
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        &-text{
            color: #979797;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &-number{
            color: #979797;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            span{
                color: #979797;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}

.merkmaleSection__container{
    display: flex;
    flex-direction: column;
    gap: 20px;

    

    &--title{
        color: #252525;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        // margin-left: 16px;
    }

    &--sections{
        display: flex;
        // justify-content: space-between;
        &-firstwithline{
            display: flex;
            gap: 63px;

            &-first{
                display: flex;
                flex-direction: column;
                color: #252525;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                gap: 10px;
    
            }
        }

        &-second{
            display: flex;
            flex-direction: column;
            color: #252525;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            gap: 10px;
            margin-left: 20px;
        }
    }

    &--line{
        width: 1px;
        height: 72px;
        border-right: 2px solid #E8E8E8;
    }

}

//InfoComponent
.infosEnergie__container{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    color: #252525;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    padding: 20px;

    &--first{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    &--second{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    &--third{
        display: flex;
        justify-content: center;
        // align-items: center;
        gap: 10px;
    }
    &--fourth{
        display: flex;
        justify-content: center;
        // align-items: center;
        gap: 10px;
    }
}

//Markmale Buttons

.markmaleButtons__container{
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    align-items: center;
    gap: 13px;
    margin: 20px 0;
    font-size: 13px;
}
.markmaleButton__first , .markmaleButton__second {
    background: #FFF;
    display: inline-flex;
    height: 50px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 55px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid $primary;
    color: $primary;
    font-weight: 600;
}
.markmaleButton__third{
    display: inline-flex;
    height: 50px;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 55px;
    flex-shrink: 0;
    border-radius: 8px;
    background: $primary;
    font-weight: 600;
    color: #FFF;
}

.tabs__style .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #252525;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.tabs__style .ant-tabs-tab-btn, .ant-tabs-tab-remove{
    color: $primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active{
    border-radius: 8px 8px 0px 0px !important;
    border: 1px solid #f0f0f0 !important;
    background: #f5f5f5 !important;
}
.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab{
    border-radius: 8px 8px 0px 0px !important;
   
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab{
    background: #ffff !important;
    // border: none !important;ho
}
// .ant-tabs-tab{
//     border: 1px solid transparent !important;
//     border-bottom: 1px solid transparent !important;   
// }
