.singleTraining {
  width: 100%;
  height: 100%;
  font-family: "Montserrat";
  padding: 30px;

  &__container {
    position: relative;
  }

  &__content {
    width: 85%;
    height: 100%;
    padding-bottom: 80px;
    @include respond(tab-port) {
      width: 100%;
      padding-right: 5px;
    }
  }
}

.trainingTop__backBtn{
  font-size: 13px;
  font-weight: bold;
  color: #0E88F2;
  cursor: pointer;
  margin-bottom: 15px;
}

.trainingTop {
  &__links {
    font-size: 13px;
    font-weight: 500;
    color: #3b505d;
  }

  &__stepsContent {
    margin-top: 30px;

    &-title {
      font-size: 13px;
      font-weight: normal;
      color: #3b3b3b;
    }

    &-steps {
      display: flex;
      margin-top: 5px;
    }

    &-step {
      width: 50px;
      height: 5px;
      margin-right: 2px;
      background-color: #eaeaea;

      &--active {
        background-color: #0E88F2;
      }
    }
  }
}

.training__page__container{
  padding: 15px 0;

  & h1{
    margin-bottom: 20px;
  }

  img {
    max-width: 100% !important;
  }
}

.rightPages {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #eee;
  padding: 30px 15px;
  min-height: 100vh;

  @include respond(tab-port) {
   display: none;
  }

  &__page {
    width: 100px;
    height: 110px;
    background-color: white;
    border: 2px solid white;
    margin-bottom: 12px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #7d8c96;
    cursor: pointer;

    &--active {
      border: 2px solid #0E88F2;
    }
  }
}

.footerTraining {
  height: 95px;
  display: flex;
  width : 100%;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  padding: 0 20px;
  border-top: 1px solid #eee;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }

  &__btn {
    font-size: 13px;
    font-weight: bold;
    color: #7a7a7a;
    margin-bottom: 5px;
    cursor: pointer;
  }

  &__next {
    color: #0E88F2;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #26353e;
  }
}
