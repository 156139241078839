.activity__box__container {
    display: flex;
    gap: 10px;
    padding: 20px 20px 10px 20px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    &--content {
        display: flex;
        flex-direction: column;
        font-family: "Montserrat";
        &-top {
            color: #252525;
            font-size: 14px;
            padding-bottom: 20px;
        }
        &-middle , &-bottom {
            color: #979797;
            font-size: 12px;
            padding-bottom: 10px;
            & span {
              font-weight: 600;
            }
        }
    }
}