.filters__component {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 350px;
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        display: flex;
        &-icon {
            cursor: pointer;
            svg {
                & > * {
                    fill: $primary;
                }
            }
        }
        &-title{
            font-size: 16px;
            color: $primary;
            font-weight: 600;
            font-family: "Montserrat";
        }
    }
    &-actions {
        display: flex;
        justify-content: space-between;
        & .primaryButton {
           width: 100%;
           border: 1px solid $primary;
           background-color: #fff;
           color: $primary;
           padding: 7px 20px;
           font-family: "Montserrat";
           height: 45px;
           border-radius: 8px;
           font-weight: 600;
           &:hover{
            background-color: $primary;
            color: #fff;
           }
        }
    }
    &-inputs {
        max-width: 350px;
        max-height: 400px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0px;
        }
        &-autocomplete , &-rangePicker , &-datepicker {
            .MuiAutocomplete-root {
                margin-bottom: 0;
            }
            .MuiAutocomplete-endAdornment{
                padding-right: 10px;
                top: 12px;
            }
            fieldset {
                border: none;
            }
            .ant-picker {
                width: 100%;
                height: 40px;
                border: 1px solid #D9D9D9;
                border-radius: 5px;
            }
            .ant-picker-input input::placeholder {
                color: #979797;
            }
            .ant-picker-suffix{
                color: #979797 !important;
                margin-right: 6px;
            }
            p {
                font-family: "Montserrat";
                font-size: 14px;
                font-weight: 600;
                color: #252525;
                margin-bottom: 5px !important;
            }
            .options-wrapper {
                min-height: 100px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid #D9D9D9;
                margin-top: 20px;
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                align-items: flex-start;
                padding: 10px;
                &-no_data {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100px;
                    span {
                        color: #D9D9D9;
                        font-weight: 600;
                        font-family: "Montserrat";
                        font-size: 14px;
                    }
                }
                &-item {
                    background-color: #E9E9E9;
                    padding: 5px 10px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    &-text {
                        font-size: 14px;
                        font-family: "Montserrat";
                        color: #252525;
                        font-weight: 600;
                    }
                    &-clearIcon {
                        border-radius: 50%;
                        padding: 7px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #A8A8A8;
                        cursor: pointer;
                    }
                }
            }
            .date-picker-with-types {
                height: 40px;
                border-radius: 5px;
                border: 1px solid #D9D9D9;
                &:hover{
                transition: all .3s cubic-bezier(.645,.045,.355,1);
                border-color: #40a9ff;
                border-right-width: 1px;
                }
                &__input {
                    height: 100%;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                }
            }
        }
    }
}