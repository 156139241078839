.button-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-title {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        color: #252525;
    }

    &-options {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;

        .single-option {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 10px;
            border-radius: 8px;
            cursor: pointer;

            .radio-wrapper {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid;

                & .checked {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }
            span {
                font-family: "Montserrat";
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}