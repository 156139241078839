@import "../../../abstracts/variables";

.create__tariff__page {
  background-color: #f5f5f5;
  overflow: auto;
  height: 100%;

  .input-required{
    position: absolute;
  }

  @media screen and (max-width: 500px) {
    margin-left: 0;
  }

  .editable-formatter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & .editable-formatter-header {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      & .editable-formatter-header-element {
        display: flex;
        align-items: center;
        height: 35px;

        &.first {
          padding-top: 10px;
          letter-spacing: 0.5px;
          opacity: 0.5;
          height: 25px;
        }
      }
    }

    &-breakText {
      overflow: hidden;
    }

    & .editable-formatter-header-element-gross {
      position: relative;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      & span {
        height: 43px;
        position: absolute;
        left: 3px;
        bottom: 3px;
      }
    }
    &-first {
      width: 50%;
      border-right: 1px solid #ccc;
      margin-right: 10px;
    }
  }
  .commission-formatter {
    font-weight: bold;
    text-align: center;
  }
  .commission-formatter-border {
    border-right: 1px solid #ddd;
    margin-right: -8px;
  }

    .back__button__create__user{
      display: flex;
      align-items: center;
      gap: 10px;
    }

  & .create__tariff__page__top {
    width: 100%;
    display: flex;
    height: 60px;
    background-color: #fff;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    .create__tariff__actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 12px;
      .create__tariff__actions-addTable {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        height: 46px;
        gap: 4px;
        background: $primary;;
        border-radius: 3px;
        cursor: pointer;
        color: white;
        font-family: "Montserrat";
        width: 200px;
      }
    }
    & .create__tariff__page__top__site__map {
      font-family: "Montserrat";
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 4.62;
      letter-spacing: normal;
      color: #3b505d;

      & .creaete__tarif__active {
        background: $primary;
      }
    }
    & .create__tariff__tab__container {
      display: flex;
      max-width: 190px;
    }

    & .create__taroff__tab__save__button {
      width: 125px;
      height: 46px;
      background: $primary;;
      color: white;
    }
  }

  & .create__tariff__inputs {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;

    & > * {
      margin-right: 10px;
    }

    & .form_input {
      flex: 1;
      & input {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        
        &:focus-visible {
          outline: none !important;
        }
      }
    }
    .MuiAutocomplete-endAdornment{
      margin-right: 15px;
    }

    &__tariffReserveTypeContent {
      flex: 1;
      padding: 5px 0;
      .form_input__label {
        font-family: "Montserrat";
        font-size: 13px;
        margin-left: 1px;
        margin-bottom: 5px !important;
        color: #252525;
        .MuiInputBase-root{
          padding: 3px !important;
        }
      }

      .MuiAutocomplete-root
        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
        .MuiAutocomplete-input {
        padding: 9.5px 4px;
        font-family: "Montserrat";
        height: 16px;
      }
    }

    &__tariffReserveType {
      margin-bottom: 0;
      background-color: white;
      border: 1px solid #d9d9d9 !important;
      border-radius: 8px;
      fieldset {
        display: none;
      }

      label.Mui-focused {
        display: none;
      }
      label.MuiFormLabel-filled {
        display: none;
      }
    }
  }

  & .create__tariff__accordion {
    font-family: "Montserrat";
    margin-top: 20px;
    margin-right: 30px;
    margin-left: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    .MuiCollapse-root{
      border-radius: 8px;
      box-shadow: 2px 3px 4px 0px #00000026 ;
    }
    & .create__tariff__single__accordion {
      & .header {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: "Montserrat";
        flex-wrap: wrap;
      }
      .MuiCollapse-wrapper{
        padding: 0 20px 20px 20px;
      }
      &.locations {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        width: auto;
        justify-self: flex-start;
        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }
      &.icons{
        display: flex;
        gap: 10px;
        align-items: center;
        img{
          @media screen and (max-width: 1300px) {
            width: 20px;
          }
        }
      }
      &.items {
        color: #0E88F2;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 7px;
        font-weight: 500;
      }
      &.dates {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 180px;
        color: #0E88F2;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        @media screen and (max-width: 1300px) {
          font-size: 12px;
        }
      }
      .rdg-light {
        height: 414px;
      }
      &.receivedCommission {
        background-color: #6C1EB04D;
        padding: 3px 10px;
        border-radius: 5px;
        border: 1px solid #6C1EB0B2;
        margin-left: 20px;
        margin-right: 20px;
        span {
          font-family: "Montserrat";
          color: #252525;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    .MuiButtonBase-root {
      width: 100%;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0;
      border: 0;
      margin: 0;
      border-radius: 0;
      padding: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      vertical-align: middle;
      -moz-appearance: none;
      -webkit-appearance: none;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: inherit;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      min-height: 48px;
      padding: 0px 16px !important;
      -webkit-transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
}

.data__grid__row__agency {
  background-color: #d8eccb55 !important;
}
.data__grid__row__team {
  background-color: #f7dfc245;
}

.data__grid__row__child {
  background-color: #f6f6f6;
  & .c1wupbe700-canary49 {
    padding-left: 25px;
  }
}

.rdg.r104f42s700-canary49 {
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 7px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    background: linear-gradient(119.24deg, #98159675 0%rgba(28, 76, 207, 0.404)CF 100%);
  }
  &::-webkit-scrollbar-thumb {
    // background: #1C4CCF;
    background: $primary;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    // background: rgb(32, 167, 108);
    background: $primary;

  }
}

.rdg-text-editor {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 6px 0 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  font-family: inherit;
  -webkit-appearance: none;
  z-index: 9999999;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #999;
    opacity: 1;
  }
}
