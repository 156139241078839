
.component__rounds__container{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .component__round_css{
        width: 63px;
        height: 63px;
        border-radius: 100%;
    
    
        &.pending{
            background-color: $primary;
        }
    
        &.approved{
            background-color: #0E88F2;
        }

        &.delivered {
            background-color: #5B0963;
        }
    
        &.paid{
            background-color: #60C7AB;
        }
    }

    .component__round_css:hover {
        cursor: pointer;
    }

    .user__profile__status__tabs__img {
        margin-top: 20px;
    }

    .component__rounds__number{
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: bold;
        padding: 7px;
        color: #3b505d;
    }
    .component__rounds__name{
        font-family: "Montserrat";
        font-size: 13px;
        font-weight: 500;
        color: #99a4ac;
    }
}
