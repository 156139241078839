.userstab-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  gap: 15px;
  width: 100%;
}
.customdropdown__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    &.salesOrg__select{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .options-wrapper {
      max-height: 150px;
      overflow: auto;
      width: 100%;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      margin-top: 20px;
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 10px;
      &-no_data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        span {
          color: #d9d9d9;
          font-weight: 600;
          font-family: 'Montserrat';
          font-size: 14px;
        }
      }
      &-item {
        background-color: #e9e9e9;
        padding: 7px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        gap: 10px;
        &-text {
          font-size: 14px;
          font-family: 'Montserrat';
          color: #252525;
          font-weight: 600;
        }
        &-clearIcon {
          border-radius: 50%;
          padding: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #a8a8a8;
          cursor: pointer;
        }
      }
    }
    &-label{
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
      color: #252525;
      margin-bottom: 5px !important;
  }
    .select__title {
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 600;
        color: #252525;
        margin-bottom: 5px;
      }
      .ant-select-selection-placeholder{
        display: flex;
        align-items: center;
      }
      .ant-select {
        width: 100%;
        .ant-select-selection-item {
          height: 40px;
          display: flex;
          align-items: center;
          font-family: 'Montserrat';
          
        }
        .ant-select-item{
            color: #979797;
            font-weight: 600;
            font-family: "Montserrat";
            font-size: 14px;
          }
        .ant-select-selection-search {
          input {
            height: 40px;
            display: flex;
            align-items: center;
            font-family: 'Montserrat';
          }
        }
        .ant-select-selection-overflow {
          max-height: 40px;
          .ant-select-selection-overflow-item {
            max-height: 45px;
            .ant-select-selection-item {
              max-height: 40px;
              border-radius: 5px;
            }
          }
        }
        .ant-select-selector {
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          min-height: 40px;
          max-height: fit-content !important; 
          input{
              height: 40px !important;
          }
        }
        .ant-select-multiple {
          min-height: 40px;
          max-height: fit-content !important; 
          .ant-select-selector {
            height: fit-content;
            .ant-select-selection-overflow {
              max-height: fit-content !important;
              min-height: fit-content !important;
            }
          }
        }
      }
      .custom-render-wrapper{
        width: 100%;
        .selected__teamMember {
          font-family: 'Montserrat';
          margin-bottom: 5px;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          min-height: 40px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 11px;
          height: fit-content;
          .name-wrapper{
            display: flex;
            flex-direction: column;
          }
          .name{
            color: #979797;
            font-size: 14px;
            font-weight: 600;
          }
          .date{
            font-family: 'Montserrat';
            color: #979797;
            font-size: 12px;
            font-weight: 500;
          }
          .role{
            display: flex;
            align-items: center;
            gap: 10px;
            color: #979797;
            &-head{
              color: #31B073;
              font-size: 13px;
              font-weight: 600;
            }
            &-name{
              font-size: 12px;
              font-weight: 600;
            }
          }
          .menu-icon{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px 10px 10px;
          }
        }
      }
      &.relative {
        width: calc(100% - 40px) !important;
        position: relative;
        .ant-select {
          &-selector {
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
              border-right: none !important;
          }
        }
        .info__toggler {
          position: absolute;
          bottom: 0;
          top: auto;
          right: -40px;
          left: auto;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #979797;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;
        }
        &.multiple {
          .info__toggler {
            top: 23px !important;
            bottom: auto;
          }
        }
       }
}
.ant-select {
    width: 100%;
    display: flex;
    flex-direction: column;
      &-item{
        color: #979797;
        font-weight: 600;
        font-family: "Montserrat";
        font-size: 14px;
      }
      &-selector {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        input{
            height: 40px !important;
        }
      }
  }
  .ant-select-multiple {
    min-height: 40px;
    max-height: fit-content !important;
    .ant-select-selector {
      height: fit-content;
      .ant-select-selection-overflow {
        max-height: fit-content;
        min-height: fit-content;
      }
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    margin-bottom: 3px;
    margin-top: 0;
    height: 34px !important;
    max-height: 34px !important;
  }