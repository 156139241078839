.info__bar__container{
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding:0 41px 0 26px;
    border-bottom: 1px solid #f5f5f5;
    align-items: center;

    .info__bar__day{
        width:22%;
        font-size: 12px;
    }
    .info__bar__day, & h2{
        font-family: "Montserrat";
  
        font-weight: 600;
        color: #26353e;
    }
    & h2{
        font-size: 11px;
        width: 10%;

    }
}