@import "../../abstracts/variables";

.create-pdf__back {
  display: flex;
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.create-pdf__header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: Montserrat;
  &-path {
    margin-left: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    div {
      display: flex;
      height: 100%;
      align-items: center;
      margin-left: 5px;
    }
  }
  &-buttons {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    &-button {
      gap: 5px;
      width: 118px;
      height: 35px;
      border-radius: 2px;
      background: linear-gradient(119.24deg, #981596 0%, #1c4ccf 100%);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
.create-pdf__wrapper {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-top: 13px;
  padding: 30px;
  .draggableContainer {
    width: 100%;
    height: calc(100vh - 150px);
    background-color: white;
    box-shadow: 0 4px 7px rgba(75, 77, 78, 0.08);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    border-radius: 8px;
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;
      margin-bottom: 10px;
      &.absolute {
        display: unset;
        position: absolute;
      }
    }
    &-inputfield {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-left: 8px;
      color: #87898e;
      align-items: center;
      display: flex;
      gap: 15px;
    }
    .order-input-dropdown {
      width: 100%;
      margin-left: 8px;
    }
    input {
      border: none;
      font-size: 14px;
      color: #87898e;
      font-weight: 500;
      font-family: "Montserrat";
    }
    .form_input__label {
      display: none;
    }
    &-search {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      .big__search__bar__container {
        flex: 1;
        input {
          font-size: 12px;
        }
      }
    }
    &-header {
      width: 100%;
      background-color: white;
      height: 50px;
      min-height: 50px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      position: sticky;
      inset: 0 0 0 0;
      &-text {
        color: #3b505d;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #252525;
        &.active {
          border-bottom: 2px solid #981596;
        }
      }
      &-button {
        color: #1c4ccf;
        font-size: 14px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }
    }
    // overflow-x: scroll;
    .order-input {
      background-color: none !important;
      width: 100%;
      .form_input__label {
        max-width: 95%;
      }
      input {
        background-color: none !important;
      }
    }
  }
  &-right {
    width: calc(75% - 20px);
    max-width: calc(75% - 20px);
    min-width: calc(75% - 20px);
    display: flex;
    align-items: center;
    height: calc(100vh - 150px);
    background-color: #f5f5f5;
    flex-direction: column;
    box-shadow: 2px 4px 12px 0px #00000014;
    border-radius: 8px;
    &-header {
      width: 100%;
      background-color: white;
      height: 50px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      align-items: center;
      border-radius: 8px 8px 0 0;
     &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      font-family: 'Montserrat';
      width: 100%;
      font-size: 14px;
      &-buttons {
        display: flex;
        align-items: center;
        gap: 20px;
        & > * {
          cursor: pointer;
        }
      }
      p {
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 600;
        color: #252525;
      }
     }
    }
    &-body {
      background: #f5f5f5;
      width: 100%;
      display: flex;
      align-items: center;
      height: auto;
      justify-content: center;
      flex-direction: column;
      &-createorder {
        background-color: white;
        align-items: flex-start;
        gap: 20px;
        padding: 20px;
        width: 100%;
        display: flex;
        height: 100%;
        border-radius: 0 0 8px 8px;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100%;
        .section_container {
          max-width: 400px;
          min-width: 400px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          border: 1px solid #C1C1C1;
          padding: 10px;
          border-radius: 8px;
          min-height: 100%;
          height: 100%;
          &-header {
            width: 100%;
            &-icons {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                cursor: pointer;
              }
              .icons__wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                & > * {
                  cursor: pointer;
                }
              }
            }
            &-name {
              margin: 10px 0;
              border: 1px solid #979797;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              padding: 10px;
              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: "Montserrat";
                font-size: 14px;
                font-weight: 600;
                color: #252525;
              }
            }
          }
          &-items {
            flex: 1;
            overflow-y: auto;
          }
        }
      }
    }
  }
 &__tabs {
  min-width: fit-content;
  .client-settings__tabs {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    border-right: 1px solid #CECECE;
    height: calc(100vh - 160px);
    .tab {
      padding: 10px 20px;
      background-color: #fff;
      span {
         color: $primary;
         font-weight: 600;
         padding: 10px 20px;
         display: flex;
         font-family: "Montserrat";
         background-color: #fff;
         cursor: pointer;
      }
      &.active {
        span {
          border-radius: 5px;
          background: $primary;
          color: #fff;
        }
      }
      &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
 }
}

.container-sortable {
  width: 40%;
  height: 70vh;
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 320px;
  border: 1px solid #C1C1C1;
  border-radius: 8px;
  .sortable-section {
    height: auto;
    width: 100%;
    margin-right: 1.5rem;
    background-color: #ffffff;
    margin-bottom: 1.5rem;
    color: white;
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: normal;
    border: 1px solid #e4e4e4;
    font-family: "Montserrat";
    border-radius: 2.8px;
    float: left;
    &-removeLogo {
      position: absolute;
      width: 17px;
      top: -21px;
      right: -3px;
      cursor: pointer;
    }
  }
}

.dragablePdf {
  position: relative;
  float: left;
  width: 100%;
  &__left {
    position: sticky;
    float: left;
    top: 0;
    width: 30%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    &__droppable {
      &-input {
        width: 90%;
        display: flex;
        align-items: center;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #87898e;
        background-color: #F0F0F0;
        margin: 5px auto;
        gap: 10px;
        cursor: grab;
        padding: 10px 15px;
        border-radius: 5px;
        &.child {
          background-color: transparent;
          border: 1px solid #F0F0F0;
          padding: 5px;
          width: 50%;
          margin: 5px 15px;
        }
        & > * {
          max-width: 100%;
          span {
            white-space: nowrap;     
            overflow: hidden;      
            text-overflow: ellipsis;  
            display: inline-block; 
            max-width: 100%;   
          }
        }
      }
    }
  }
  &__right {
    position: relative;
    float: left;
    width: 70%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    &__droppable {
      position: absolute;
      &-checkbox {
        width: 20px;
        height: 20px;
        cursor: grab;
      }
      & > * {
        font-family: "Montserrat";
        font-size: 10px;
        font-weight: 600;
        color: #979797;
        height: 15px;
        display: flex;
        align-items: center;
        span {
          white-space: nowrap; 
          overflow: hidden; 
          text-overflow: ellipsis; 
        }
      }
      &-input {
        height: auto;
        // min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #87898e;
        gap: 10px;
        cursor: grab;
        background: #ffffff;
        box-shadow: 0px 4px 7px rgba(128, 128, 128, 0.14);
        border-radius: 1px;
        border: 1px solid;
        border-image: linear-gradient(45deg, #981596, #1c4ccf) 1;
        padding: 2px;
        &-checkbox {
          resize: both;
          border: 1px solid rgb(64, 63, 63);
          overflow: auto;
          // width: 20px !important;
          // height: 20px !important;
        }
        &-text {
          resize: both;
          overflow: auto;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
        &-removeLogo {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          width: 13px;
          background-color: white;
          border-radius: 50%;
        }
        &-cloneField {
          position: absolute;
          top: 0;
          right: 13px;
          cursor: pointer;
          width: 13px;
          background-color: white;
          border-radius: 50%;
        }
      }
      &-image {
        width: 595px;
        height: 842px;
        min-width: 595px;
        min-height: 842px;
        margin-bottom: 10px;
        pointer-events: none;
        outline: none;
      }
      &-textbox {
        position: absolute;
				padding: 0px;
				margin: 0px;
        width: 50px;
				line-height: 0.7;
				border: 1px dashed #ccc;
        cursor: pointer;
        outline: none;
        span {
          position: relative;
          float: left;
          width: 50px;
          max-height: 8px;
          font-size: 10px;
          text-align: left;
        }
      }
      &-checkbox {
        position: absolute;
				padding: 0px;
				margin: 0px;
        width: 15px;
        height: 15px;
				border: 1px dashed #ccc;
        cursor: pointer;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
          svg {
            width: 15px;
            height: 15px;
          }
      }
    }
  }
}
.target-box {
  text-align: center;
  width: 599px;
  margin: 0 auto;
  min-width: 599px;
  &__lines {
    min-width: 599px;
    height: 100%;
  }
}
.create_input_type {
  padding: 20px;
  position: relative;
  &-close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
  .popup_create_input_type-body-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .popup_create_input_type-body-checkbox {
      padding-left: 10px;
    }
    .popup_create_input_type-body-button {
      display: flex;
      width: 100%;
      & .primaryButton {
        width: 100%;
        height: 46px;
        padding: 16px 147px 15px;
        background-color: $primary;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-weight: 600;
      }
    }
  }
}

.create-field-modal {
  padding: 20px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &-title {
      font-size: 16px;
    }
    &-close {
      cursor: pointer;
    }
  }
  .create-field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-inputs {
      .form_input {
        & .disabled-fieldName-input {
          background-color: #f2f2f2 !important;
        }
      }
      &-dropdown {
        .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
          margin-bottom: 5px;
        }
        &-title {
          color: #7d8c96;
          font-size: 11px;
        }
      }
      .show-field-checkboxes {
        &-wrapper {
          padding-left: 10px;
        }
        span {
          color: #7d8c96;
          font-size: 11px;
        }
        &-wrapper {
          padding-left: 10px;
          display: flex;
          gap: 10px;
        }
      }
    }
    &-actions {
      display: flex;
      width: 100%;
      & .primaryButton {
        width: 100%;
        height: 46px;
        margin-top: 10px;
        background-color: $primary;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-weight: 600;
      }
    }
  }
}
.droppedInput_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 10px;
  &-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
    &-add {
      width: 17px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
    }
    &-edit {
      width: 17px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

  .popup_create_input_type-wrapper {
    padding: 20px;
    font-family: 'Montserrat';
    .popup_create_input_type-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-close {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .popup_create_input_type-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .popup_create_input_type-body-checkbox {
        padding-left: 10px;
      }
    }
    .popup_create_input_type-body-button {
      display: flex;
      width: 100%;
      & .primaryButton {
        width: 100%;
        height: 46px;
        padding: 16px 147px 15px;
        margin-top: 10px;
        background-color: $primary;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-weight: 600;
      }
    }
  }
.generic-pdf-wrapper {
  &__tabs {
    .tab-wrapper {
      background-color: transparent;
      & .tab {
        background-color: transparent;
      }
    }
  }
}

.form_input {
  .form_input__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}
.order-input-dropdown {
   label {
      white-space: nowrap;
      overflow: hidden;
      max-width: 70%;
      text-overflow: ellipsis;
    }
}

.dropdown_input {
  padding: 5px 0;
  &__label {
    font-family: "Montserrat";
    font-size: 11px;
    margin-left: 2px;
    color: #7d8c96;
    margin-bottom: 8px !important;
  }
}

.assign__modal {
  min-height: 100vh;
  max-height: 100vh;
  &-container {
    padding: 20px;
    position: relative;
    min-height: 100vh;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      & .title {
        color: $primary;
        font-family: "Montserrat";
        font-size: 20px;
        font-weight: 700;
      }
      .close__icon {
        cursor: pointer;
      }
    }
    &-actions {
      position: absolute;
      top: auto;
      bottom: 20px;
      right: 20px;
      & .primaryButton {
        padding: 10px 40px;
        border-radius: 8px;
        background: $primary;
        color: white;
        font-weight: 600;
      }
    }
    &-bottom {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      & > * {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        width: 60%;
      }
    }
  }
}
.custom-field--wrapper {
  & .field__title {
    width: 80%;
  }
}

.popup_create_input_type-body-chars {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 10px 0;
  & > * {
    width: 49%;
  }
}
.textarea__container--customFields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  label {
    font-size: 14px;
    font-family: "Montserrat";
    color: #252525;
  }
  textarea {
    resize: none;
    border-radius: 8px;
    border: 2px solid #f5f5f5;
    padding: 5px;
    font-size: 12px;
    font-family: "Montserrat";
    color: #252525;
  }
}
.autofill__input__popover {
  z-index: 99999999999;
  ul {
    font-family: "Montserrat";
    font-size: 14px;
  }
}
.autoFill__info__icon {
  padding-left: 5px;
}
.popover-items-parent {

}
.popover-items {
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    .title-component {
      position: relative;
      float: left;
      width: 100%;
      min-width: 200px;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &.previewtitles{
          font-weight: 500;
        }
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
    }
  }
  &__actions:hover {
    background-color: #ccc;
    color: #fff;
  }
}
.edit_field_modal {
  padding: 20px;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
    &-title {
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 600;
    }
    &-closeIcon {
      cursor: pointer;
    }
  }
  &-form {
    margin: 20px 0;
    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
     & .form_input {
      &__label {
        color: #252525;
        font-weight: 500;
      }
     }
     &-autocomplete {
      span {
        color: #252525;
        font-weight: 500;
        font-size: 11px;
        font-family: "Montserrat"; 
      }
      .MuiAutocomplete-root {
        height: 45px;
        margin: 8.5px 0 0;
        border: solid 2px #f5f5f5;
        .MuiFormControl-root {
          height: 45px;
        }
      }
     }
    }
    & .primaryButton {
      position: absolute;
      bottom: 20px;
      right: 20px;
      left: 20px;
      border-radius: 8px;
      background: $primary;
      color: #fff;
      padding: 10px 0;
      font-family: "Montserrat";
    }
  }
}
.static-section__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #F0F0F0;
  border-radius: 8px;
  padding: 15px;
  min-width: 300px;
  max-width: 300px;
  max-height: max-content;
  margin: 10px;
  &-title {
    padding: 5px 20px;
    border: 1px solid #C1C1C1;
    border-radius: 4px;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    color: #252525;
    text-align: center;
  }
  .static-section__item {
    border: 1px solid #C1C1C1;
    background-color: #fff;
    border-radius: 4px;
    padding: 3px;
    width: 100%;
    .section__name {
      background-color: #F0F0F0;
      border-radius: 4px;
      padding: 5px 20px;
      width: 100%;
      display: flex;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
      color: #979797;
    }
  }
}
.fileName-generator-container {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: $primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    &-list {
      padding: 0 15px;
      ol {
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  &-title {
    color: $primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
.parent__wrapper {
  width: 90%;
  margin: 10px auto 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  &-icon {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &-label {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    color: #979797;
    white-space: nowrap;     
    overflow: hidden;      
    text-overflow: ellipsis;  
    display: inline-block; 
    max-width: 100%;   
  }
}