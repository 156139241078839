@import "../../abstracts/variables";

.generic-pdf-wrapper {
  width: 100%;
  height: 100vh;
  padding: 10px 30px 0px 30px;
  overflow: scroll;
  .clientList {
    margin-left: 8px;
  }
  &__tabs {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 17px;
    margin-top: 25px;
    .edit__and__create__user__single__tabs {
      width: 89px;
      font-size: 13px;
      background: none;
      font-weight: 500;
    }
    .edit__and__create__user__single__tabs.active {
      width: 89px;
      font-size: 13px;
      font-weight: 500;
      background: none;
      color: #1c4ccf;
      border-bottom: 2px solid #1c4ccf;
    }
  }
  &__uploader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__tabs {
      min-width: fit-content;
      .client-settings__tabs {
        display: flex;
        flex-direction: column;
        padding-right: 30px;
        border-right: 1px solid #cecece;
        height: calc(100vh - 160px);
        .tab {
          padding: 10px 20px;
          background-color: #fff;
          span {
            color: $primary;
            font-weight: 600;
            padding: 10px 20px;
            display: flex;
            font-family: 'Montserrat';
            background-color: #fff;
            cursor: pointer;
          }
          &.active {
            span {
              border-radius: 5px;
              background: $primary;
              color: #fff;
            }
          }
          &:first-of-type {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
          &:last-of-type {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 30px 20px;
        .input__type__file__bordered {
            border-radius: 8px;
            background: linear-gradient(95deg, $primary 0%, #400077 100%);
            border: none;
            padding: 10px 45px;
            label {
              color: white;
              padding-left: 0px;
              justify-content: center;
              font-weight: 700;
              font-size: 14px;
              cursor: pointer;
            }
          }
        &-input {
            border-radius: 8px;
            border: 2px dashed $primary;
            background: rgba(108, 30, 176, 0.15);
            width: 100%;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 50px;
            margin-left: 20px;
            img {
              width: 90px;
            }
            span {
              text-decoration-line: underline;
              color: #1c4ccf;
              font-size: 16px;
              font-weight: 700;
              margin-top: 14px;
            }
          }
          .table__wrapper {
            margin-top: 30px;
            width: 100%;
            margin-left: 20px;
            border-radius: 10px;
            box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.15);
            height: 400px;
          }
    }
    .primaryButton {
      width: 137px;
      height: 46px;
      background-color: #1e8659;
      color: #fff;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
